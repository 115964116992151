import React, { useMemo, useState, forwardRef } from "react";
import {
  ResultsBaseAnalyticsArgs,
  FertilityDisruptorMicrobeScores,
  MicrobeScoreSummary,
  ScoreDetails,
} from "../types";
import { ScoreCard } from "../components/scoreCard";
import ScoreGradient from "../components/ScoreGradient";
import { formatScore } from "./utils";
import { DottedDivider } from "../components/Icons";
import { EvvyMarkdown } from "../../../../components/common/evvyMarkdown";
import { ScoreCardRow } from "../components/ScoreCardRow";
import { ScoreCardWrapper } from "../components/ScoreCardWrapper";
import classNames from "classnames";
import { getIsMobile } from "../../../../utils/general";
import { DETECTED_COPY, FERTILITY_DISRUPTOR_COPY } from "./constants";
import { ScoreCardHeader } from "../components/ScoreCardHeader";

interface FertilityDisruptorProps {
  fertilityDisruptorMicrobes: Record<string, ScoreDetails>;
  fertilityDisruptorMicrobesSummary: MicrobeScoreSummary;
  onSelect: () => void;
  className?: string;
  isExpanded?: boolean;
  setModalData: (data: { type: string; data: any }) => void;
  analyticsArgs: ResultsBaseAnalyticsArgs;
}

export const FertilityDisruptor = forwardRef<HTMLDivElement, FertilityDisruptorProps>(
  (
    {
      fertilityDisruptorMicrobes,
      fertilityDisruptorMicrobesSummary,
      onSelect,
      className = "",
      isExpanded = false,
      setModalData,
      analyticsArgs,
    },
    ref
  ) => {
    const isMobile = getIsMobile();
    const { has_pcr_positive_and_below_threshold } =
      fertilityDisruptorMicrobesSummary;
    const [selectedMicrobe, setSelectedMicrobe] = useState<ScoreDetails | null>(
      Object.keys(fertilityDisruptorMicrobes).length > 0
        ? fertilityDisruptorMicrobes[Object.keys(fertilityDisruptorMicrobes)[0]]
        : null
    );

    return (
      <div data-score-type="microbe">
        <ScoreCardWrapper
          ref={ref}
          onSelect={isMobile ? undefined : onSelect}
          className={className}
          isExpanded={isExpanded}
        >
          <div>
            <ScoreCardHeader
              scoreDisplayName={FERTILITY_DISRUPTOR_COPY.header.title}
              scoreSubtitle={FERTILITY_DISRUPTOR_COPY.header.subtitle}
              isMobile={isMobile}
              isExpanded={isExpanded}
              status={has_pcr_positive_and_below_threshold ? DETECTED_COPY : ""}
              onSelect={onSelect}
            />
          </div>
          {isExpanded && !isMobile && !has_pcr_positive_and_below_threshold && (
            <div>
              <h4 className="mb-2 text-4xl leading-10 text-neutral-900">
                {formatScore(
                  fertilityDisruptorMicrobesSummary.total_abundance || 0,
                  "microbe"
                )}
              </h4>
            </div>
          )}
          <div
            className={classNames({
              "bg-white px-4 py-6 rounded-xl": isMobile && isExpanded,
              "bg-transparent p-0": !isMobile,
            })}
          >
            {isMobile && isExpanded && <p className="t4 mb-2">YOUR RESULTS:</p>}
            {has_pcr_positive_and_below_threshold && isMobile && (
              <div className="flex text-center mx-auto items-center w-full bg-evvy-cream rounded-full p-2">
                <p className="w-full mb-0 t4 pb-0 font-semibold text-sm leading-5 text-evvy-red">
                  {DETECTED_COPY}
                </p>
              </div>
            )}
            {!has_pcr_positive_and_below_threshold && (
              <div className="flex gap-2 items-center">
                {(!isExpanded || isMobile) && (
                  <p className="mb-0 pb-0 font-semibold text-sm leading-5 text-neutral-900">
                    {formatScore(
                      fertilityDisruptorMicrobesSummary.total_abundance || 0,
                      "microbe"
                    )}
                  </p>
                )}
                <ScoreGradient
                  value={fertilityDisruptorMicrobesSummary.total_abundance}
                  min={0}
                  max={100}
                  lowThreshold={0.1}
                  moderateThreshold={2}
                />
              </div>
            )}
            {isExpanded && !has_pcr_positive_and_below_threshold && (
              <div className="b2 my-4">
                <EvvyMarkdown>
                  {fertilityDisruptorMicrobesSummary.status}
                </EvvyMarkdown>
              </div>
            )}
          </div>

          {!isExpanded &&
            fertilityDisruptorMicrobesSummary.has_pcr_positive_and_below_threshold && (
              <div>
                {fertilityDisruptorMicrobesSummary.microbes_above_threshold.map(
                  (microbe, index) => (
                    <div
                      className={classNames({
                        "mb-6":
                          index !==
                          fertilityDisruptorMicrobesSummary
                            .microbes_above_threshold.length -
                            1,
                        "mb-0":
                          index ===
                          fertilityDisruptorMicrobesSummary
                            .microbes_above_threshold.length -
                            1,
                      })}
                      key={microbe.score_definition.display_name}
                    >
                      <div className="my-6 w-full border-b border-dashed border-black border-opacity-50 min-h-px max-md:max-w-full" />
                      <ScoreCardRow
                        score={microbe}
                        isExpanded={isExpanded}
                        analyticsArgs={analyticsArgs}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          {/* Render each of the microbe scores as a ScoreCard */}
          {isExpanded &&
            Object.values(fertilityDisruptorMicrobes).map((microbe, index) => (
              <div
                className={classNames({
                  "mb-2":
                    index ===
                    Object.keys(fertilityDisruptorMicrobes).length - 1,
                  "mb-6":
                    index !==
                    Object.keys(fertilityDisruptorMicrobes).length - 1,
                })}
                key={microbe.score_definition.display_name}
              >
                <DottedDivider />
                <ScoreCard
                  score={microbe}
                  score_definition={microbe.score_definition}
                  setModalData={setModalData}
                  setSelectedMicrobe={setSelectedMicrobe}
                  microbeLearnMoreExpanded={
                    selectedMicrobe?.score_definition.display_name ===
                    microbe.score_definition.display_name
                  }
                  analyticsArgs={analyticsArgs}
                />
              </div>
            ))}
        </ScoreCardWrapper>
      </div>
    );
  }
);
