import React, { useState, forwardRef } from "react";
import { DottedDivider } from "./Icons";
import { ReactComponent as InfoIcon } from "../../../../components/results_v2/icons/info.svg";

import {
  ScoreDetails,
  ScoreDefinitionDetails,
  ResultsBaseAnalyticsArgs,
} from "../types";
import { formatScore, getScoreType } from "../fertilityModule/utils";
import ScoreGradient from "./ScoreGradient";
import Markdown from "markdown-to-jsx";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { ScoreCardWrapper } from "./ScoreCardWrapper";
import { getIsMobile } from "../../../../utils/general";
import { ScoreInsightRow } from "./ScoreInsightRow";
import {
  DETECTED_COPY,
  FERTILITY_DISRUPTOR_TOOLTIP_COPY,
  RESEARCH_STATUS_COPY,
  SCORE_VALUE_DETECTED,
  SCORE_VALUE_HIGH,
  SCORE_VALUE_LOW,
  SCORE_VALUE_MODERATE,
} from "../fertilityModule/constants";
import { ScoreCardHeader } from "./ScoreCardHeader";
import { ResearchRating } from "../../../../components/common/ResearchRating";
import { secondaryColors } from "../../../../utils/viz";
import { MicrobeLearnMoreExpanded } from "./MicrobeLearnMoreExpanded";

interface ScoreCardProps {
  score: ScoreDetails;
  score_definition: ScoreDefinitionDetails;
  className?: string;
  onSelect?: () => void;
  isExpanded?: boolean;
  setModalData: (data: { type: string; data: any }) => void;

  // Props for nested microbe scores (for mobile view)
  setSelectedMicrobe?: (microbe: ScoreDetails | null) => void;
  microbeLearnMoreExpanded?: boolean;
  analyticsArgs: ResultsBaseAnalyticsArgs;
}

const getScoreValueCopy = (score: ScoreDetails) => {
  if (score.score_definition.score_type === "microbe") {
    if (
      score.quantitative_value === 0 &&
      score.qualitative_value !== SCORE_VALUE_DETECTED
    ) {
      return `<i>${score.score_definition.display_name}</i> was not detected, which research associates with positive fertility outcomes.`;
    } else if (
      score.qualitative_value === SCORE_VALUE_LOW ||
      score.qualitative_value === SCORE_VALUE_MODERATE
    ) {
      return `Your <i>${score.score_definition.display_name}</i> levels are low, which research associates with positive fertility outcomes.`;
    } else if (score.qualitative_value === SCORE_VALUE_HIGH) {
      return `Your <i>${score.score_definition.display_name}</i> levels are <b>elevated</b>. Research associates lower levels with positive fertility outcomes.`;
    } else if (score.qualitative_value === SCORE_VALUE_DETECTED) {
      return `${score.score_definition.display_name} was <b>detected</b>. Research associates lower levels with positive fertility outcomes.`;
    } else {
      return "Unknown";
    }
  } else if (score.score_definition.slug === "protective") {
    switch (score.qualitative_value) {
      case SCORE_VALUE_LOW:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>. Research associates higher levels of protective bacteria with positive fertility outcomes.`;
      case SCORE_VALUE_MODERATE:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>. Research associates higher levels of protective bacteria with positive fertility outcomes.`;
      case SCORE_VALUE_HIGH:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>, which research associates with positive fertility outcomes.`;
      default:
        return "Unknown";
    }
  } else if (score.score_definition.slug === "shannon-diversity") {
    switch (score.qualitative_value) {
      case SCORE_VALUE_LOW:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>, which research associates with positive fertility outcomes.`;
      case SCORE_VALUE_MODERATE:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>. Research associates lower diversity scores with positive fertility outcomes.`;
      case SCORE_VALUE_HIGH:
        return `Your <i>${score.score_definition.display_name}</i> is <b>${score.qualitative_value}</b>. Research associates lower diversity scores with positive fertility outcomes.`;
      default:
        return "Unknown";
    }
  }
  return "Unknown";
};

export const ScoreCard = forwardRef<HTMLDivElement, ScoreCardProps>(
  (
    {
      score,
      score_definition,
      className = "",
      onSelect,
      isExpanded = false,
      setModalData,
      setSelectedMicrobe,
      microbeLearnMoreExpanded,
      analyticsArgs,
    },
    ref
  ) => {
    const isNestedMicrobeScore =
      score.score_definition.score_type === "microbe";
    const isMobile = getIsMobile();

    return (
      <ScoreCardWrapper
        ref={ref}
        onSelect={isMobile ? undefined : onSelect}
        className={className}
        isExpanded={isExpanded}
        isNestedMicrobeScore={isNestedMicrobeScore}
      >
        {!isNestedMicrobeScore && (
          <ScoreCardHeader
            scoreDisplayName={score_definition.display_name}
            scoreSubtitle={score_definition.subtitle}
            isMobile={isMobile}
            isExpanded={isExpanded}
            onSelect={onSelect}
          />
        )}
        <div
          className={classNames("sm:bg-transparent rounded-xl sm:p-0 p-0", {
            "bg-white": !isNestedMicrobeScore && isExpanded,
            "bg-transparent": isNestedMicrobeScore,
            "p-4": isExpanded && isMobile,
          })}
          data-score-type={score.score_definition.score_type}
        >
          <div
            className={classNames("overflow-hidden", {
              "max-h-[1000px] opacity-100": isExpanded,
              "max-h-0 opacity-0": !isExpanded,
            })}
          >
            {score.quantitative_value !== undefined &&
              score.quantitative_value !== null && (
                <div className="mb-0 sm:block hidden">
                  <h2 className="pb-0 mb-0 text-neutral-900">
                    {formatScore(score.quantitative_value, getScoreType(score))}
                  </h2>
                </div>
              )}
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div
              className={classNames({
                "grid grid-cols-[30%_70%]":
                  isNestedMicrobeScore &&
                  (!isMobile || score.pcr_detected_or_sti_positive),
                "flex flex-col gap-2": !isNestedMicrobeScore,
              })}
            >
              {isNestedMicrobeScore && (
                <p
                  className={classNames("text-[16px] medium text-neutral-900", {
                    "sm:mb-0 mb-2": !score.pcr_detected_or_sti_positive,
                    "mb-0": score.pcr_detected_or_sti_positive,
                    medium: !isMobile,
                    "semibold b2": isMobile,
                  })}
                >
                  {score_definition.display_name}
                </p>
              )}
              {isExpanded && isMobile && (
                <p className="t4 mb-0">YOUR RESULTS</p>
              )}
              <div className="flex gap-2 items-center w-full">
                {(!isExpanded || isMobile) &&
                  !score.pcr_detected_or_sti_positive && (
                    <p
                      className={classNames(
                        "mb-0 pb-0 font-semibold text-sm leading-5 text-neutral-900 whitespace-nowrap"
                      )}
                    >
                      {formatScore(
                        score.quantitative_value || 0,
                        getScoreType(score)
                      )}
                    </p>
                  )}
                <div
                  className={classNames("flex-1", {
                    "flex justify-between text-right":
                      score.pcr_detected_or_sti_positive,
                  })}
                >
                  {score.pcr_detected_or_sti_positive ? (
                    <div className="flex gap-2 items-center justify-end w-full">
                      <Tippy
                        arrow
                        render={() => (
                          <div
                            id="tooltip"
                            role="tooltip"
                            className="leading-snug bg-evvy-pine p-3 rounded-md max-w-sm text-evvy-white"
                            tabIndex={-1}
                          >
                            {FERTILITY_DISRUPTOR_TOOLTIP_COPY}
                          </div>
                        )}
                        placement="bottom"
                        animation={false}
                      >
                        <div className="flex gap-2 items-center">
                          <p className="t4 text-evvy-red mb-0 pb-0">
                            {DETECTED_COPY}
                          </p>
                          <InfoIcon
                            stroke={secondaryColors["evvy-red"]}
                            fill={secondaryColors["evvy-red"]}
                            className="mb-0.5 w-4 h-4 inline"
                          />
                        </div>
                      </Tippy>
                    </div>
                  ) : (
                    <ScoreGradient
                      value={score.quantitative_value || 0}
                      lowThreshold={score_definition.low_threshold}
                      moderateThreshold={score_definition.moderate_threshold}
                      min={score_definition.min_score}
                      max={score_definition.max_score}
                      height={!isNestedMicrobeScore ? 33 : 16}
                      greenToRedScale={
                        score.score_definition.slug === "protective"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {(isExpanded || isNestedMicrobeScore) && (
              <div
                className={classNames("mt-2", {
                  "opacity-100 translate-y-0":
                    isExpanded || isNestedMicrobeScore,
                  "opacity-0 -translate-y-2":
                    !isExpanded && !isNestedMicrobeScore,
                })}
              >
                <Markdown className="text-sm leading-5 text-neutral-900 mb-4">
                  {getScoreValueCopy(score)}
                </Markdown>
              </div>
            )}
            {(isExpanded || isNestedMicrobeScore) && (
              <div className="flex gap-1 items-center self-start text-sm leading-none text-neutral-500">
                <ResearchRating
                  researchRating={score.research_rating}
                  tooltipCopy={RESEARCH_STATUS_COPY}
                />
              </div>
            )}
          </div>
        </div>
        {isNestedMicrobeScore && isMobile && (
          <MicrobeLearnMoreExpanded
            microbeLearnMoreExpanded={microbeLearnMoreExpanded}
            setSelectedMicrobe={setSelectedMicrobe}
            score={score}
            analyticsArgs={analyticsArgs}
            setModalData={setModalData}
          />
        )}
        {isExpanded && isMobile && (
          <div className="p-2 flex flex-col gap-2">
            <DottedDivider />
            <ScoreInsightRow
              score={score}
              setModalData={setModalData}
              analyticsArgs={analyticsArgs}
            />
          </div>
        )}
      </ScoreCardWrapper>
    );
  }
);
