import { Consult } from "./care";
import { ProblemsSummary, TestScores } from "./test";
import { User } from "./user";

export type ProviderTestOrder = {
  id: number;
  provider: User;
  patient_email: string;
  test_pretty_hash?: string;
  test_type: string;
  bacteria_data: any[];
  results_pdf_url: string;
  prelim_results_pdf_url?: string;
  create_date: string;
  status: string;
  results_released_at?: string;
  sample_taken_at?: string;
  provider_order_link?: string;
  add_expanded_pcr: boolean;
  care_eligibility_info: CareEligibilityInfo;
  problems_summary: ProblemsSummary;
  scores: TestScores;
  patient_first_name: string;
  patient_last_name: string;
  latest_completed_vaginitis_consult?: Consult;
  patient_is_menopausal: boolean;
};

export type CareEligibilityInfo = {
  status: "Eligible" | "Eligibility lapsed" | "Enrolled" | "Ineligible";
  tooltip: string;
};

export enum Status {
  WAITING = "Waiting for Patient",
  RECEIVED_AT_LAB = "Test Received at Lab",
  RECEIVED = "Patient Received Test",
  SEQUENCING = "Test Sequencing at Lab",
  PRELIM_RESULTS_READY = "Prelim Results Ready",
  READY = "Results Ready",
}

export type ProviderTestData = {
  id: number;
  orderDate: string;
  patientEmail: string;
  testPrettyHash: string;
  testType: string;
  status: Status;
  sampleDate?: string;
  hasAddedExpan?: boolean;
  resultsData: {
    reportUrl?: string;
    prelimResultsUrl?: string;
    bacteriaData: any[];
    problemsSummary: ProblemsSummary;
  };
  careEligibilityInfo?: CareEligibilityInfo;
  scores: TestScores;
  patientFirstName: string;
  patientLastName: string;
  latestCompletedVaginitisConsult?: Consult;
  patientIsMenopausal: boolean;
};
