/**
 * An outlined button styled with a plus button
 * @param {string} text the text label for the button
 * @param {*} onClick function to handle button click
 * @returns jsx of a button
 */

import { React } from "react";
import { ReactComponent as ArrowIcon } from "../../components/results_v2/icons/arrow-right-no-tail.svg";
import { secondaryColors } from "../../utils/viz";
import classNames from "classnames";

const PlusButtonPill = ({
  text,
  onClick,
  useArrowIcon = false,
  className = "",
}) => {
  return (
    <button
      className={classNames(
        "group flex items-center text-[12px] sm:text-[13px] font-semibold uppercase tracking-wide border border-evvy-pine rounded-full px-4 py-1 mt-3 mr-2 transition-colors hover:bg-evvy-pine hover:text-white",
        className
      )}
      onClick={onClick}
    >
      {text}
      {useArrowIcon ? (
        <ArrowIcon
          className="w-[6px] h-[10px] ml-2 transition-all duration-200"
          stroke={secondaryColors["evvy-pine"]}
        />
      ) : (
        <span className="ml-1">+</span>
      )}
    </button>
  );
};

export default PlusButtonPill;
