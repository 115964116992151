import classNames from "classnames";
import React, { forwardRef } from "react";
import { getIsMobile } from "../../../../utils/general";

interface ScoreCardWrapperProps {
  onSelect?: () => void;
  className?: string;
  isExpanded?: boolean;
  children: React.ReactNode;
  isNestedMicrobeScore?: boolean;
  isLocked?: boolean;
  isSTIScore?: boolean;
}

export const ScoreCardWrapper = forwardRef<HTMLDivElement, ScoreCardWrapperProps>(
  (
    {
      onSelect,
      children,
      className,
      isExpanded,
      isNestedMicrobeScore = false,
      isLocked = false,
      isSTIScore = false,
    },
    ref
  ) => {
    const handleClick = (e?: React.MouseEvent) => {
      if (onSelect) {
        e?.preventDefault(); // Prevent default scroll behavior
        onSelect();
      }
    };

    return (
      <div
        ref={ref}
        onClick={handleClick}
        className={classNames(
          "group rounded-xl transition-all duration-300 ease-in-out scroll-mt-24",
          className,
          {
            "bg-evvy-cream shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]": isExpanded,
            "sm:p-6 p-4": !isNestedMicrobeScore,
            "p-0 px-0": isNestedMicrobeScore,
            "cursor-pointer border border-solid border-neutral-900 border-opacity-10 bg-white hover:bg-evvy-cream hover:border-none":
              !isExpanded && !isNestedMicrobeScore && !isLocked,
            "cursor-not-allowed border border-solid border-neutral-900 border-opacity-10":
              isLocked,
          }
        )}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onSelect?.();
          }
        }}
      >
        {children}
      </div>
    );
  }
);
 