import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";

import { indexCheckboxStyles, indexRadioStyles } from "../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import { getQuestionTitle } from "../../../utils/questions";
import { intakeDisclaimerCopy } from "../../../components/care/constants";
import BlueRectangularButton from "../../../components/common/blueRectangularButton";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";

const VAGINAL_ESTROGEN_BLOG_LINK = "https://www.evvy.com/blog/vaginal-estrogen";

const VAGINAL_ESTROGEN = "ES";

const GeneralQuestions = ({ consultIntake, submitPage, loading }) => {
  const [error, setError] = useState(null);
  const [apiErrors, setApiErrors] = useState({});

  // for tooltips
  const allergiesQuestion = useRef(null);
  const allergiesOtherQuestion = useRef(null);
  const yeastHistoryQuestion = useRef(null);
  const anythingElseQuestion = useRef(null);

  const allQuestions = {
    allergies: allergiesQuestion,
    allergies_other: allergiesOtherQuestion,
    yeast_history: yeastHistoryQuestion,
    anything_else: anythingElseQuestion,
  };

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      console.log("error is ", apiErrors[key][0]);
      return apiErrors[key][0];
    }
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>
          Care | Consult | Intake | Treatment | General Treatment Questions
        </title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Treatment Questions</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        <Formik
          initialValues={{
            allergies: consultIntake.allergies || [],
            allergies_other: consultIntake.allergies_other || "",
            yeast_history: consultIntake.yeast_history || "",
            anything_else: consultIntake.anything_else || "",
          }}
          validate={(values) => {
            var errors = {};
            Object.keys(allQuestions).forEach((key) =>
              ReactTooltip.hide(allQuestions[key].current)
            );
            ReactTooltip.rebuild();
            // required questions
            var requiredQuestions = ["yeast_history"];
            var requiredMultiselects = ["allergies"];
            var requiredOtherQuestions = ["allergies"];

            requiredQuestions.forEach((key) => {
              if (!values[key]) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
              }
            });
            // required multiselect questions (multi select, needs at least 1)
            requiredMultiselects.forEach((key) => {
              if (!values[key].length) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current);
              }
            });

            // required "other" context if "Other" is selected
            requiredOtherQuestions.forEach((key) => {
              if (values[key].includes("OT") && !values[`${key}_other`]) {
                errors[`${key}_other`] = "This is a required question";
                ReactTooltip.show(allQuestions[`${key}_other`].current);
              }
            });
            return errors;
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            submitPage(values, (response) => {
              if (typeof response === "object") {
                setApiErrors(response);
                Object.keys(response).forEach((k) => {
                  ReactTooltip.show(allQuestions[k].current);
                });
              } else {
                setError(response || "Error saving consult intake");
              }
            });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="flex-1 bg-evvy-white p-5 sm:p-8 mb-3 rounded-lg">
                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("allergies", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("allergies", errors, apiErrors)}
                  data-for="allergies"
                  ref={allergiesQuestion}
                >
                  <ReactTooltip
                    id="allergies"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_allergies")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>
                    Please note that this will directly impact what your
                    provider prescribes.
                  </p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.allergies.map((o, i) => (
                      <label
                        className="mb-2 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="allergies"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "allergies",
                            fieldValues: values.allergies,
                            noneCode: ["NO"],
                          })}
                        />
                        {/* special handling for vaginal estrogen ES */}
                        {o[0] === VAGINAL_ESTROGEN ? (
                          <span className="ml-2">
                            {o[1]}
                            {" (read about its benefits "}
                            <a
                              className="italic"
                              target="_blank"
                              rel="noreferrer"
                              href={VAGINAL_ESTROGEN_BLOG_LINK}
                            >
                              here
                            </a>
                            {")"}
                          </span>
                        ) : (
                          <span className="ml-2">{o[1]}</span>
                        )}
                      </label>
                    ))}
                  </div>
                </div>

                {values.allergies.includes("OT") ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError("allergies_other", errors, apiErrors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError("allergies_other", errors, apiErrors)}
                    data-for="allergies_other"
                    ref={allergiesOtherQuestion}
                  >
                    <ReactTooltip
                      id="allergies_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("intake_allergies_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="allergies_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("yeast_history", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("yeast_history", errors, apiErrors)}
                  data-for="yeast_history"
                  ref={yeastHistoryQuestion}
                >
                  <ReactTooltip
                    id="yeast_history"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_yeast_history")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.yeast_history.map((o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="yeast_history"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div className="mt-2 p-4">
                  <h4 className="t1">
                    {getQuestionTitle("intake_anything_else")}
                  </h4>
                  <div>
                    <Field
                      name="anything_else"
                      as="textarea"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="bg-coral p-2 px-3 font-medium rounded-md my-4 text-center">
                  {errors && Object.keys(errors).length > 0
                    ? "Please fix the errors above to continue"
                    : intakeDisclaimerCopy}
                </div>
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GeneralQuestions;
