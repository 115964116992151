import React from "react";
// components
import CloseX from "../../common/closeX";
import { EvvyMarkdown } from "../../common/evvyMarkdown";
import { KEY_MICROBES_NOT_DETECTED_COPY } from "../../../pages/results_v2/constants";
import { Bacteria } from "../../../types/results";

const KeyMicrobesNotDetectedModal = ({
  handleClose,
  keyMicrobesNotDetected,
}: {
  handleClose: any;
  keyMicrobesNotDetected: Bacteria[];
}) => {
  return (
    <div className="w-full bg-evvy-white px-6 py-8 md:p-8 max-h-[800px] overflow-y-scroll">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {/* title   */}
      <div className="t1 my-6">{KEY_MICROBES_NOT_DETECTED_COPY.TITLE}</div>
      <hr />
      {/* details  - new line at pipe indicator*/}
      <div className="b2 my-6">
        <p>
          <EvvyMarkdown newlineOnPipe>
            {KEY_MICROBES_NOT_DETECTED_COPY.DESCRIPTION}
          </EvvyMarkdown>
        </p>
        <ul className="list-disc list-outside">
          {keyMicrobesNotDetected.map((microbe: any) => (
            <li key={microbe.id}>{microbe.short_name}</li>
          ))}
          <li>And many more!</li>
        </ul>
      </div>
    </div>
  );
};

export default KeyMicrobesNotDetectedModal;
