import { ReactComponent as ArrowDown } from "../../../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../../../components/common/icons/arrow-up-no-tail.svg";
import { secondaryColors } from "../../../../utils/viz";
import classNames from "classnames";
import { ReactComponent as ArrowIcon } from "../../../../components/results_v2/icons/arrow-right-no-tail.svg";
import { DETECTED_COPY, POSITIVE_STATUS } from "../fertilityModule/constants";

interface ScoreCardHeaderProps {
  scoreDisplayName: string;
  scoreSubtitle: string;
  isMobile: boolean;
  isExpanded: boolean;
  status?: string;
  onSelect?: () => void;
}

export const ScoreCardHeader: React.FC<ScoreCardHeaderProps> = ({
  scoreDisplayName,
  scoreSubtitle,
  isMobile,
  isExpanded,
  status = "",
  onSelect,
}) => {
  return (
    <header onClick={onSelect} aria-label={`${scoreDisplayName} information`}>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-4 items-center">
          <p
            className={classNames(
              "sm:text-[18px] text-[16px] text-neutral-900 mb-0",
              {
                "font-semibold": isExpanded,
                "font-medium": !isExpanded,
              }
            )}
          >
            {scoreDisplayName}
          </p>
          {!isMobile && status && (
            <span
              className={classNames(
                "gap-2.5 self-stretch px-4 py-1 my-auto t4 tracking-wider leading-loose text-center uppercase whitespace-nowrap rounded-[90px]",
                {
                  "bg-stone-100": !isExpanded,
                  "bg-white": isExpanded,
                  "text-evvy-red":
                    status === DETECTED_COPY || status === POSITIVE_STATUS,
                  "text-teal-900":
                    status !== DETECTED_COPY && status !== POSITIVE_STATUS,
                }
              )}
            >
              {status}
            </span>
          )}
        </div>
        {!isMobile && (
          <ArrowIcon
            className={classNames("sm:block hidden", {
              "opacity-100": isExpanded,
              "opacity-0 group-hover:opacity-100": !isExpanded,
            })}
            stroke={secondaryColors["evvy-black"]}
          />
        )}
        {isMobile &&
          (isExpanded ? (
            <ArrowUp fill={secondaryColors["evvy-black"]} />
          ) : (
            <ArrowDown fill={secondaryColors["evvy-black"]} />
          ))}
      </div>
      {isExpanded && (
        <p
          className={classNames(
            "sm:hidden medium block leading-6 text-neutral-900"
          )}
        >
          {scoreSubtitle}
        </p>
      )}
    </header>
  );
};
