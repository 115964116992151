import {
  diagnosesCodes,
  dischargeCodes,
  smellCodes,
  treatmentCodes,
  symptomCodes,
} from "../../utils/copy";

const blurLevels = {
  HIGH: 30,
  HIGH_MOBILE: 20,
  MED: 8,
  MED_MOBILE: 3,
  LOW: 0,
};

const screens = [
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.2,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.2,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0.15,
    blurProp: blurLevels.LOW,
  },
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
];

const screensWithFertility = [
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.2,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0.15,
    blurProp: blurLevels.LOW,
  },
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
];

// different settings for viz when on mobile and very small
const screens_mobile = [
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED_MOBILE,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.25,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.MED_MOBILE,
  },
  {
    isMoving: false,
    groupExplosionBasis: 0.35,
    nodeExplosionBasis: 0.3,
    blurProp: blurLevels.LOW,
  },
  {
    isMoving: true,
    groupExplosionBasis: 0,
    nodeExplosionBasis: 0,
    blurProp: blurLevels.HIGH,
  },
];

const healthContextDictionary = {
  discharge: {
    intro: "your discharge was",
    answers: dischargeCodes,
  },
  smell: {
    // deprecated
    intro: "your vaginal smell was",
    answers: smellCodes,
  },
  treatments: {
    intro: "within 60 days of this test, you had tried",
    answers: treatmentCodes,
  },
  symptoms: {
    intro: "your symptoms included",
    answers: symptomCodes,
  },
  diagnoses_SI: {
    // deprecated and replaced with diagnoses_30
    intro: "within 60 days of this test, you were diagnosed with",
    answers: diagnosesCodes,
  },
  diagnoses_30: {
    intro: "within 30 days of this test, you were diagnosed with",
    answers: diagnosesCodes,
  },
  diagnoses_TH: {
    intro: "you suspected a case of",
    answers: diagnosesCodes,
  },
};

const defaultNote =
  "Above you'll find a detailed breakdown of all the microbes present and what they mean. It's important to note that this is not a diagnosis; this test is only a wellness screen and these microbes are associative based on scientific research.|We've put together a customized vaginal health plan for you with a step by step guide to promote a healthy, protective microbiome. Make sure to click through to \"view your plan\" below!|Thank you,|Team Evvy";

const MENU_ITEMS = [
  {
    number: 1,
    label: "At-a-glance",
    href: "ataglance",
  },
  {
    number: 2,
    label: "Your Microbiome Type",
    href: "microbiometype",
  },
  {
    number: 3,
    label: "Detailed Results",
    href: "detailedresults",
  },
  {
    number: 4,
    label: "Trends & Insights",
    href: "trends",
  },
  {
    number: 5,
    label: "Next Steps",
    href: "nextsteps",
  },
];

export const TEST_VERSION_MAP = {
  SA: "VMB v1",
  S2: "VMB v2",
  "S2.2": "VMB v2.2",
};

const PAGE_SECTION_TITLES = {
  BACTERIA_COMPOSITION: "Bacteria Composition",
  PLAN: "Plan",
  CST: "CST",
  WHATS_NEXT: "Whats Next",
  MY_PLAN_STICKY_FOOTER: "My Plan Sticky Footer",
};

const CST_COPY = {
  TITLE: "Your Vaginal Microbiome Type",
  SUBTITLE: `While every vaginal microbiome is unique, current research shows that most vaginal microbiomes fall into one of five categories, known as "Community State Types” (CSTs).`,
  LINK: "learn more about CST's",
  LINK_ADDRESS: "https://www.evvy.com/blog/community-state-types",
  CARD_TITLE: "Your community state type",
  CARD_EXPAND: "Learn More",
  CARD_COLLAPSE: "Show Less",
};

const BACTERIA_COPY = {
  TITLE: "Your Detailed Results",
  SUBTITLE:
    "We tested for 700+ microbes. Select a tag to view your microbes related to:",
  CARD_EXPAND: "View all microbes",
  CARD_COLLAPSE: "Hide",
  EMPTY_A:
    "Your test indicates that you don’t have a significant amount of microbes related to this condition.",
  EMPTY_A_NO_MICROBIES:
    "Your test indicates that you don’t have any microbes related to this condition.",
  EMPTY_B:
    "If you feel like you might have this condition or are experiencing symptoms, reach out to your doctor.",
  EMPTY_A_GOOD_HEALTH:
    "Your test indicates that you currently don't have many microbes that are associated with good vaginal health.",
  EMPTY_B_GOOD_HEALTH:
    "You may want to focus on increasing the levels of these protective microbes (and your Evvy plan can show you how!)",
};

const DISCLAIMER_COPY = {
  WELLNESS:
    "* Reminder: Evvy does not detect STIs. It is not intended to replace your regular medical checkups or your Pap smear.",
  EXPANDED_LDT:
    "* Reminder: Evvy is not intended to replace your regular checkups or Pap smear.",
  STANDARD_LDT:
    "* Reminder: Evvy does not detect STIs, and is not intended to replace your regular checkups or Pap smear.",
};

const STI_RESULTS_COPY = {
  TITLE: "STI Results",
};

const GOOD_HEALTH_NAME = "Good Health";

const NEXT_COPY = {
  TITLE: "What’s next?",
  SUBTITLE:
    "We’ve created a personalized plan for you based on your unique microbiome, click below to access!",
  GOAL: "your microbiome goal",
  LINK: "View Your Plan",
  SCROLL_TEXT: "Want to keep learning about your microbiome?",
};

const NEXT_COPY_V2 = {
  TITLE: "What’s next for your microbiome?",
  SUBTITLE:
    "Your microbiome is unique, and so is your care. We’ve created a personalized plan to help support balance and optimize your vaginal health. Explore your recommendations below.",
  LINK: "View Your Plan",
  SECONDARY_LINK: "Talk to Your Health Coach",
};

const WHATS_UP_COPY = {
  TITLE: "Now you know what's up down there —<br>see what's next:",
  SUBTITLE: "Microbiome Goal",
  LINK: "View Your Plan",
};

const TRENDS_COPY = {
  TITLE: "Trends & Insights",
  SUBTITLE:
    "Your microbiome changes in response to behaviors, hormones, treatments, and more. See below to understand how your microbome changed between tests.",
  SUBTITLE_ONE_TEST:
    "Your microbiome changes in response to behaviors, hormones, treatments, and more. Take another test to understand how your microbiome changes over time.",
};

const RESULTS_COPY = {
  BACTERIA_TYPE: {
    protective: "This type is predominantly",
    variable: "This type is predominantly",
    disruptive: "This type is predominantly",
    unknown: "N/A",
  },
  TYPE_PERCENTAGE: "of Evvy users are also categorized as",
};

const modalTypes = {
  HEALTH_ASSOCIATIONS: "_health_associations",
  ASSOCIATIONS_DISCLAIMER: "_associations_disclaimer",
  EVVY_TEST_VERSION: "_evvy_test_version",
  KEY_MICROBES_NOT_DETECTED: "_key_microbes_not_detected",
};

const SECTION_TYPES = {
  circlePack: "circlePack",
  donut: "donut",
};

const smallTypeSvgConfig = {
  width: 54,
  height: 54,
  margin: 0,
  innerRadius: 15,
  outerRadius: 27,
};

const largeTypeSvgConfig = {
  width: 70,
  height: 70,
  margin: 0,
  innerRadius: 24,
  outerRadius: 35,
};

const SYMPTOM_SCORES = ["none", "mild", "moderate", "severe"];
const SYMPTOM_COLORS = ["#C89CD9", "#C89CD9A8", "#C89CD954", "white"];

const REVERSE_COLORS = ["#C89CD9", "#C89CD9A8", "#C89CD954", "white"].reverse();

const CATEGORY_KEYS = {
  protective: "GO",
  disruptive: "BA",
  neutral: "NE",
  shannon_diversity: "SH",
};

const DATA_TYPES = {
  DEFAULT: "default",
  TEST: "test",
  TYPE: "type",
};

const TREND_DIRECTION = {
  NEUTRAL: "neutral",
  UP: "up",
  DOWN: "down",
};

export const MODULE_TITLE_DICTIONARY = {
  fertility: "Fertility Insights",
  sti: "STI Status",
  menopause: "Menopause Insights",
};

const TYPES_DICTIONARY = {
  "I-A": "Type 1-A",
  "I-B": "Type 1-B",
  II: "Type 2",
  "III-A": "Type 3-A",
  "III-B": "Type 3-B",
  "IV-A": "Type 4-A",
  "IV-B": "Type 4-B",
  "IV-C": "Type 4-C",
  "IV-C0": "Type 4-C0",
  "IV-C1": "Type 4-C1",
  "IV-C2": "Type 4-C2",
  "IV-C3": "Type 4-C3",
  "IV-C4": "Type 4-C4",
  V: "Type 5",
};

const HEALTH_ASSOCIATIONS_COPY = {
  DISCLAIMER:
    "*An association is not a diagnosis. Always talk to your healthcare provider to understand how these associations may apply to you.*",
  ASSOCIATION_DESCRIPTION:
    "Evvy’s CST associations can help you understand if there are certain health conditions that published research has found to be correlated with your vaginal microbiome type — enabling you to be proactive in your health.",
  CONDITION_DISCLAIMER:
    "No — an association is not a diagnosis! Many people have a microbiome associated with a condition and they do not develop that condition (and vice versa!). It is important to note that being in a CST with certain associated conditions does NOT mean you will certainly develop or experience those associated conditions.",
  WHAT_CONDITION:
    "If my CST is associated with a specific condition, does that mean I have the condition?",
  WHAT_NEXT: "What can I do next?",
  WHAT_ASSOCIATION: "What is a CST association?",
  NEXT_DESCRIPTION:
    "For many conditions, the vaginal microbiome is just one piece of the puzzle, and it’s important to remember that your lifestyle choices, genetics, and other factors can influence your likelihood of developing any condition. Always talk to your healthcare provider to better understand how these associations may apply to you.|The good news is that your vaginal microbiome is a modifiable part of the puzzle, and it can shift with your behaviors and treatments. We’re here to help you ensure that your vaginal microbiome is working in your favor!|*We’re also here support you — reach out anytime at support@evvy.com*",
};

export const EVVY_PEER_REVIEW_COPY = {
  TITLE:
    "The Evvy test is peer-reviewed and analytically validated by CLIA, CAP, and CLEP.",
  DESCRIPTION:
    "This means our test has undergone rigorous evaluation by independent experts, [setting a new standard](https://www.evvy.com/blog/peer-reviewed-study-confirms-accuracy-of-evvys-vaginal-microbiome-test) for vaginal microbiome testing.||Note: Evvy only reports microbes that meet our validated threshold (1,390-1,850 CFU/mL for bacteria and 1,980 CFU/mL for fungi) to ensure the accuracy of results reported.",
};

export const EVVY_TEST_VERSION_COPY = {
  TITLE: "About Evvy Test Versions",
  DESCRIPTION:
    "One of the most exciting parts of retesting with Evvy is that our platform gets smarter over time. That’s because we use cutting-edge, metagenomics-based sequencing and continuously refine our algorithms and learn from thousands of diverse samples.||Each time you test, your results reflect the most up-to-date scientific understanding of the vaginal microbiome — and evolve as we do.||This means you’ll receive even more accurate insights into what’s going on in your body and how to best support your health over time. Just like your microbiome, we’re constantly learning and improving — so you can feel confident that your care is grounded in the latest research.",
  KEY_CHANGES_TITLE: "VMB v2.2 Key Changes",
  KEY_CHANGES_DESCRIPTION: "VMB v2.2, was released in April 2025.",
  KEY_CHANGE_1:
    "**Sharper strain differentiation** for key microbes like _Gardnerella_, _Lactobacillus_, and _Prevotella_.",
  KEY_CHANGE_2:
    "**Better detection of low-abundance microbes** which are organisms that can be hard to detect because they are usually present in very small amounts.",
};

export const KEY_MICROBES_NOT_DETECTED_COPY = {
  TITLE: "Key Microbes Not Detected",
  DESCRIPTION:
    "Evvy is the only vaginal microbiome test to receive [CLIA, CAP, and CLEP certification, as well as publish peer-reviewed validation](https://www.evvy.com/blog/peer-reviewed-study-confirms-accuracy-of-evvys-vaginal-microbiome-test) of our test. Evvy only reports microbes that meet our validated threshold to ensure the accuracy of results reported.||We test for 700+ microbes. Below is a list of the most clinically-relevant microbes that were **not** detected in your sample:",
};


const LOAD_DISPLAY_MAP = {
  L: "Low",
  M: "Medium",
  H: "High",
};

const DEFAULT_COMPARE_TABLE_DATA = {
  default: true,
  bacteria_valences: {
    BA: { bacteria: [], percent: 0 },
    GO: { bacteria: [], percent: 0 },
    NE: { bacteria: [], percent: 0 },
    UN: { bacteria: [], percent: 0 },
  },
  common_community_type: "",
  dominant_bacteria_name: "",
  health_history: {
    basics_last_period_date: "",
    diagnoses: [""],
    treatments: [""],
    symptoms_vaginal_discharge: [""],
    symptoms_vaginal_smell: [""],
  },
};

export {
  blurLevels,
  screens,
  screensWithFertility,
  healthContextDictionary,
  defaultNote,
  screens_mobile,
  MENU_ITEMS,
  CST_COPY,
  BACTERIA_COPY,
  DISCLAIMER_COPY,
  NEXT_COPY,
  NEXT_COPY_V2,
  modalTypes,
  smallTypeSvgConfig,
  largeTypeSvgConfig,
  SYMPTOM_SCORES,
  SYMPTOM_COLORS,
  REVERSE_COLORS,
  RESULTS_COPY,
  SECTION_TYPES,
  CATEGORY_KEYS,
  WHATS_UP_COPY,
  TRENDS_COPY,
  DATA_TYPES,
  TYPES_DICTIONARY,
  HEALTH_ASSOCIATIONS_COPY,
  TREND_DIRECTION,
  DEFAULT_COMPARE_TABLE_DATA,
  PAGE_SECTION_TITLES,
  GOOD_HEALTH_NAME,
  LOAD_DISPLAY_MAP,
  STI_RESULTS_COPY,
};
