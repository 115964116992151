import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  sendProviderClickedContinueRegistration,
  sendProviderClickedSignInFromRegistration,
  sendProviderClickedViewTerms,
  sendProviderViewedRegistrationPage,
} from "../../utils/analytics/customEventTracking";
import { MAX_PASSWORD_LENGTH } from "../../components/account/constants";

type RegisterProviderFormValues = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
};

export const RegisterProvider = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    sendProviderViewedRegistrationPage();
  }, []);

  const initialValues: RegisterProviderFormValues = {
    email: "",
    password: "",
    first_name: "",
    last_name: "",
  };

  return (
    <LayoutAuth title="" metaTitle="Provider | Register" subtitle="">
      <h3 className="text-[2em] mb-12 text-center">
        Welcome! Create an Evvy Provider account to access tests for your
        patients
      </h3>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoading(true);
          setError("");

          const { password } = values;

          if (password.length > MAX_PASSWORD_LENGTH) {
            setError("Password is too long");
            setLoading(false);
            return;
          }

          try {
            await authService.registerProvider(values);
            authService.fetchUser(
              () => {
                const next = getParameterByName("next", window.location);
                if (next) {
                  window.location = next;
                } else {
                  //@ts-ignore
                  window.location = "/";
                }
              },
              () => {
                console.error("Failed to fetch user info");
              }
            );
          } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
              if (error.response?.status === 400) {
                setError(error.response.data);
              }
            } else {
              setError("Could not sign up");
            }

            setLoading(false);
          }
        }}
      >
        <Form className="space-y-6">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first_name" className="uppercase font-medium">
                First Name
              </label>
              <Field
                id="first_name"
                name="first_name"
                type="text"
                autoFocus
                autoComplete="given-name"
                required
                style={{ fontSize: "16px" }}
                className="mt-1 appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last_name" className="uppercase font-medium">
                Last Name
              </label>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                required
                style={{ fontSize: "16px" }}
                className="mt-1 appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="uppercase font-medium">
              Email Address
            </label>
            <Field
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              style={{ fontSize: "16px" }}
              className="mt-1 appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
            />
          </div>

          <div>
            <label htmlFor="password" className="uppercase font-medium">
              Password
            </label>
            <Field
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              minLength={8}
              style={{ fontSize: "16px" }}
              className="mt-1 appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              data-html={true}
              data-tip="<div>
    14 or more characters<br/>
    Alphanumeric characters<br/>
    No common passwords<br/>
    Password can't be similar to email
  </div>"
            />
            <ReactTooltip
              place="right"
              backgroundColor="#F3F1EE"
              textColor="#000000"
              effect="solid"
            />
          </div>
          <div className="flex items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              required
              className="bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
            />
            <label htmlFor="terms" className="block font-medium ml-2">
              I have read and agree to the{" "}
              <a
                href="https://www.evvy.com/terms-of-service" // TODO: Update link for providers terms of service
                className="underline uppercase text-sm font-semibold tracking-wider"
                target="_blank"
                rel="noreferrer"
                onClick={sendProviderClickedViewTerms}
              >
                Terms and Conditions
              </a>
              . and acknowledge the{" "}
              <a
                href="https://www.evvy.com/privacy-policy"
                className="underline uppercase text-sm font-semibold tracking-wider"
                target="_blank"
                rel="noreferrer"
                onClick={sendProviderClickedViewTerms}
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>

          {error ? (
            <div className="bg-coral p-2 px-3 font-medium rounded-xs">
              {error}
            </div>
          ) : (
            ""
          )}

          <div className="items-center justify-between sm:flex-row-reverse">
            <button
              type="submit"
              className="cursor-pointer mt-6 sm:mt-0 justify-center w-full py-6 px-4 tracking-wider font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
              disabled={loading}
              onClick={sendProviderClickedContinueRegistration}
            >
              {"Continue Registration →"}
            </button>

            <div className="mr-auto mt-6">
              Already have an account?{" "}
              <Link
                to="/login/"
                className="underline uppercase text-sm font-semibold tracking-wider"
                onClick={() => {
                  sendProviderClickedSignInFromRegistration();
                }}
              >
                Sign In
              </Link>
            </div>
            <div className="mt-4">
              If you already have an account with us as an Evvy patient, you'll
              need to create a new provider account with a different email
              address.
            </div>
          </div>
        </Form>
      </Formik>
    </LayoutAuth>
  );
};
