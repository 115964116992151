import Layout from "../components/layout";
import StickyHeader from "../components/common/stickyHeader";
import { ResultsLoadingAnimation } from "./results_v2/viz";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  sendConsultIntakeLoadingScreenClickedBack,
  sendConsultIntakeViewedLoading,
} from "../utils/analytics/customEventTracking";
import { ConsultsContext } from "../contexts/consultsContext";
import { useProcessOrder } from "../hooks/useProcessOrder";
import { Order, OrderLineItem } from "../types/orders";
import BlueRectangularButton from "../components/common/blueRectangularButton";

const ORDER_PROCESSING_COPY =
  "Processing your order — please do not refresh. You’ll be automatically navigated back to your intake!";

const getRedirectLinkBasedOnOrder = (
  order: Order,
  consultUid?: string,
  firstName?: string,
  lastName?: string,
  orderEmail?: string
) => {
  // Redirects for care purchases or upsells
  if (consultUid) {
    if (order.checkout_source === "vaginitis-upsell") {
      // Ungated Rx upsell from vaginitis intake -- continuing vaginitis intake
      return `/care/rx/consults/${consultUid}/intake/treatment/shipping/`;
    } else if (order.checkout_source === "ungated-rx-upsell") {
      // Ungated Rx upsell from consult intakes -- continuing ungated RX intake
      const hasBoricAcid = order.line_items.some(
        (item: OrderLineItem) => item.is_urx_boric_acid
      );
      if (hasBoricAcid) {
        return `/care/rx/consults/${consultUid}/intake/boric-acid/`;
      } else {
        return `/care/rx/consults/${consultUid}/intake/shipping/`;
      }
    } else if (order.checkout_source === "health-history") {
      // Ungated Rx upsell from HH -- starting ungated RX intake
      return `/care/rx/consults/${consultUid}/intake/consent/`;
    } else {
      // General care intake link -- starting vaginitis intake
      return `/care/consults/${consultUid}/intake/demographics/`;
    }
  }

  // If they weren't redirected from an ungated upsell, then check the order line items
  if (order.line_items.some((item: OrderLineItem) => item.is_expan_upsell)) {
    // If they have the expanded PCR add-on, then redirect to the history intake
    return "/tests/intake/history/";
  } else if (
    order.line_items.some((item: OrderLineItem) => item.order_type === "UX")
  ) {
    // TODO: support redirecting to the treatments/login or treatments/register page
    return `/treatments/login?orderNumber=${order.order_number}&checkoutId=${order.checkout_id}&email=${orderEmail}&firstName=${firstName}&lastName=${lastName}`;
  }

  return "/tests/";
};

const LoadingOrder: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [source, setSource] = useState<string | null | undefined>(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const { refetchConsults } = useContext(ConsultsContext);
  const urlParams = new URLSearchParams(
    window.location.search.replace(/\+/g, "%2B")
  );
  const checkoutId = urlParams.get("checkoutId");
  const orderEmail = decodeURIComponent(urlParams.get("email") || "");
  const firstName = urlParams.get("firstName") || "";
  const lastName = urlParams.get("lastName") || "";

  const navigate = useNavigate();
  const { data, isLoading, refetchProcessOrder } = useProcessOrder(
    checkoutId,
    orderEmail
  );

  const redirectToNextPage = (order: Order, consult_uid?: string) => {
    const redirectLink = getRedirectLinkBasedOnOrder(
      order,
      consult_uid,
      firstName,
      lastName
    );
    if (redirectLink) {
      navigate(redirectLink);
    }
  };

  useEffect(() => {
    if (data && !isLoading) {
      if (error) {
        setError(error);
      } else {
        const { consult_uid, order } = data;
        if (order) {
          const checkoutSource = order.checkout_source;
          setSource(checkoutSource);
          sendConsultIntakeViewedLoading({
            checkoutId,
            checkoutSource: checkoutSource,
          });
          refetchConsults();
          redirectToNextPage(order, consult_uid);
        }
      }
    }
    if (refetchCount < 5) {
      refetchProcessOrder();
      setRefetchCount(refetchCount + 1);
    }
  }, [isLoading]);

  return (
    <Layout
      title={`Order Processing`}
      bgClass="bg-evvy-cream"
      full
      centered
      header={
        <StickyHeader
          backLink="/tests/"
          className="bg-evvy-cream"
          onClickBack={() => {
            sendConsultIntakeLoadingScreenClickedBack({
              checkoutId,
              checkoutSource: source,
            });
          }}
        />
      }
    >
      {error && <div>{error}</div>}
      {refetchCount < 5 ? (
        <div className="w-full sm:w-1/2">
          <div className="mt-20 sm:-mt-20" />
          <ResultsLoadingAnimation />
          <div className="mt-20 sm:-mt-20 mb-20 medium text-lg md:text-xl text-center px-4">
            {ORDER_PROCESSING_COPY}
          </div>
        </div>
      ) : (
        <div className="mt-20 w-full sm:max-w-1/2 p-8">
          <div className="mb-10 medium text-lg md:text-xl text-center px-4">
            Your order was unable to be processed. Please contact support at
            support@evvy.com.
          </div>
          <BlueRectangularButton
            fullWidth
            text="Go to my tests ->"
            onClick={() => {
              navigate("/tests/");
            }}
          />
        </div>
      )}
    </Layout>
  );
};

export default LoadingOrder;
