"use client";
import React, { useMemo, useState, useRef } from "react";
import { ScoreCard } from "../components/scoreCard";
import { HorizontalDivider, VerticalDivider } from "../components/Icons";
import {
  ResultsBaseAnalyticsArgs,
  FertilityModule,
  ScoreDetails,
  ScoreDefinitionDetails,
} from "../types";
import { transformModuleData } from "./utils";
import { FertilityDisruptor } from "./fertilityDisruptor";
import { STICard } from "./stiCard";
import { ScoreInsightRow } from "../components/ScoreInsightRow";
import { getIsMobile } from "../../../../utils/general";
import {
  DISCLAIMER_COPY,
  FERTILITY_DISRUPTOR_COPY,
  STI_STATUS_COPY,
} from "./constants";
import { LockedCard } from "./lockedCard";
import classNames from "classnames";
import { sendClickedScoreCard } from "../../../../utils/analytics/customEventTracking";
interface FertilityInsightsProps {
  moduleData: FertilityModule;
  setModalData: (data: { type: string; data: any }) => void;
  hasVPCRResults: boolean;
  analyticsArgs: ResultsBaseAnalyticsArgs;
}

export const FertilityInsights: React.FC<FertilityInsightsProps> = ({
  moduleData,
  setModalData,
  hasVPCRResults,
  analyticsArgs,
}) => {
  const isMobile = getIsMobile();
  const data = transformModuleData(moduleData);

  // Create refs for each card
  const protectiveCardRef = useRef<HTMLDivElement>(null);
  const shannonCardRef = useRef<HTMLDivElement>(null);
  const microbeCardRef = useRef<HTMLDivElement>(null);
  const stiCardRef = useRef<HTMLDivElement>(null);

  const [selectedScores, setSelectedScores] = useState<ScoreDetails[]>(
    !isMobile ? [data.scores.protective] : []
  );

  const hasSTIScores = Object.keys(data.scores.sti.scores).length > 0;

  const stiCardSelected = useMemo(() => {
    return hasSTIScores
      ? selectedScores.some(
          (score) => score?.score_definition?.score_type === "sti"
        )
      : false;
  }, [selectedScores]);

  const fertilityDisruptorSelected = useMemo(() => {
    return selectedScores.some(
      (score) => score?.score_definition?.score_type === "microbe"
    );
  }, [selectedScores]);

  const handleSelectScoreCard = (scores: ScoreDetails[]) => {
    sendClickedScoreCard({
      testOrder: analyticsArgs.testOrder,
      testHash: analyticsArgs.testHash,
      cst: analyticsArgs.cst,
      moduleName: "fertility",
      scoreSlug: scores?.length > 0 ? scores[0].score_definition?.slug : "",
      scoreQualitativeValue:
        data.scores?.fertilityDisruptors?.summary?.total_abundance,
    });

    if (isMobile) {
      if (scores.length > 0 && selectedScores.includes(scores[0])) {
        setSelectedScores(
          selectedScores.filter((score) => !scores.includes(score))
        );
      } else {
        // Get the appropriate ref based on the score type
        const scoreType = scores[0]?.score_definition?.score_type;
        const scoreSlug = scores[0]?.score_definition?.slug;
        let targetRef: HTMLDivElement | null = null;

        if (scoreSlug === "protective") {
          targetRef = protectiveCardRef.current;
        } else if (scoreSlug === "shannon-diversity") {
          targetRef = shannonCardRef.current;
        } else if (scoreType === "microbe") {
          targetRef = microbeCardRef.current;
        } else if (scoreType === "sti") {
          targetRef = stiCardRef.current;
        }

        if (targetRef) {
          // Add a small delay to ensure DOM has updated with new scroll height
          setTimeout(() => {
            targetRef?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
        setSelectedScores((prevScores) => [...prevScores, ...scores]);
      }
    } else {
      setSelectedScores(scores);
    }
  };

  return (
    <>
      <main
        id="fertility"
        className={classNames(
          "scroll-mt-24 sm:mx-[90px] mx-[16px] sm:mb-36 mb-24 my-0 bg-white rounded-xl max-w-[1260px]",
          {
            "-mt-24 scroll-mt-36": isMobile,
          }
        )}
      >
        <header className="flex flex-col gap-3 items-center px-0 pt-12 mx-auto my-0 text-center max-w-[610px] max-sm:px-8 max-sm:py-8">
          <h2 className="sm:text-center text-left mb-0 text-neutral-900">
            {data.header.title}
            <span className="italic serif">{data.header.title_suffix}</span>
          </h2>
          <p className="sm:text-center text-left leading-5 text-neutral-900">
            {data.header.subtitle}
          </p>
        </header>
        {!isMobile && <HorizontalDivider />}
        <div className="flex sm:gap-12 gap-0 px-12 py-0 max-md:flex-col max-md:px-6 max-md:py-0 max-sm:px-4 max-sm:py-0">
          <section className="flex flex-col flex-1 gap-3 px-0 sm:py-12">
            {data.scores.protective && (
              <div className="scroll-mt-24">
                <ScoreCard
                  ref={protectiveCardRef}
                  score={data.scores.protective}
                  score_definition={data.scores.protective?.score_definition}
                  onSelect={() => {
                    handleSelectScoreCard([data.scores.protective]);
                  }}
                  isExpanded={selectedScores.includes(data.scores.protective)}
                  setModalData={setModalData}
                  analyticsArgs={analyticsArgs}
                />
              </div>
            )}
            {data.scores["shannon-diversity"] && (
              <div className="scroll-mt-24">
                <ScoreCard
                  ref={shannonCardRef}
                  score={data.scores["shannon-diversity"]}
                  score_definition={
                    data.scores["shannon-diversity"].score_definition
                  }
                  onSelect={() => {
                    handleSelectScoreCard([data.scores["shannon-diversity"]]);
                  }}
                  isExpanded={selectedScores.includes(
                    data.scores["shannon-diversity"]
                  )}
                  setModalData={setModalData}
                  analyticsArgs={analyticsArgs}
                />
              </div>
            )}
            <div className="scroll-mt-24">
              <FertilityDisruptor
                ref={microbeCardRef}
                fertilityDisruptorMicrobes={
                  data.scores.fertilityDisruptors?.scores
                }
                fertilityDisruptorMicrobesSummary={
                  data.scores.fertilityDisruptors?.summary
                }
                onSelect={() => {
                  handleSelectScoreCard(
                    Object.values(data.scores.fertilityDisruptors?.scores || {})
                  );
                }}
                isExpanded={fertilityDisruptorSelected}
                setModalData={setModalData}
                analyticsArgs={analyticsArgs}
              />
            </div>
            {hasSTIScores && hasVPCRResults ? (
              <div className="scroll-mt-24">
                <STICard
                  ref={stiCardRef}
                  stiScores={data.scores.sti}
                  onSelect={() => {
                    handleSelectScoreCard(
                      Object.values(data.scores.sti?.scores)
                    );
                  }}
                  isExpanded={stiCardSelected}
                  setModalData={setModalData}
                  analyticsArgs={analyticsArgs}
                />
              </div>
            ) : (
              <LockedCard
                lockedCardTitle={"STI status"}
                lockedCardCopy={
                  "This test did NOT include STI testing. Requires Expanded PCR Panel add-on."
                }
              />
            )}
          </section>
          <VerticalDivider />
          <div className="flex flex-col flex-1 sm:pt-16 pt-0 sm:mb-24">
            {!isMobile && (
              <section className="sm:sticky sm:top-24 sm:overflow-y-auto pb-24">
                {/* STI insight header */}
                {stiCardSelected && (
                  <div>
                    <p className="text-2xl semibold text-neutral-900 mb-0">
                      {STI_STATUS_COPY.header.title}
                    </p>
                    <p className="text-base medium text-neutral-900">
                      {STI_STATUS_COPY.header.subtitle}
                    </p>
                  </div>
                )}
                {/* Fertility disruptor insight header */}
                {fertilityDisruptorSelected && (
                  <div>
                    <p className="font-semibold text-neutral-900 mb-2 text-[20px]">
                      {FERTILITY_DISRUPTOR_COPY.header.title}
                    </p>
                    <p className="text-base medium text-neutral-900 pb-0 mb-0">
                      {FERTILITY_DISRUPTOR_COPY.header.subtitle}
                    </p>
                  </div>
                )}
                {selectedScores.map((score) => (
                  <ScoreInsightRow
                    key={score.score_definition.slug}
                    score={score}
                    setModalData={setModalData}
                    analyticsArgs={analyticsArgs}
                  />
                ))}
              </section>
            )}
            <footer className="bg-evvy-white z-10 pt-6 pr-0 pb-8 sm:pl-8 mt-auto text-xs italic text-neutral-900 text-opacity-50">
              {DISCLAIMER_COPY}
            </footer>
          </div>
        </div>
      </main>
    </>
  );
};

export default FertilityInsights;
