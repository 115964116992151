/**
 * Symptoms section (lower table) of the compare/trends table
 * @param {string} symptomCategory a string defining the symptom (1st column)
 * @param {string} symptomDetailCurrent a string defining the symptom from a previous/compare test (2nd column)
 * @param {string} symptomDetailLast a string defining the symptom from the current test (2rd column)
 * @param {boolean} hide a boolean to toggle the css class to hide/show the full list of symptoms
 * @param {boolean} shortlist a boolean to determine which rows to hide on page load
 *
 */

// react
import React from "react";

import { useResponsiveSize } from "../../../utils/customHooks";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { secondaryColors } from "../../../utils/viz";

const TrendsTableHistoryRow = ({
  symptomCategory,
  symptomDetailCurrent,
  symptomDetailLast,
  hide,
  shortlist,
  setModalData,
}) => {
  const [[width, height, isMobile]] = useResponsiveSize(window.innerWidth, 700);

  return (
    <div
      className={`grid grid-cols-2 gap-4 md:gap-8 trend-row justify-between px-4 md:px-8 py-4 my-2 bg-evvy-cream text-left ${
        hide ? "hidden" : ""
      } ${shortlist ? "trend-shortlist" : ""}`}
      style={{ gridTemplateColumns: isMobile ? "" : ".75fr 1fr 1fr .25fr" }}
    >
      <div
        className={`text-left capitalizeFirst text-lg font-semibold ${
          isMobile && `col-span-2`
        }`}
      >
        {symptomCategory === "Test version" ? (
          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={setModalData}
          >
            {symptomCategory}
            <InfoIcon
              stroke={secondaryColors["evvy-black-dull"]}
              fill={secondaryColors["evvy-black-dull"]}
              className="mt-0 w-4 h-4 inline"
            />
          </div>
        ) : (
          <>{symptomCategory}</>
        )}
      </div>
      <div className="text-left capitalizeFirst text-base font-medium">
        {symptomDetailLast}
      </div>
      <div className="text-left capitalizeFirst text-base font-medium">
        {symptomDetailCurrent}
      </div>
    </div>
  );
};

export default TrendsTableHistoryRow;
