import Tippy from "@tippyjs/react/headless";
import { cn } from "../../../utils/cn";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import { ReactComponent as Info } from "../../plan_unfurled/icons/info.svg";
import WarningIcon from "../icons/warning";
import { TransparentCareProductConfig } from "../../../types/care";
import { getPurposeTagText } from "../utils";
import classNames from "classnames";
import { EvvyMarkdown } from "../../common/evvyMarkdown";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import {
  ELIGIBILITY_WINDOW_TREATMENT_COPY,
  PREGNANCY_WARNING_TREATMENT_COPY,
} from "../constants";

const Tooltip = ({
  copy,
  doNotTakeIfPregnant,
  showTryingToConceiveWarning,
}: {
  copy: string;
  doNotTakeIfPregnant?: boolean;
  showTryingToConceiveWarning?: boolean;
}) => (
  <Tippy
    arrow
    render={() => (
      <div
        id="tooltip"
        role="tooltip"
        className="leading-snug bg-evvy-pine p-4 rounded-lg max-w-sm text-evvy-white"
        tabIndex={-1}
      >
        <EvvyMarkdown>{copy}</EvvyMarkdown>
      </div>
    )}
    placement="bottom"
    animation={false}
  >
    <button type="button" className="focus:outline-none">
      {doNotTakeIfPregnant && showTryingToConceiveWarning ? (
        <WarningIcon
          fill="none"
          stroke="#000000"
          outlineStroke="#000000"
          className="ml-1 w-[12px] h-[12px] text-evvy-black-dull/75"
        />
      ) : (
        <Info
          stroke="currentColor"
          fill="currentColor"
          className="ml-1.5 w-4 h-4 text-evvy-black-dull/75"
        />
      )}
    </button>
  </Tippy>
);

const TransparentCarePhasePill: React.FC<{
  phase: string;
  className?: string;
  daysUntilExpiry?: number;
  productConfig?: TransparentCareProductConfig;
  doNotTakeIfPregnant?: boolean;
  tryingToConceive?: boolean;
}> = ({
  phase,
  className,
  daysUntilExpiry,
  productConfig,
  doNotTakeIfPregnant,
  tryingToConceive,
}) => {
  const currentUser = useLoggedInUser();
  const fertilityV0Enabled = currentUser?.features.fertilityV0Enabled;
  const showTryingToConceiveWarning = fertilityV0Enabled && tryingToConceive;

  const getTooltipCopy = () => {
    if (showTryingToConceiveWarning) {
      if (doNotTakeIfPregnant && !daysUntilExpiry) {
        return PREGNANCY_WARNING_TREATMENT_COPY;
      } else if (doNotTakeIfPregnant && daysUntilExpiry) {
        return (
          ELIGIBILITY_WINDOW_TREATMENT_COPY +
          " " +
          PREGNANCY_WARNING_TREATMENT_COPY
        );
      }
    }
    return ELIGIBILITY_WINDOW_TREATMENT_COPY;
  };

  return (
    <div
      className={cn(
        "uppercase text-sm font-semibold rounded-full border border-black border-opacity-5 px-4 xs:px-2.5 py-1 xs:py-0.5 z-10 flex items-center justify-center",
        { "max-w-min": !daysUntilExpiry },
        transparentCarePhaseColorMap[
          phase as keyof typeof transparentCarePhaseColorMap // hacky type assertion to appease TS
        ].full,
        className
      )}
    >
      <span className={classNames({ "sm:block hidden": daysUntilExpiry })}>
        {getPurposeTagText(phase)}{" "}
      </span>
      {/* Only show the category on desktop (e.g. "Treat - 21 days left" is just "21 days left" on mobile) */}
      {daysUntilExpiry && (
        <span
          className={classNames({
            "sm:block hidden ml-1 mr-1": daysUntilExpiry,
          })}
        >
          -
        </span>
      )}
      <span> {daysUntilExpiry ? `${daysUntilExpiry} days left` : ""} </span>
      {((phase === "treat" && daysUntilExpiry) ||
        (showTryingToConceiveWarning && doNotTakeIfPregnant)) &&
        productConfig === "individual" && (
          <Tooltip
            copy={getTooltipCopy()}
            doNotTakeIfPregnant={doNotTakeIfPregnant}
            showTryingToConceiveWarning={showTryingToConceiveWarning}
          />
        )}
    </div>
  );
};

export default TransparentCarePhasePill;
