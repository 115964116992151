import React, { useMemo } from "react";

import OutlinedButton from "../plan_unfurled/outlinedButton";
import { TreatmentFAQButton } from "./treatmentFAQButton";
import { ReactComponent as Chat } from "../../components/plan_unfurled/icons/chat.svg";
import { ReactComponent as Coach } from "../../components/plan_unfurled/icons/coach.svg";
import { ReactComponent as Heart } from "../../components/plan_unfurled/icons/heart.svg";
import { ReactComponent as Blood } from "../../components/common/icons/blood.svg";
import { ReactComponent as Swirl } from "../../components/common/icons/swirl.svg";
import { ReactComponent as Checklist } from "../../components/common/icons/checklist.svg";

import PrescriptionCard from "./PrescriptionCard";

import { secondaryColors } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import {
  getWeekCalendlyURL,
  getWeekCTA,
  TREATMENT_ORDER_BULLETS,
  TREATMENT_ORDER_MATTERS_TITLE,
  TRYING_TO_CONCEIVE_BULLETS,
  TRYING_TO_CONCEIVE_SOON_TITLE,
  TRYING_TO_CONCEIVE_TITLE,
} from "./constants";
import { useIntercom } from "react-use-intercom";
import usePrescriptions from "../../hooks/care/usePrescriptions";

import {
  Consult,
  Prescription,
  TreatmentPlan as TreatmentPlanType,
} from "../../types/care";
import {
  SEXUAL_ACTIVITY_TITLE,
  SEXUAL_ACTIVITY_P1,
  SEXUAL_ACTIVITY_P2,
  PERIOD_TITLE,
  PERIOD_P1,
  PERIOD_P2_TREATMENT_ONLY,
  TIPS_TITLE,
  TIPS_DESCRIPTION,
  TIPS_BULLETS,
} from "./constants";
import { TreatmentCalendarExport } from "./treatmentCalendar/treatmentCalendarExport";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import classNames from "classnames";

type TreatmentPlanProps = {
  consult: Consult;
  treatmentPlan: TreatmentPlanType;
  isMenopausal: boolean;
  openPrescriptionModal: (prescription: Prescription) => void;
  baseUrl: string;
  retestLink: string;
};

type TreatmentBestPracticeCardProps = {
  icon: "heart" | "blood" | "checklist" | "swirl";
  title: string;
  bullets: string[];
  description?: string;
};

const TreatmentBestPracticeCard: React.FC<TreatmentBestPracticeCardProps> = ({
  title,
  bullets,
  icon,
  description,
}) => {
  const renderIcon = () => {
    switch (icon) {
      case "heart":
        return <Heart stroke={secondaryColors["evvy-pine"]} />;
      case "blood":
        return (
          <Blood stroke={secondaryColors["evvy-pine"]} className="mt-0.5" />
        );
      case "swirl":
        return (
          <Swirl fill={secondaryColors["evvy-pine"]} className="w-5 h-5" />
        );
      case "checklist":
        return (
          <Checklist className="mb-2" stroke={secondaryColors["evvy-pine"]} />
        );
    }
  };

  return (
    <div className="p-6">
      <div className="b1 medium mb-4 flex items-center">
        <div className="w-8 h-8 flex items-center justify-center">
          {renderIcon()}
        </div>
        <div className="ml-2 leading-5">{title}</div>
      </div>
      {description && <div className="b2 mb-2">{description}</div>}
      <ul className="b2 list-disc mt-2">
        {bullets.map((bullet, index) => (
          <li
            key={bullet}
            className={classNames({
              "mt-2 mb-0": index !== 0,
            })}
          >
            {bullet}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const getTreatmentOrderMattersBullets = (
  treatmentPlan: TreatmentPlanType,
  consult: Consult
) => {
  const bullets = [];
  const isAlaCareTreatmentPlan = consult?.purchase_type === "a-la-care";
  const hasBoricAcid = treatmentPlan?.prescriptions?.some(
    (prescription) => prescription.type === "boric_acid"
  );
  const hasAntibiotics = treatmentPlan?.prescriptions?.some(
    (prescription) => prescription.type === "antibiotic"
  );
  const hasProbiotic = treatmentPlan?.prescriptions?.some(
    (prescription) => prescription.type === "probiotic"
  );
  if (isAlaCareTreatmentPlan) {
    if (hasBoricAcid && hasAntibiotics) {
      bullets.push(TREATMENT_ORDER_BULLETS["boricAcid"]);
    }
    if (hasAntibiotics && hasProbiotic) {
      bullets.push(TREATMENT_ORDER_BULLETS["antibiotics"]);
    }
  }
  return bullets;
};

export const getTryingToConceiveTitle = (consult: Consult) => {
  return consult?.trying_to_conceive
    ? consult?.trying_to_conceive_soon
      ? TRYING_TO_CONCEIVE_SOON_TITLE
      : TRYING_TO_CONCEIVE_TITLE
    : null;
};

const TreatmentPlan: React.FC<TreatmentPlanProps> = ({
  consult,
  treatmentPlan,
  isMenopausal,
  openPrescriptionModal,
  baseUrl,
  retestLink,
}) => {
  const { show } = useIntercom();
  const currentUser = useLoggedInUser();
  const fertilityV0Enabled = currentUser?.features.fertilityV0Enabled;

  const isAlaCareTreatmentPlan = consult?.purchase_type === "a-la-care";
  const planType = consult?.plan_type;
  const isTreatmentPlan = planType === "treatment";

  const { prescriptionsWithRefillsRemaining } = usePrescriptions(consult?.uid);
  const selectedTreatmentSlugs = consult.selected_treatments?.map(
    (treatment) => treatment.slug
  );

  const treatmentTagGroupingOrder = ["treat", "rebuild", "relief"];

  const treatmentOrderMattersBullets = getTreatmentOrderMattersBullets(
    treatmentPlan,
    consult
  );
  const tryingToConceiveTitle = fertilityV0Enabled
    ? getTryingToConceiveTitle(consult)
    : null;

  return (
    <>
      <div className="bg-white py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-center capitalize">Your Treatments</h2>
          <div className="mt-12 block md:grid grid-cols-5 sm:space-x-8">
            <div className="col-span-3 space-y-6">
              {treatmentPlan ? (
                treatmentPlan?.prescriptions
                  ?.sort((a, b) => {
                    if (consult.purchase_type !== "a-la-care") return 0;

                    const indexA = treatmentTagGroupingOrder.indexOf(
                      a.purpose_tag ?? ""
                    );
                    const indexB = treatmentTagGroupingOrder.indexOf(
                      b.purpose_tag ?? ""
                    );

                    if (indexA === -1) return 1; // move 'a' to the end if its purpose_tag is not found
                    if (indexB === -1) return -1; // move 'b' to the end if its purpose_tag is not found

                    return indexA - indexB; // sort based on the predefined order treat, rebuild, relief
                  })
                  ?.map((prescription) => (
                    <PrescriptionCard
                      key={prescription.slug}
                      prescription={prescription}
                      refillablePrescriptions={
                        prescriptionsWithRefillsRemaining
                      }
                      selectedTreatmentSlugs={selectedTreatmentSlugs}
                      consult={consult}
                      openModal={() => openPrescriptionModal(prescription)}
                    />
                  ))
              ) : (
                <div className="p-4 bg-evvy-cream rounded-md text-gray-500">
                  Loading prescriptions...
                </div>
              )}

              {consult.purchase_type === "a-la-care" && (
                <TreatmentCalendarExport
                  treatmentPlan={treatmentPlan}
                  consult={consult}
                  baseUrl={baseUrl}
                  retestLink={retestLink}
                  isMenopausal={isMenopausal}
                  tryingToConceiveTitle={tryingToConceiveTitle}
                  treatmentOrderMattersBullets={treatmentOrderMattersBullets}
                />
              )}
            </div>

            <div className="col-span-2 space-y-6 mt-8 sm:mt-0">
              <div className="bg-highlighter-mint-dull rounded-md">
                <div className="p-4 uppercase font-semibold tracking-wider text-sm text-white bg-evvy-pine rounded-t-md">
                  {planType
                    ? `Best practices during ${planType}`
                    : "Best practices"}
                </div>
                <div className="rounded-b-md">
                  {fertilityV0Enabled && tryingToConceiveTitle ? (
                    <div>
                      <TreatmentBestPracticeCard
                        title={tryingToConceiveTitle}
                        bullets={[
                          TRYING_TO_CONCEIVE_BULLETS[0],
                          TRYING_TO_CONCEIVE_BULLETS[1],
                        ]}
                        icon="swirl"
                      />
                      <hr className="bg-highlighter-mint m-0" />
                    </div>
                  ) : (
                    <TreatmentBestPracticeCard
                      title={SEXUAL_ACTIVITY_TITLE}
                      bullets={[
                        SEXUAL_ACTIVITY_P1[planType],
                        SEXUAL_ACTIVITY_P2[planType],
                      ]}
                      icon="heart"
                    />
                  )}
                  {treatmentOrderMattersBullets?.length > 0 && (
                    <div>
                      <TreatmentBestPracticeCard
                        title={TREATMENT_ORDER_MATTERS_TITLE}
                        bullets={treatmentOrderMattersBullets}
                        icon="heart"
                      />
                      <hr className="bg-highlighter-mint m-0" />
                    </div>
                  )}
                  <hr className="bg-highlighter-mint m-0" />
                  {!isMenopausal && (
                    <TreatmentBestPracticeCard
                      title={PERIOD_TITLE}
                      bullets={
                        isTreatmentPlan
                          ? [PERIOD_P1, PERIOD_P2_TREATMENT_ONLY]
                          : [PERIOD_P1]
                      }
                      icon="blood"
                    />
                  )}
                  <hr className="bg-highlighter-mint m-0" />
                  <TreatmentBestPracticeCard
                    title={TIPS_TITLE}
                    bullets={TIPS_BULLETS}
                    description={TIPS_DESCRIPTION}
                    icon="checklist"
                  />
                </div>
              </div>
              {!isAlaCareTreatmentPlan && (
                <div className="mt-8 max-w-xs inline-block space-y-4">
                  <div className="t3 medium mb-4 text-evvy-pine">
                    Have Questions?
                  </div>
                  <OutlinedButton
                    icon={
                      <Chat
                        stroke={secondaryColors["evvy-pine"]}
                        width="100%"
                        height="100%"
                      />
                    }
                    text="Chat with our care team"
                    color="evvy-pine"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.TREATMENT_ASK_QUESTION,
                      eventArgs: {
                        consultId: consult?.uid,
                        testHash: treatmentPlan?.test?.hash,
                        location: "your treatment",
                      },
                      clickHandler: () => show(),
                    })}
                  />
                  <TreatmentFAQButton
                    analyticsArgs={{
                      consultId: consult?.uid,
                      testHash: treatmentPlan?.test?.hash,
                    }}
                  />
                  {/* Only if the current week has a CTA and only for treatment plans */}
                  {isTreatmentPlan &&
                    !isAlaCareTreatmentPlan &&
                    treatmentPlan &&
                    treatmentPlan.calendar &&
                    treatmentPlan.calendar.current_treatment_day &&
                    getWeekCTA(
                      treatmentPlan.calendar.current_treatment_day.week,
                      treatmentPlan.calendar.last_treatment_week
                    ) && (
                      <OutlinedButton
                        href={getWeekCalendlyURL()}
                        icon={
                          <Coach
                            stroke={secondaryColors["evvy-pine"]}
                            width="100%"
                            height="100%"
                          />
                        }
                        text="Book a coaching call"
                        color="evvy-pine"
                        onClick={analyticsClickHandler({
                          eventName: eventNames.TREATMENT_CLICKED_COACHING,
                          eventArgs: {
                            consultId: consult?.uid,
                            testHash: treatmentPlan?.test?.hash,
                            location: "your treatment",
                          },
                        })}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentPlan;
