import React from "react";
import { analyticsClickHandler } from "../../../../utils/analytics/helpers";
import {
  eventNames,
  sendClickedSupportingResearchTag,
} from "../../../../utils/analytics/customEventTracking";
import { ResultsBaseAnalyticsArgs, ScoreDetails } from "../types";
import classNames from "classnames";
import { BulletPoint, DottedDivider } from "./Icons";
import { EvvyMarkdown } from "../../../../components/common/evvyMarkdown";
import PlusButtonPill from "../../../../components/results_v2/plusButtonPill";
import { modalTypes } from "../../constants";
import { getIsMobile } from "../../../../utils/general";

interface ScoreInsightRowProps {
  score: ScoreDetails;
  setModalData?: (data: { type: string; data: any }) => void;
  analyticsArgs: ResultsBaseAnalyticsArgs;
}

export const ScoreInsightRow: React.FC<ScoreInsightRowProps> = ({
  score,
  setModalData,
  analyticsArgs,
}) => {
  const isMobile = getIsMobile();
  return (
    <div key={score.id}>
      <div className="mb-4">
        {score.score_definition.score_type !== "numeric" && !isMobile && (
          <div className="sm:mb-8">
            <DottedDivider />
          </div>
        )}
        <p
          className={classNames("text-neutral-900 mb-4 sm:block hidden", {
            t4: score.score_definition.score_type !== "numeric",
            "text-2xl semibold":
              score.score_definition.score_type === "numeric",
          })}
        >
          {score.score_definition.display_name}
        </p>
        {score.score_definition.score_type === "numeric" && (
          <p className="sm:block hidden text-base medium text-neutral-900">
            {score.score_definition.subtitle}
          </p>
        )}
        {score.score_definition.score_type === "numeric" && (
          <p
            className={classNames(
              "text-neutral-500 text-[12px] mb-0 font-semibold uppercase tracking-[0.05em] mb-4",
              {
                "text-neutral-900": !isMobile,
              }
            )}
          >
            LEARN MORE{!isMobile && ":"}
          </p>
        )}

        {score.research_association_description &&
          score.research_association_description
            .split(/\n|\|/)
            .map((line, index) => (
              <div key={index} className={classNames("flex gap-2 items-start")}>
                <BulletPoint />
                <div className="flex-1 mb-2 leading-relaxed">
                  <EvvyMarkdown>{line.trim()}</EvvyMarkdown>
                </div>
              </div>
            ))}
        <div className="sm:ml-8 ml-0 sm:mt-2 mt-4">
          <p
            className={classNames("text-neutral-900 mb-0 semibold", {
              b2: !isMobile,
              "b3 mb-2": isMobile,
            })}
          >
            Fertility Associations:
          </p>
          <div className="flex flex-wrap gap-1">
            {score.research_associations &&
              score.research_associations.map((data) => (
                <PlusButtonPill
                  key={`button-${data.name}`}
                  text={data.name}
                  useArrowIcon
                  className="bg-white text-evvy-pine"
                  onClick={() => {
                    sendClickedSupportingResearchTag({
                      testOrder: analyticsArgs.testOrder,
                      testHash: analyticsArgs.testHash,
                      cst: analyticsArgs.cst,
                      moduleName: "fertility",
                      scoreSlug: score.score_definition?.slug,
                      associationTitle: data.name,
                      scoreQualitativeValue: score.qualitative_value ?? "none",
                    });
                    setModalData?.({
                      type: modalTypes.HEALTH_ASSOCIATIONS,
                      data,
                    });
                  }}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
