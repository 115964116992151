import { usePanelCart } from "../../../hooks/cart/usePanelCart";
import { TreatmentGroups } from "../../../types/care";
import { CART_ITEM_TYPE } from "../../../types/cart";
import RectangularButton from "../../common/rectangularButton";
import BundleEcommFeatures from "../transparentCare/BundleEcommFeatures";
import ALaCareTreatmentCard from "./ALaCareTreatmentCard";
import WarningIcon from "../icons/warning";
import { useEffect, useState } from "react";
import { Test } from "../../../types/test";
import { sendCareClickedAddAllToCart } from "../../../utils/analytics/customEventTracking";
import ActiveInfectionNoTreatmentsWarningModal from "../ActiveInfectionNoTreatmentsWarningModal";
import { secondaryColors } from "../../../utils/viz";

interface ALaCareRecommendationsProps {
  recommendedALaCareTreatments?: TreatmentGroups;
  test?: Test;
}

const ALaCareRecommendations: React.FC<ALaCareRecommendationsProps> = ({
  recommendedALaCareTreatments,
  test,
}) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const hasSeenWarningModal = localStorage.getItem(
    `seen-active-infection-modal-${test?.hash}`
  )
    ? true
    : false;

  const careEligibilityExpirationWindow = 3 * 7 * 24 * 60 * 60 * 1000; // 3 weeks
  const careEligibilityExpired = test?.results_ready_at
    ? Date.now() >
      Date.parse(test.results_ready_at) + careEligibilityExpirationWindow
    : false;

  const resultsReadyAtDate = test?.results_ready_at;
  const hasActiveInfection = test?.has_active_infection;
  const shouldShowWarningModal = hasActiveInfection && careEligibilityExpired;

  // First time opening the page, if they haven't seen the warning modal + should see it
  useEffect(() => {
    if (!hasSeenWarningModal && shouldShowWarningModal) {
      setWarningModalOpen(true);
      localStorage.setItem(`seen-active-infection-modal-${test?.hash}`, "true");
    }
  }, []);

  const {
    addToCart,
    getIfALaCareTreatmentInCart,
    setShowCart,
    getCartItemByTreatmentSlug,
    baseCartAnalyticsArgs,
  } = usePanelCart();
  if (
    !recommendedALaCareTreatments ||
    Object.keys(recommendedALaCareTreatments).length === 0
  )
    return null;
  const { treat, rebuild, relief } = recommendedALaCareTreatments;
  const allRecommendedALaCareTreatments = [
    ...(treat?.treatments || []),
    ...(rebuild?.treatments || []),
    ...(relief?.treatments || []),
  ];
  const allALaCareTreatmentsInCart = allRecommendedALaCareTreatments.every(
    (treatment) => {
      return getIfALaCareTreatmentInCart(treatment.slug);
    }
  );

  const itemsToAdd = allRecommendedALaCareTreatments
    .filter((treatment) => {
      return !getIfALaCareTreatmentInCart(treatment.slug);
    })
    .map((treatment) => {
      return {
        item_type: "individual-treatment" as CART_ITEM_TYPE,
        quantity: 1,
        treatment_product_id: treatment.id,
        treatment_product: treatment,
      };
    });

  const addAllRecommendedALaCareTreatmentsToCart = () => {
    if (allALaCareTreatmentsInCart) {
      setShowCart(true);
    } else {
      addToCart(itemsToAdd);
      sendCareClickedAddAllToCart({
        addedTreatments: itemsToAdd.map((item) => item.treatment_product.slug),
        addedBundle: false,
        ...baseCartAnalyticsArgs,
      });
    }
  };

  return (
    <div className="max-w-[1045px] mx-auto px-3 lg:px-0 pt-6 pb-8 sm:pb-11 flex md:justify-between justify-center flex-wrap lg:flex-nowrap">
      <div className="md:max-w-[450px] flex flex-col justify-between mb-4 sm:mb-0">
        {/* believe it or not this is actually an intentional empty div and not meant to be a fragment because the parent needs to justify between the two divs */}
        <div>
          <div className="text-sm font-semibold uppercase mb-3">
            Based on your results & symptoms
          </div>
          <h2 className="text-[40px]">
            Rx Treatments{" "}
            <span className="bg-highlighter-mint/70 whitespace-nowrap">
              for you
            </span>
          </h2>
          <div className="mb-6">
            These treatments* have been prescribed to people with similar
            symptoms and test results.
          </div>
          {shouldShowWarningModal && (
            <div
              className="flex items-center mb-4"
              onClick={() => setWarningModalOpen(!warningModalOpen)}
            >
              <WarningIcon
                fill={secondaryColors["evvy-starfish"]}
                outlineStroke="none"
                className="w-[20px] h-[20px]"
              />
              <p className="t4 mb-0 border-b border-evvy-black cursor-pointer ml-2">
                IMPORTANT NOTE
              </p>
            </div>
          )}
          {warningModalOpen && resultsReadyAtDate && (
            <ActiveInfectionNoTreatmentsWarningModal
              resultsReadyAtDate={resultsReadyAtDate}
              setWarningModalOpen={setWarningModalOpen}
            />
          )}
          <RectangularButton
            text={allALaCareTreatmentsInCart ? "Added" : "Add to cart"}
            textColorClass="text-evvy-black"
            verticalPadding="py-5"
            fullWidth
            onClick={addAllRecommendedALaCareTreatmentsToCart}
            icon={allALaCareTreatmentsInCart ? "checkmark" : ""}
          />
          <BundleEcommFeatures />
        </div>
        <div className="italic text-[12px] text-evvy-black/50 hidden md:block mb-4">
          *Any prescriptions will be determined at the sole discretion of an
          Evvy-affiliated provider. Individual results for any given product may
          vary.
        </div>
      </div>
      <div className="lg:max-w-1/2 grid grid-cols-2 gap-3.5 md:gap-4 lg:gap-8">
        {Object.entries(recommendedALaCareTreatments).flatMap(
          ([phase, treatments]) =>
            treatments.treatments.map((treatment) => (
              <div
                key={`${phase}-${treatment.slug}`}
                className="w-full flex justify-center"
              >
                <ALaCareTreatmentCard
                  treatment={treatment}
                  phase={phase}
                  addToCart={() => {
                    const itemToAdd = {
                      item_type: "individual-treatment" as CART_ITEM_TYPE,
                      quantity: 1,
                      treatment_product_id: treatment.id,
                      treatment_product: treatment,
                    };
                    addToCart([itemToAdd]);
                  }}
                  openCart={() => setShowCart(true)}
                  inCart={
                    getCartItemByTreatmentSlug(treatment.slug) ? true : false
                  }
                  tryingToConceive={test?.is_trying_to_conceive}
                />
              </div>
            ))
        )}
      </div>
      <div className="italic text-[12px] text-evvy-black/50 block md:hidden mt-5">
        *Any prescriptions will be determined at the sole discretion of an
        Evvy-affiliated provider. Individual results for any given product may
        vary.
      </div>
    </div>
  );
};

export default ALaCareRecommendations;
