import { Link, useNavigate } from "react-router-dom";

// components
import RectangularButton from "../common/rectangularButton";
import BacteriaIcon from "./icons/bacteria-icon.svg";
import PillsIcon from "./icons/pills-icon.svg";
import BottleIcon from "./icons/bottle-icon.svg";

// library
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import Markdown from "markdown-to-jsx";
import { getButtonCTATextForConsultation } from "../care/utils";

// Constants
import { CARE_CARD_LEARN_MORE, CARE_CARD_IMAGE_SUBTEXT } from "./constants";
import {
  CARE_DEFAULT_VALUE_PROP_BULLETS,
  CARE_SHIPPING_HSA_COPY,
} from "../care/constants";

const CARE_DEFAULT_VALUE_PROPS = [
  {
    icon: BacteriaIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[0],
  },
  {
    icon: PillsIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[1],
  },
  {
    icon: BottleIcon,
    text: CARE_DEFAULT_VALUE_PROP_BULLETS[2],
  },
];

// Care component shown on the Plan page.
const CareCard = ({
  consult,
  analyticsEventArgs,
}: {
  consult: any;
  analyticsEventArgs: any;
}) => {
  const ctaCopy = getButtonCTATextForConsultation(consult);
  const navigate = useNavigate();

  const careDisclaimerCopy =
    "Your specific program is determined by a provider.";
  const careCardHeader = "Put your plan into action";
  const careCardSubhead = "Get clinical care with Evvy";

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl m-auto md:py-16">
        <div className="relative md:block hidden my-auto">
          <img
            src={"/images/graphics/care-packaging-tile-square.png"}
            loading="lazy"
            alt="Care Packaging"
            className="rounded-2xl hidden md:block"
          />
          <p className="py-3 px-4 text-center top-0 left-0 right-auto bottom-auto my-auto absolute bg-highlighter-yellow font-semibold text-sm tracking-wider uppercase rounded-tl-2xl rounded-br-2xl w-fit ">
            {CARE_CARD_IMAGE_SUBTEXT}
          </p>
        </div>
        <div className="flex flex-col p-4 md:ml-16 my-auto md:px-8">
          <div className="space-x-2 mx-auto md:mx-0">
            <span className="bg-highlighter-yellow rounded-full py-1 px-2.5 font-semibold text-sm tracking-wider uppercase my-auto">
              New!
            </span>
            <span className="font-semibold text-sm tracking-wider uppercase my-auto ">
              {careCardHeader}
            </span>
          </div>
          <h3 className="md:block hidden m-0 my-4">{careCardSubhead}</h3>
          <h3 className="md:hidden m-0 text-center my-4">{careCardSubhead}</h3>
          <div className="relative md:hidden my-4">
            <img
              src={"/images/graphics/care-packaging-tile.png"}
              loading="lazy"
              alt="Care Packaging"
              className="rounded-2xl w-full"
            />
            <p className="py-3 px-4 text-center my-auto absolute bottom-0 left-0 w-full bg-highlighter-yellow rounded-b-2xl font-semibold text-sm tracking-wider uppercase">
              {CARE_CARD_IMAGE_SUBTEXT}
            </p>
          </div>
          {CARE_DEFAULT_VALUE_PROPS.map((valueProp, index) => (
            <>
              <div className="flex mt-4">
                <img
                  src={valueProp.icon}
                  alt={`Icon ${index}`}
                  key={index}
                  className="w-10 h-10 ml-2 mr-6"
                />
                <p className="b2 my-auto">{valueProp.text}</p>
              </div>
              <hr className="opacity-70 m-0 mt-4" />
            </>
          ))}
          <div className="mt-5 sm:mt-8">
            <RectangularButton
              text={ctaCopy}
              bgColorClass="bg-evvy-blue"
              textColorClass="text-evvy-black"
              onClick={analyticsClickHandler({
                eventName: eventNames.PLAN_CLICKED_START_CONSULT,
                eventArgs: {
                  ...analyticsEventArgs,
                  ctaCopy: ctaCopy,
                  location: "plan-care-card",
                },
                clickHandler: () => navigate("/care/"),
              })}
              fullWidth
              verticalPadding="py-6"
            />
            <div className="b2 mt-4 text-center md:text-left caption">
              <Markdown>
                {`${CARE_SHIPPING_HSA_COPY} ${careDisclaimerCopy}`}
              </Markdown>
              {` `}
              <Link
                to="/care/"
                className="inline border-b border-evvy-black cursor-pointer"
                onClick={analyticsClickHandler({
                  eventName: eventNames.PLAN_CLICKED_LEARN_MORE_CARE,
                  eventArgs: {
                    ...analyticsEventArgs,
                    location: "plan-care-card",
                    ctaText: ctaCopy,
                  },
                })}
              >
                {CARE_CARD_LEARN_MORE}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareCard;
