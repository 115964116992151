/**
 * A card styled with a gradient slider
 * @param {object} scoreData
 * @param {string} scoreType "protective" or "disruptive"
 * @param {string} boxShadowClass tailwind class string for box shadow (highlights card on scroll)
 * @returns jsx of a card
 */

import React from "react";

// imported components
import Markdown from "markdown-to-jsx";

// local components
import ScoreGradient from "./scoreGradient";
import { getIsMobile } from "../../../utils/general";
import { ReactComponent as LightBulbOn } from "../icons/lightbulb-on.svg";
import { DatabaseVersionSageV2_2 } from "../../../types/test";

const ScoreCard = ({
  scoreType,
  scoreData,
  boxShadowClass = "",
  initial = true,
  databaseVersion,
}: {
  scoreType: string;
  scoreData: any;
  boxShadowClass?: string;
  initial?: boolean;
  databaseVersion?: string;
}) => {
  const {
    title,
    score_description: description,
    score_category_description: categoryDescription,
    value,
  } = scoreData || {};

  const isMobile = getIsMobile();
  return (
    <div className={`bg-white p-7 rounded-[10px] ${boxShadowClass}`}>
      <div className={`${isMobile ? "t3" : "b0 capitalize"} semibold`}>
        {title}
      </div>
      <div className="b2 my-4">{description}</div>
      <h1 className="serif my-6">
        {scoreData.score_type === "number" ? value.toFixed(1) : `${value}%`}
      </h1>
      <ScoreGradient scoreType={scoreType} score={initial ? 0 : value} />
      <div className="b2 mt-6" id={"score" + scoreType}>
        <Markdown>{categoryDescription}</Markdown>
      </div>
      {scoreType === "protective" &&
        value === 100 &&
        databaseVersion === DatabaseVersionSageV2_2 && (
          <>
            <div className=" border-t border-dashed border-evvy-gray-100 my-4 xs:my-5" />
            <div className="flex items-center gap-2 sm:gap-4">
              <div className="w-10 h-10 relative flex-shrink-0">
                <div className="absolute inset-0 bg-gradient-to-t from-[#80a2d7] to-[#c4d2e5] rounded-full blur-[1.5px]" />
                <LightBulbOn className="fill-white w-10 h-10 p-2 relative z-10" />
              </div>
              <div className="text-sm text-evvy-gray-500">
                A Protective Score of 100% seems too good to be true... but it's
                actually a common result!
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default ScoreCard;
