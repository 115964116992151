import { useEffect, useState } from "react";
import { ConsultIntake, Prescription } from "../../../../types/care";
import { StethoscopeIcon } from "../../icons/stethoscopeIcon";
import PageWrapper from "../ungatedRx/PageWrapper";
import { useNavigate } from "react-router-dom";
import { careService } from "../../../../services/care";
import { useConsultAdditionalRecommendedProducts } from "../../../../hooks/care/useConsultAdditionalRecommendedProducts";
import TestUpsellCard from "./TestUpsellCard";
import { indexCheckboxStyles } from "../../../../utils/colors";
import LoadingSpinner from "../../../common/loadingSpinner";
import ALaCareTreatmentCard from "../../aLaCare/ALaCareTreatmentCard";
import {
  sendConsultIntakeClickedAdd,
  sendConsultIntakeClickedCheckout,
  sendConsultIntakeViewedUpsell,
  sendHealthHistorySymptomsUpsellCheckout,
  sendHealthHistorySymptomsUpsellClickedAdd,
  sendHealthHistorySymptomsUpsellViewed,
} from "../../../../utils/analytics/customEventTracking";
import BlueRectangularButton from "../../../common/blueRectangularButton";
import { Test } from "../../../../types/test";
import { useHealthHistoryRecommendedProducts } from "../../../../hooks/care/useHealthHistoryRecommendedProducts";
import { testsService } from "../../../../services/tests";
import classNames from "classnames";

const SymptomsUpsell = ({
  consultIntake,
  test,
  getNextPageFullUrl,
  source,
  symptoms,
}: {
  consultIntake?: ConsultIntake;
  test?: Test;
  getNextPageFullUrl?: () => string;
  source: "vaginitis-intake" | "ungated-rx-intake" | "health-history";
  symptoms?: string[];
}) => {
  const navigate = useNavigate();
  const { consultRecommendedProducts, consultRecommendedProductsIsLoading } =
    useConsultAdditionalRecommendedProducts(consultIntake?.consult.uid);
  const productType =
    source === "vaginitis-intake" ? "a-la-care" : "ungated-rx";

  const checkoutSourceHealthHistory =
    consultIntake?.is_checkout_source_health_context;

  const {
    healthHistoryRecommendedProducts,
    healthHistoryRecommendedProductsIsLoading,
  } = useHealthHistoryRecommendedProducts(test?.hash);
  const healthHistorySymptoms = healthHistoryRecommendedProducts?.symptoms;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [addedProducts, setAddedProducts] = useState<Prescription[]>([]);
  const [addedTest, setAddedTest] = useState<string>("");
  const isLoading =
    source === "health-history"
      ? healthHistoryRecommendedProductsIsLoading
      : consultRecommendedProductsIsLoading;

  // TODO: update test SKU once we support expan here
  const testSKU = "VMT001";

  // If they've already consented to not trying to conceive, we don't need to show the consent again
  const consentReceived =
    consultIntake?.currently_trying_to_conceive &&
    consultIntake?.currently_trying_to_conceive == "NO";

  const [
    productsRequiringConceiveConfirmation,
    setProductsRequiringConceiveConfirmation,
  ] = useState<Prescription[]>([]);

  const [hasSeenTryingToConceiveWarning, setHasSeenTryingToConceiveWarning] =
    useState<boolean>(false);

  const productAdded = (product: Prescription) => {
    return addedProducts.includes(product);
  };

  async function goToCheckout() {
    setLoading(true);
    try {
      let response;
      if (source !== "health-history") {
        // Update their consent if they've consented to the terms
        if (productsRequiringConceiveConfirmation.length > 0) {
          careService.asyncConsentToTryingNotToConceive(
            consultIntake?.consult.uid
          );
        }
        response = await careService.createConsultUpsellCheckoutUrl({
          consultUid: consultIntake?.consult.uid,
          data: {
            treatments: addedProducts,
            test: addedTest,
            checkout_source: source,
          },
        });
      } else if (test?.hash) {
        response = await testsService.asyncCreateUngatedRxCheckoutUrl({
          testHash: test?.hash,
          data: {
            treatments: addedProducts,
          },
        });
      }
      const checkoutURL = response.data.checkout_url;
      window.location = checkoutURL;
    } catch (error) {
      console.error(error);
      setError("Error creating checkout URL");
    }
  }

  const handleCheckConceiveConfirmation = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.checked) {
      setProductsRequiringConceiveConfirmation([]);
      setAddedProducts(
        addedProducts.filter((product) => !product.do_not_take_if_pregnant)
      );
    }
  };

  const handleSubmit = () => {
    // If there are items in the cart, call the get checkout URL endpoint and navigate there
    if (addedProducts?.length > 0 || addedTest) {
      let addedProductSkus: string[] = [];
      if (source === "vaginitis-intake") {
        addedProductSkus = addedProducts.map(
          (product) => product.ecomm_product?.sku
        );
      } else {
        addedProductSkus = addedProducts.map(
          (product) => product.ungated_rx_variant?.sku
        );
      }
      const selectedSkus = [addedProductSkus, ...(addedTest ? [testSKU] : [])];
      if (source === "health-history") {
        sendHealthHistorySymptomsUpsellCheckout({
          testHash: test?.hash,
          symptoms: symptoms,
          selectedSkus: selectedSkus,
          totalInCart: addedProducts.reduce((sum, product) => {
            const price = product.ungated_rx_variant?.price || 0;
            return sum + price;
          }, 0),
        });
      } else {
        sendConsultIntakeClickedCheckout({
          consultUid: consultIntake?.consult.uid,
          testHash: consultIntake?.consult?.test_hash,
          consultType: consultIntake?.consult.type,
          checkoutSource: source,
          selectedSkus: selectedSkus,
        });
      }
      // TODO: add event for health history
      goToCheckout();
    } else {
      // Otherwise, navigate to the next page
      if (getNextPageFullUrl) {
        navigate(getNextPageFullUrl());
      }
    }
  };

  const addProductToCart = (product: Prescription) => {
    if (!addedProducts.includes(product)) {
      if (source === "health-history") {
        sendHealthHistorySymptomsUpsellClickedAdd({
          testHash: test?.hash,
          symptoms: symptoms,
          sku: product.ungated_rx_variant?.sku,
        });
      } else {
        sendConsultIntakeClickedAdd({
          consultUid: consultIntake?.consult.uid,
          testHash: consultIntake?.consult?.test_hash,
          consultType: consultIntake?.consult.type,
          checkoutSource: source,
          sku:
            productType === "a-la-care"
              ? product.ecomm_product?.sku
              : product.ungated_rx_variant?.sku,
        });
      }
      if (product.do_not_take_if_pregnant) {
        if (!productsRequiringConceiveConfirmation.includes(product)) {
          setProductsRequiringConceiveConfirmation([
            ...productsRequiringConceiveConfirmation,
            product,
          ]);
          if (
            source === "ungated-rx-intake" &&
            !hasSeenTryingToConceiveWarning &&
            !consentReceived
          ) {
            setHasSeenTryingToConceiveWarning(true);
            const scrollToElement = (elementId: string) => {
              const element = document.getElementById(elementId);
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                });
              }
            };
            scrollToElement("warning");
          }
        }
      }
      setAddedProducts([...addedProducts, product]);
    } else {
      if (product.do_not_take_if_pregnant) {
        const updatedProductsRequiringConceiveConfirmation =
          productsRequiringConceiveConfirmation.filter((p) => p !== product);
        setProductsRequiringConceiveConfirmation(
          updatedProductsRequiringConceiveConfirmation
        );
        if (updatedProductsRequiringConceiveConfirmation.length === 0) {
          setHasSeenTryingToConceiveWarning(false);
        }
      }
      setAddedProducts(addedProducts.filter((p) => p !== product));
    }
  };

  let recommendedTreatmentsList: Prescription[] = [];
  if (source === "vaginitis-intake") {
    recommendedTreatmentsList =
      consultRecommendedProducts?.aLaCareTreatments || [];
  } else if (source === "ungated-rx-intake" && !checkoutSourceHealthHistory) {
    recommendedTreatmentsList =
      consultRecommendedProducts?.ungatedRxTreatments || [];
  } else if (source === "health-history") {
    recommendedTreatmentsList =
      healthHistoryRecommendedProducts?.ungatedRxTreatments || [];
  }

  useEffect(() => {
    let recommendedTreatmentsSkus =
      recommendedTreatmentsList?.map((product) =>
        productType === "a-la-care"
          ? product.ecomm_product?.sku
          : product.ungated_rx_variant?.sku
      ) || [];

    const allSkus = [...recommendedTreatmentsSkus, testSKU];
    if (source === "health-history") {
      sendHealthHistorySymptomsUpsellViewed({
        testHash: test?.hash,
        symptoms: symptoms,
        availableSKUs: allSkus,
      });
    } else {
      sendConsultIntakeViewedUpsell({
        consultUid: consultIntake?.consult.uid,
        testHash: consultIntake?.consult?.test_hash,
        consultType: consultIntake?.consult.type,
        checkoutSource: source,
        availableSKUs: allSkus,
      });
    }
  }, [consultRecommendedProducts, healthHistoryRecommendedProducts]);

  return (
    <div
      className={classNames({
        "max-w-full sm:max-w-[610px] mt-4": source === "health-history",
        "max-w-2xl": source !== "health-history",
      })}
    >
      {source !== "health-history" && (
        <div
          className="p-8 rounded-t-lg mt-6"
          style={{
            backgroundImage: `url('/images/graphics/upsell-header.png')`,
          }}
        >
          <div className="p-[10px] bg-white rounded-lg flex ">
            <div className="flex items-center">
              <div className="mr-4">
                <StethoscopeIcon strokeClassName="white" />
              </div>
              <div>
                <p className="t4 mb-0">From our experts</p>
                {
                  <p className="mb-0 b3">
                    {recommendedTreatmentsList.length > 0
                      ? "Based on your current symptoms, we think these treatments might help."
                      : "Evvy's vaginal microbiome test can help you get to the root cause."}
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      )}
      <PageWrapper roundTop={source === "health-history"}>
        {/* Product cards based on recommendations */}
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {source === "health-history" && (
              <div className="p-[10px] mb-4 bg-evvy-cream rounded-lg flex ">
                <div className="flex items-center">
                  <div className="mr-4">
                    <StethoscopeIcon strokeClassName="white" />
                  </div>
                  <div>
                    <p className="t4 mb-0">From our experts</p>
                    <p className="mb-1 b3">
                      {healthHistorySymptoms
                        ? `Get fast-acting symptom relief for your${
                            healthHistorySymptoms?.length > 0
                              ? ` ${healthHistorySymptoms
                                  .slice(0, -1)
                                  .join(", ")}`.toLowerCase()
                              : ""
                          }${healthHistorySymptoms?.length > 1 ? " and" : ""}${
                            healthHistorySymptoms?.length > 0
                              ? ` ${healthHistorySymptoms.slice(
                                  -1
                                )}`.toLowerCase()
                              : ""
                          } while we uncover long-term solutions and next steps.`
                        : "Get fast-acting symptom relief while we uncover long-term solutions and next steps."}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4">
              {recommendedTreatmentsList?.map((product) => (
                <ALaCareTreatmentCard
                  treatment={product}
                  phase={product.purpose_tag || "relief"}
                  productType={productType}
                  customAddToCart={addProductToCart}
                  inCart={productAdded(product)}
                  tryingToConceive={test?.is_trying_to_conceive}
                />
              ))}
              {consultRecommendedProducts?.test ? (
                <TestUpsellCard
                  added={addedTest}
                  setTestAdded={() => {
                    sendConsultIntakeClickedAdd({
                      consultUid: consultIntake?.consult.uid,
                      testHash: consultIntake?.consult?.test_hash,
                      consultType: consultIntake?.consult.type,
                      checkoutSource: source,
                      sku: testSKU,
                    });
                    setAddedTest(addedTest === "VHT" ? "" : "VHT");
                  }}
                />
              ) : null}
            </div>
            {source !== "health-history" && (
              <div className="mt-4 italic text-[12px] text-evvy-black/50 hidden md:block mb-4">
                *Any prescriptions will be determined at the sole discretion of
                an Evvy-affiliated provider. Individual results for any given
                product may vary.
              </div>
            )}
          </div>
        )}
        {source === "ungated-rx-intake" &&
          !consentReceived &&
          productsRequiringConceiveConfirmation?.length > 0 && (
            <div className="mt-4 flex align-start border-evvy-blue border-2 bg-evvy-blue-light p-4 rounded-lg cursor-pointer">
              <div className="mr-2">
                <input
                  type="checkbox"
                  name="notTryingToConceiveConfirmation"
                  id="notTryingToConceiveConfirmation"
                  checked={productsRequiringConceiveConfirmation?.length > 0}
                  onChange={handleCheckConceiveConfirmation}
                  className={indexCheckboxStyles(4)}
                />
              </div>
              <div>
                <p className={"medium b1 mb-1"}>
                  By continuing, I confirm that I am not trying to conceive.
                </p>
                <p className="medium text-xs italic mb-0 pb-0">
                  {productsRequiringConceiveConfirmation?.map(
                    (product, index) => {
                      if (index === 0) {
                        return product.title_short_display;
                      } else {
                        return "and " + product.title_short_display;
                      }
                    }
                  )}
                  {productsRequiringConceiveConfirmation?.length > 1
                    ? " are"
                    : " is"}{" "}
                  not safe to use if you are trying to conceive. Please un-check
                  this box if you are trying to conceive and we will remove this
                  from your cart.
                </p>
              </div>
            </div>
          )}
        {source === "health-history" && addedProducts?.length > 0 && (
          <div className="mt-4">
            <BlueRectangularButton
              type="submit"
              text="Checkout"
              paddingXClass="sm:px-32"
              onClick={handleSubmit}
              fullWidth
            />
          </div>
        )}
        {source === "health-history" && (
          <div className="mt-4 italic text-[12px] text-evvy-black/50 mb-4">
            *Any prescriptions will be determined at the sole discretion of an
            Evvy-affiliated provider. Individual results for any given product
            may vary.
          </div>
        )}
        {source == "ungated-rx-intake" && (
          <PageWrapper.FormFooter
            isSubmitting={false}
            handleSubmit={handleSubmit}
            buttonCTA={
              addedProducts.length > 0 || addedTest ? "Checkout" : "Next"
            }
            disabled={false}
          />
        )}
      </PageWrapper>
      {source === "vaginitis-intake" && (
        <div>
          <BlueRectangularButton
            type="submit"
            text="Continue"
            paddingXClass="sm:px-32"
            onClick={handleSubmit}
          />
        </div>
      )}
      <div id="warning" />
    </div>
  );
};

export default SymptomsUpsell;
