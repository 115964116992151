import React, { useEffect, useState } from "react";
import {
  ANSWER_NO,
  ANSWER_YES,
  Question,
  getRelatedDiagnosisMap,
  multidimensionalQuestionsWithPreferNotToSayMap,
} from "../../../utils/healthHistoryV2";
import {
  HealthContextFields,
  HealthContextRelatedDiagnosesFields,
} from "../../../types/test";
import QuestionTitle from "./questionTitle";
import { Field } from "formik";
import {
  indexCheckboxStyles,
  multiselectFollowupRadioStyles,
} from "../../../utils/colors";
import { UNSELECTED_OPTION } from "../../../utils/healthHistoryV2";
import { getQuestionTitle } from "../../../utils/questions";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import classNames from "classnames";
import { cn } from "../../../utils/cn";

// Validation and formatting of multidimensional questions happens in healthHistoryV2.ts in handle
// For a question to be valid, it must either have all options set to the default value (representing "None" being selected),
// OR all currently checked options must be set to a non-default value
// On submitting the form, all empty values are set to the default value to be stored in the database

const MultidimensionalQuestion = ({
  question,
  healthContext,
  handleChange,
  setFieldValue,
  setCurrentCheckedOptionsForPage,
  values,
}: {
  question: Question;
  healthContext: HealthContextFields;
  handleChange: () => void;
  setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void;
  setCurrentCheckedOptionsForPage: any;
  values: any;
}) => {
  const options = question.options;
  const currentOptions = question.getFollowupOptions?.({
    healthContextValues: healthContext,
  });
  const isMultiselectFollowupQuestion =
    question.optionAnswerType === "multiselect";

  const isDropdownFollowupQuestion =
    question.answerType === "multiselect_dimensional_dropdown";

  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    string[]
  >([]);

  // None option is selected if all other values have the question default selected
  const [noneOptionSelected, setNoneOptionSelected] = useState<boolean>(false);

  const handlePreferNotToSayOptionChange = (
    value: string,
    setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void
  ) => {
    const key = multidimensionalQuestionsWithPreferNotToSayMap[question.slug];
    setFieldValue(key, value);
  };

  const getPreferNotToSayOptionChecked = () => {
    const key = multidimensionalQuestionsWithPreferNotToSayMap[question.slug];
    return values[key] === ANSWER_YES;
  };

  // The question default (populated if the user does not select the checkbox)
  const defaultUnselectedOption = question
    ? UNSELECTED_OPTION[question.slug]
    : "";

  // Followup options that render below the checkbox:
  // e.g. prescription_treatments -> ["not-prescribed", "vaginal-symptom", "urinary-symptom", "other"]
  // ["vaginal-symptom", "urinary-symptom", "other"] are followup options, ["not-prescribed"] is the default
  const followupOptions = currentOptions?.filter(
    (option) => option[1] !== defaultUnselectedOption
  );

  // This use effect runs everytime the question changes
  useEffect(() => {
    const initialValue = question.getInitialValue({
      healthContextValues: healthContext,
    });

    let relatedDiagnosis: HealthContextRelatedDiagnosesFields | undefined;
    if (question.isRelatedDiagnosisFollowup) {
      // get condition for related diagnosis multidimensionals
      // "infertility_treatments" -> "infertility"
      const relatedDiagnoses = healthContext.healthcontextrelateddiagnoses_set;
      const relatedDiagnosisMap = getRelatedDiagnosisMap(relatedDiagnoses);
      relatedDiagnosis = relatedDiagnosisMap[question.slug.split("_")[0]];
    }

    // On first render, make sure to check none option if all options are "default"
    let allValuesDefault = options?.every((option) => {
      if (isMultiselectFollowupQuestion) {
        // Main one is the diagnoses question, which is stored as multiselect on the backend
        // (e.g. diagnoses_bv, diagnoses_candida, etc.)
        return initialValue[option as keyof HealthContextFields]?.includes(
          defaultUnselectedOption
        );
      } else if (relatedDiagnosis) {
        return (
          relatedDiagnosis?.[
            option as keyof HealthContextRelatedDiagnosesFields
          ] === defaultUnselectedOption
        );
      } else {
        // For singleselect multidimensional questions:
        // (e.g. symptoms_additional_dryness, symptoms_additional_excessive_discharge)
        return initialValue?.[option] === defaultUnselectedOption;
      }
    });

    if (initialValue && question.slug + "_other_selected" in initialValue) {
      allValuesDefault =
        allValuesDefault &&
        initialValue[question.slug + "_other_selected"] &&
        initialValue[question.slug + "_other_selected"] === ANSWER_NO;
    }

    // The prefer not to say option is initially not nested under the question slug as it is for the rest of the multidimensional questions options
    const preferNotToSaySelected =
      initialValue &&
      initialValue[
        multidimensionalQuestionsWithPreferNotToSayMap[question.slug]
      ] === ANSWER_YES;

    if (preferNotToSaySelected) {
      setFieldValue(
        multidimensionalQuestionsWithPreferNotToSayMap[question.slug],
        ANSWER_YES
      );
    } else if (allValuesDefault) {
      setNoneOptionSelected(true);
    } else {
      setNoneOptionSelected(false);
    }
  }, [question]);

  // This runs everytime values stored on frontend change (e.g. user checks/unchecks)
  useEffect(() => {
    // "selected" options are ones that don't equal the "default" value
    const allCheckboxValues = isDropdownFollowupQuestion
      ? Object.keys(values[question.slug])
      : Object.keys(values);

    const selected = allCheckboxValues.filter((field) => {
      let value = values[field as keyof HealthContextFields];
      // For dropdowns, the value is stored in a nested object
      if (isDropdownFollowupQuestion) {
        value = values[question.slug]?.[field];
      }
      if (Array.isArray(value)) {
        return value.length !== 0 && !value.includes(defaultUnselectedOption);
      } else {
        return value && value !== defaultUnselectedOption;
      }
    });
    setCurrentSelectedOptions(
      Array.from(new Set([...selected, ...currentSelectedOptions]))
    );
  }, [values]);

  // Clear default values if option not selected
  const setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty = (
    currentOption: string,
    setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void
  ) => {
    if (options) {
      for (const option of options) {
        if (isDropdownFollowupQuestion) {
          if (values[question.slug]?.[option] === defaultUnselectedOption) {
            setFieldValue(question.slug, {
              ...values[question.slug],
              [option]: "",
            });
          }
        } else {
          if (
            isMultiselectFollowupQuestion &&
            values[option]?.includes(defaultUnselectedOption)
          ) {
            setFieldValue(option, []);
          } else if (values[option] === defaultUnselectedOption) {
            setFieldValue(option, "");
          }
        }
      }
    }
    // Set current option to empty
    if (isDropdownFollowupQuestion) {
      setFieldValue(question.slug, {
        ...values[question.slug],
        [currentOption]: "",
      });
    } else {
      setFieldValue(currentOption, isMultiselectFollowupQuestion ? [] : "");
    }
  };

  // Clear all options regardless of current value
  const setAllValuesToEmpty = (
    setFieldValue: (fieldName: string, defaultAnswer: string | string[]) => void
  ) => {
    if (options) {
      for (const option of options) {
        if (isDropdownFollowupQuestion) {
          setFieldValue(question.slug, {
            ...values[question.slug],
            [option]: "",
          });
        } else if (isMultiselectFollowupQuestion) {
          setFieldValue(option, []);
        } else {
          setFieldValue(option, "");
        }
      }
    }
  };

  const handleCheckboxChange =
    ({
      handleChange,
      setFieldValue,
      option,
    }: {
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      setFieldValue: (fieldName: string, defaultAnswer: any) => void;
      option: string;
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e);
      // Handling checked -> unchecked
      if (!e.target.checked) {
        if (option?.includes("none") || option?.includes("prefer_not_to_say")) {
          // deselect none checkbox
          if (option?.includes("none")) {
            setNoneOptionSelected(false);
          }
          // set all other to emptys
          setAllValuesToEmpty(setFieldValue);
        } else {
          // set values to empty if currently populated with default value
          setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty(
            option,
            setFieldValue
          );
          setCurrentSelectedOptions(
            currentSelectedOptions.filter((value) => value !== option)
          );
          setCurrentCheckedOptionsForPage(
            currentSelectedOptions.filter((value) => value !== option)
          );
        }
      } else if (e.target.checked) {
        if (option?.includes("none") || option?.includes("prefer_not_to_say")) {
          // select "none" checkbox
          if (option?.includes("none")) {
            setNoneOptionSelected(true);
            handlePreferNotToSayOptionChange(ANSWER_NO, setFieldValue);
          } else {
            handlePreferNotToSayOptionChange(ANSWER_YES, setFieldValue);
            setNoneOptionSelected(false);
          }
          // set all other values to the default value
          if (options) {
            const defaultValues: Record<string, string> = {};
            if (
              values &&
              values[question.slug] &&
              question.slug + "_other_selected" in values[question.slug]
            ) {
              defaultValues[question.slug + "_other_selected"] = ANSWER_NO;
            }
            for (const option of options) {
              if (isDropdownFollowupQuestion) {
                options.forEach((option) => {
                  if (option?.includes("other_selected")) {
                    defaultValues[option] = ANSWER_NO;
                  } else {
                    defaultValues[option] = defaultUnselectedOption;
                  }
                });
                setFieldValue(question.slug, defaultValues);
              } else if (isMultiselectFollowupQuestion) {
                setFieldValue(option, [defaultUnselectedOption]);
              } else {
                setFieldValue(option, defaultUnselectedOption);
              }
            }
          }
          setCurrentSelectedOptions([]);
          setCurrentCheckedOptionsForPage([]);
        } else {
          // make sure the none option is not checked
          setNoneOptionSelected(false);
          handlePreferNotToSayOptionChange(ANSWER_NO, setFieldValue);
          // set values to empty if current populated with default value
          setCurrentOptionEmptyAndAllOtherDefaultValuesToEmpty(
            option,
            setFieldValue
          );
          if (option?.includes("other_selected")) {
            setFieldValue(question.slug, {
              ...values[question.slug],
              [option]: "YE",
            });
          } else {
            setCurrentSelectedOptions([...currentSelectedOptions, option]);
            setCurrentCheckedOptionsForPage([
              ...currentSelectedOptions,
              option,
            ]);
          }
        }
      }
    };

  return (
    <>
      <QuestionTitle questionTitle={question.slug} />
      <label className="mb-[10px] cursor-pointer flex items-center">
        <Field
          type="checkbox"
          name={"none"}
          value={noneOptionSelected}
          className={indexCheckboxStyles(0)}
          onChange={handleCheckboxChange({
            handleChange,
            setFieldValue,
            option: "none",
          })}
          checked={noneOptionSelected}
        />
        <span className="ml-2 leading-5">
          {getQuestionTitle("option_none")}
        </span>
      </label>
      {Object.keys(multidimensionalQuestionsWithPreferNotToSayMap).includes(
        question.slug
      ) && (
        <label className="mb-[10px] cursor-pointer flex items-center">
          <Field
            type="checkbox"
            name={multidimensionalQuestionsWithPreferNotToSayMap[question.slug]}
            value={
              values[
                multidimensionalQuestionsWithPreferNotToSayMap[question.slug]
              ]
            }
            className={indexCheckboxStyles(0)}
            onChange={handleCheckboxChange({
              handleChange,
              setFieldValue,
              option:
                multidimensionalQuestionsWithPreferNotToSayMap[question.slug],
            })}
            checked={getPreferNotToSayOptionChecked()}
          />
          <span className="ml-2 leading-5">
            {getQuestionTitle(
              multidimensionalQuestionsWithPreferNotToSayMap[question.slug]
            )}
          </span>
        </label>
      )}
      <div>
        {followupOptions &&
          options?.map((option, index) => (
            <div key={option}>
              <label className="mb-[10px] cursor-pointer flex items-center">
                <Field
                  type="checkbox"
                  name={option}
                  value={values[option as keyof HealthContextFields]}
                  className={indexCheckboxStyles(index)}
                  onChange={handleCheckboxChange({
                    handleChange,
                    setFieldValue,
                    option,
                  })}
                  checked={currentSelectedOptions?.includes(option)}
                />
                <span className="ml-2 leading-5">
                  {getQuestionTitle(option)}
                </span>
              </label>
              {/* Only render the follow-up options when option is selected */}
              {currentSelectedOptions?.includes(option) &&
                (isDropdownFollowupQuestion && !option?.includes("_other") ? (
                  <div>
                    <Field
                      as="select"
                      id={`${question.slug}.${option}`}
                      name={`${question.slug}.${option}`}
                      className={cn(
                        "ml-8 mb-2 border border-black border-opacity-20 rounded-xl sm:w-96 w-64 outline-none focus:ring-0 p-2"
                      )}
                      placeholder="Your Answer"
                      autoComplete="off"
                      value={values[question.slug]?.[option] || ""}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue(question.slug, {
                          ...values[question.slug],
                          [option]: e.target.value,
                        });
                      }}
                    >
                      <option value="" label="Select option" />
                      {followupOptions
                        ?.filter(
                          (followupOption) =>
                            followupOption[0] !== defaultUnselectedOption &&
                            !option?.includes("_other")
                        )
                        ?.map((followupOption) => (
                          <option
                            key={followupOption[0]}
                            value={followupOption[0]}
                          >
                            {followupOption[1]}
                          </option>
                        ))}
                    </Field>
                  </div>
                ) : (
                  <div
                    className={classNames("flex px-8 pb-2", {
                      "flex-col":
                        question.answerType === "multiselect_dimensional",
                      "flex-row":
                        question.answerType === "multiselect_dimensional_short",
                    })}
                  >
                    {followupOptions
                      ?.filter(
                        (followupOption) =>
                          followupOption[0] !== defaultUnselectedOption &&
                          !option?.includes("_other")
                      )
                      .map((followupOption, index) => (
                        <label
                          className={classNames(
                            "mb-[10px] w-auto text-left flex justify-center cursor-pointer p-3 rounded-xl mr-2",
                            {
                              "bg-evvy-blue-light":
                                isMultiselectFollowupQuestion
                                  ? values[option]?.includes(followupOption[0])
                                  : values[option] === followupOption[0],
                              "border-evvy-blue border-2":
                                isMultiselectFollowupQuestion
                                  ? values[option]?.includes(followupOption[0])
                                  : values[option] === followupOption[0],
                              "border-evvy-grey border hover:bg-evvy-blue-light hover:border-evvy-blue hover:border":
                                isMultiselectFollowupQuestion
                                  ? !values[option]?.includes(followupOption[0])
                                  : values[option] !== followupOption[0],
                              "sm:w-[393px]":
                                question.answerType ===
                                "multiselect_dimensional",
                              "w-full text-center":
                                question.answerType ===
                                "multiselect_dimensional_short",
                            }
                          )}
                          key={followupOption[0]}
                        >
                          <Field
                            type={"radio"}
                            id={`${option}-${followupOption[0]}`}
                            name={option}
                            value={followupOption[0]}
                            className={multiselectFollowupRadioStyles(
                              index,
                              question.answerType ===
                                "multiselect_dimensional_short"
                            )}
                            onChange={uncheckNoneChangeHandler({
                              handleChange,
                              setFieldValue,
                              fieldName: question.slug,
                              fieldValues: values[question.slug],
                            })}
                            checked={
                              isMultiselectFollowupQuestion
                                ? values[option]?.includes(followupOption[0])
                                : values[option] === followupOption[0]
                            }
                          />
                          <label
                            className={classNames("cursor-pointer w-full", {
                              "pl-2":
                                question.answerType ===
                                "multiselect_dimensional",
                            })}
                            htmlFor={`${option}-${followupOption[0]}`}
                          >
                            {followupOption[1]}
                          </label>
                        </label>
                      ))}
                  </div>
                ))}
            </div>
          ))}
      </div>
      {/* TODO: support more generalized "other" follow up for multiselect multi-dimensional questions */}
      {question.slug === "pregnancy_previously_experienced" && (
        <label className="mb-[10px] cursor-pointer flex items-center">
          <Field
            type="checkbox"
            name={"pregnancy_previously_experienced_other_selected"}
            value={
              values[question.slug][
                "pregnancy_previously_experienced_other_selected"
              ]
            }
            className={indexCheckboxStyles(0)}
            onChange={handleCheckboxChange({
              handleChange,
              setFieldValue,
              option: "pregnancy_previously_experienced_other_selected",
            })}
            checked={
              values[question.slug][
                "pregnancy_previously_experienced_other_selected"
              ] === "YE"
            }
          />
          <span className="ml-2 leading-5">
            {getQuestionTitle(
              "pregnancy_previously_experienced_other_selected"
            )}
          </span>
        </label>
      )}
    </>
  );
};

export default MultidimensionalQuestion;
