import Modal from "../common/modal";
import CloseX from "../common/closeX";
import WarningIcon from "./icons/warning";
import { formatDate } from "../../utils/datetime";
import { secondaryColors } from "../../utils/viz";

const ActiveInfectionNoTreatmentsWarningModal = ({
  resultsReadyAtDate,
  setWarningModalOpen,
}: {
  resultsReadyAtDate: string;
  setWarningModalOpen: (open: boolean) => void;
}) => {
  return (
    <Modal
      widthClass="w-full sm:w-[431px]"
      closeModal={() => setWarningModalOpen(false)}
    >
      <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
        <CloseX
          handleClose={() => setWarningModalOpen(false)}
          textColorClass="text-evvy-black"
        />
        <div className="flex flex-col justify-center items-center mt-4">
          <WarningIcon
            fill={secondaryColors["evvy-starfish"]}
            outlineStroke="none"
            className="w-[32px] h-[32px]"
          />
          <h3 className="mb-0">Attention</h3>
        </div>
        <div className="mt-8">
          <p>
            These treatments are designed to protect your microbiome & relieve
            symptoms, but they cannot treat an active infection.
          </p>
          <p>
            {`Your results and symptoms from ${
              resultsReadyAtDate
                ? formatDate(Date.parse(resultsReadyAtDate))
                : "your most recent test results"
            } showed signs of an
        infection. If you’re still experiencing symptoms, we
        recommend seeing your provider or taking another Evvy test.`}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ActiveInfectionNoTreatmentsWarningModal;
