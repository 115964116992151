import * as React from "react";
import { useEffect, useRef, useState } from "react";

interface ScoreGradientProps {
  /**
   * The current value to display on the gradient
   */
  value: number | string;
  /**
   * The minimum value of the scale
   */
  min: number | string;
  /**
   * The maximum value of the scale
   */
  max: number | string;
  /**
   * The threshold where the gradient transitions from red to yellow (0-100)
   */
  lowThreshold: number | string;
  /**
   * The threshold where the gradient transitions from yellow to green (0-100)
   */
  moderateThreshold: number | string;
  /**
   * Optional className for the container
   */
  className?: string;
  /**
   * Optional width for the gradient bar
   */
  width?: number | string;
  /**
   * Optional height for the gradient bar
   */
  height?: number;

  /**
   * Whether to use a green to red scale
   */
  greenToRedScale?: boolean;
}

// Helper function to define the color of the dot on the gradient
const interpolateColor = (
  percentage: number,
  normalizedLowThreshold: number,
  normalizedModerateThreshold: number,
  greenToRedScale: boolean
): string => {
  if (greenToRedScale) {
    if (percentage <= normalizedLowThreshold) {
      return "#DC6464"; // Red
    } else if (percentage <= normalizedModerateThreshold) {
      return "#F6B992"; // Orange/Yellow
    } else {
      return "#41907A"; // Green
    }
  } else {
    if (percentage <= normalizedLowThreshold) {
      return "#41907A"; // Green
    } else if (percentage <= normalizedModerateThreshold) {
      return "#F6B992"; // Orange/Yellow
    } else {
      return "#DC6464"; // Red
    }
  }
};

export const ScoreGradient: React.FC<ScoreGradientProps> = ({
  value,
  min,
  max,
  greenToRedScale = false,
  lowThreshold,
  moderateThreshold,
  className = "",
  width = "100%",
  height = 33,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(486); // Default width to prevent initial flash
  const containerRef = useRef<HTMLDivElement>(null);

  // Generate unique ID for this gradient instance
  const gradientId = useRef(
    `scoreGradient_${Math.random().toString(36).substr(2, 9)}`
  );

  // Convert string values to numbers
  const numericMin = Number(min);
  const numericMax = Number(max);
  const numericLowThreshold = Number(lowThreshold);
  const numericModerateThreshold = Number(moderateThreshold);
  const numericValue = Number(value);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          setContainerWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(containerRef.current);

    // Set initial width
    setContainerWidth(containerRef.current.offsetWidth);

    return () => resizeObserver.disconnect();
  }, []);

  // Calculate all the normalized values
  const percentage =
    ((numericValue - numericMin) / (numericMax - numericMin)) * 100;
  const lowThresholdPercentage =
    ((numericLowThreshold - numericMin) / (numericMax - numericMin)) * 100;
  const moderateThresholdPercentage =
    ((numericModerateThreshold - numericMin) / (numericMax - numericMin)) * 100;

  const normalizedPercentage = Math.max(0, Math.min(100, percentage));
  const normalizedLowThreshold = Math.max(
    0,
    Math.min(100, lowThresholdPercentage)
  );
  const normalizedModerateThreshold = Math.max(
    0,
    Math.min(100, moderateThresholdPercentage)
  );

  // Add padding for the indicator dot radius (6px)
  const dotRadius = 6;
  const effectiveWidth = typeof width === "number" ? width : containerWidth;
  const targetPosition = Math.max(
    dotRadius,
    Math.min(
      effectiveWidth - dotRadius,
      (normalizedPercentage / 100) * effectiveWidth
    )
  );

  // Set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Animate the indicator when visible
  useEffect(() => {
    if (isVisible) {
      setCurrentPosition(targetPosition);
    }
  }, [isVisible, targetPosition]);

  const indicatorColor = interpolateColor(
    normalizedPercentage,
    normalizedLowThreshold,
    normalizedModerateThreshold,
    greenToRedScale
  );

  return (
    <div
      ref={containerRef}
      className={className}
      role="img"
      aria-label={`Score: ${value} (Range: ${min} to ${max})`}
      style={{ width: width }}
    >
      <svg
        width="100%"
        height={height}
        viewBox={`0 0 ${effectiveWidth} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="score-bar-graph"
      >
        {/* Gradient Bar */}
        <rect
          y={(height - 10) / 2}
          width={effectiveWidth}
          height="10"
          rx="2"
          fill={`url(#${gradientId.current})`}
        />

        {/* Indicator */}
        <g filter="url(#indicatorShadow)">
          <circle
            cx={currentPosition}
            cy={height / 2}
            r={dotRadius}
            fill={indicatorColor}
            style={{ transition: "cx 1s ease-out" }}
          />
          <circle
            cx={currentPosition}
            cy={height / 2}
            r={dotRadius - 1}
            stroke="white"
            strokeWidth="2"
            style={{ transition: "cx 1s ease-out" }}
          />
        </g>

        {/* Definitions for gradient and filters */}
        <defs>
          <filter
            id="indicatorShadow"
            x="0"
            y="0"
            width="100%"
            height="100%"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_23475_4848"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_23475_4848"
              result="shape"
            />
          </filter>

          <linearGradient
            id={gradientId.current}
            x1="0"
            y1={height / 2}
            x2={effectiveWidth}
            y2={height / 2}
            gradientUnits="userSpaceOnUse"
          >
            {greenToRedScale ? (
              <>
                <stop offset="0" stopColor="#DC6464" />
                <stop
                  offset={`${normalizedLowThreshold}%`}
                  stopColor="#F6B992"
                />
                <stop
                  offset={`${normalizedModerateThreshold}%`}
                  stopColor="#41907A"
                />
                <stop offset="100%" stopColor="#41907A" />
              </>
            ) : (
              <>
                <stop offset="0" stopColor="#41907A" />
                <stop
                  offset={`${normalizedLowThreshold}%`}
                  stopColor="#41907A"
                />
                <stop
                  offset={`${normalizedModerateThreshold}%`}
                  stopColor="#F6B992"
                />
                <stop offset="100%" stopColor="#DC6464" />
              </>
            )}
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ScoreGradient;
