import { useState } from "react";

// types
import { STIResult } from "../../types/results";
// icons
import { ReactComponent as AlertIcon } from "../common/icons/alert-icon.svg";

import { ShowDetailButton } from "../common/showDetailButton";
import { BacteriaRowContent } from "./bacteriaRowContent";
import { sendExpandedResultsCard } from "../../utils/analytics/customEventTracking";
import { useBaseAnalyticsArgs } from "../../hooks/useBaseAnalyticsArgs";
import { getIsMobile } from "../../utils/general";
import { ReactComponent as ArrowUp } from "../common/icons/arrow-up-no-tail.svg";
import { ReactComponent as ArrowDown } from "../common/icons/arrow-down-no-tail.svg";

const POSITIVE = "Positive";
const NEGATIVE = "Negative";

type STIResultsProps = {
  className?: string;
  stiResults: STIResult[];
  analyticsOnClick: (fn: (() => void) | null, eventName: string) => any;
};

export const STIResults: React.FC<STIResultsProps> = ({
  className,
  stiResults,
}: STIResultsProps) => {
  return (
    <div className="space-y-2">
      {stiResults.map((stiResult) => (
        <STIResultRow stiResult={stiResult} />
      ))}
    </div>
  );
};

const STIResultRow: React.FC<{
  stiResult: STIResult;
}> = ({ stiResult }) => {
  const isPositive = stiResult.detected;
  const bacteria = stiResult.sti.bacteria;
  const [showDetails, setShowDetails] = useState(false);
  const isMobile = getIsMobile();
  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div
      className={`hover:cursor-pointer rounded-lg bg-white py-6 px-7 ${
        isPositive ? "border-t-[5px] border-evvy-dusty-red" : ""
      }`}
      onClick={() => {
        setShowDetails(!showDetails);
        sendExpandedResultsCard({
          cardType: "STI",
          bacteriaName: stiResult.sti.display_name,
          bacteriaId: stiResult.sti.bacteria.id,
          microbeCategory:
            stiResult.sti.bacteria.get_type_display?.toLowerCase(),
          ...baseAnalyticsArgs,
        });
      }}
    >
      <div className="flex justify-between">
        <div className="flex content-center">
          {isPositive ? (
            <AlertIcon className="w-5 h-5 my-auto" />
          ) : (
            <div className="rounded-full bg-evvy-dark-cream w-5 h-5 my-auto" />
          )}
          <div className={`${isMobile ? "b2 medium" : "t1"} pl-4 my-auto`}>
            {stiResult.sti.display_name}
          </div>
        </div>
        <div className="flex space-x-3">
          <div
            className={`rounded-full ${
              isMobile ? "text-[12px] uppercase font-semibold" : "t4"
            } ${
              isPositive ? "bg-evvy-dusty-red/25" : "bg-evvy-cream"
            } px-3 py-1`}
          >
            {isPositive ? POSITIVE : NEGATIVE}
          </div>
          <div className={`flex justify-center items-center `}>
            {showDetails ? (
              <ArrowUp className="h-4 w-4 fill-evvy-black" />
            ) : (
              <ArrowDown className="h-4 w-4 fill-evvy-black" />
            )}
          </div>
        </div>
      </div>
      {showDetails && (
        <div className="pt-2">
          <BacteriaRowContent bacteria={bacteria} open={showDetails} />
        </div>
      )}
    </div>
  );
};
