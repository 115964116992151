import React from "react";
// components
import CloseX from "../../common/closeX";
import { EvvyMarkdown } from "../../common/evvyMarkdown";
import { EVVY_TEST_VERSION_COPY } from "../../../pages/results_v2/constants";

const EvvyTestVersion = ({ handleClose }: { handleClose: any }) => {
  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className="w-full bg-evvy-white px-4 py-8 md:p-8">
      {/* close button */}
      <div className="flex justify-end items-center">
        <CloseX handleClose={handleClose} textColorClass="text-evvy-black" />
      </div>
      {/* title   */}
      <div className="t1 my-6">{EVVY_TEST_VERSION_COPY.TITLE}</div>
      <hr />
      {/* details  - new line at pipe indicator*/}
      <div className="b2 my-6">
        <p>
          <EvvyMarkdown newlineOnPipe>
            {EVVY_TEST_VERSION_COPY.DESCRIPTION}
          </EvvyMarkdown>
        </p>
        <p className="text-lg font-semibold text-evvy-pine">
          <EvvyMarkdown>
            {EVVY_TEST_VERSION_COPY.KEY_CHANGES_TITLE}
          </EvvyMarkdown>
        </p>
        <hr />
        <p>
          <EvvyMarkdown>
            {EVVY_TEST_VERSION_COPY.KEY_CHANGES_DESCRIPTION}
          </EvvyMarkdown>
        </p>
        <ol className="list-decimal list-outside">
          <li>
            <EvvyMarkdown>{EVVY_TEST_VERSION_COPY.KEY_CHANGE_1}</EvvyMarkdown>
          </li>
          <li>
            <EvvyMarkdown>{EVVY_TEST_VERSION_COPY.KEY_CHANGE_2}</EvvyMarkdown>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default EvvyTestVersion;
