import { Link, useNavigate } from "react-router-dom";
import LinkedText from "../../components/common/linkedText";
import {
  sendProviderClickedOrderFromResources,
  sendProviderClickedResourceLink,
  sendProviderViewedResourcesPage,
} from "../../utils/analytics/customEventTracking";
import { WHITE_PAPER_LINK } from "../constants";
import BlueRectangularButton from "../../components/common/blueRectangularButton";
import { useEffect } from "react";
import { cn } from "../../utils/cn";
import { useProviderProfile } from "../../hooks/provider/useProviderProfile";

const PROVIDER_INTERPRETATION_CTA = "Results Interpretation Guide";
const PROVIDER_INTERPRETATION_LINK =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/evvy-provider-results-interpretation-guide.pdf";

const METHODS_CTA = "Test Methods";
const METHODS_LINK_UNITED_STATES =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/sample-evvy-methods-united-states.pdf";
const METHODS_LINK_CANADA =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/sample-evvy-methods-canada.pdf";

const PATIENT_TALKING_POINTS_CTA = "Test Information For Patients";
const PATIENT_TALKING_POINTS_LINK_UNITED_STATES =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/provider-test-information-for-patients-united-states.pdf";
const PATIENT_TALKING_POINTS_LINK_CANADA =
  "https://evvy-public-content.s3.us-east-2.amazonaws.com/documents/provider-test-information-for-patients-canada.pdf";

const ASK_EVVY_BLOG_CTA = "Ask Evvy Blog";
const ASK_EVVY_BLOG_LINK = "https://www.evvy.com/ask";

const EVVY_TEST_VALIDATION_PAPER_CTA = "2024 Evvy Test Validation Paper";
const EVVY_TEST_VALIDATION_PAPER_LINK =
  "https://www.mdpi.com/2075-4418/14/18/2039";

const WHITE_PAPER_CTA = "Evvy Treatment's Clinical Study";

const IDSOG_2024_ABSTRACT_CTA =
  "IDSOG 2024 Abstract: Vaginal Odor and Prevotella";
const IDSOG_2024_ABSTRACT_LINK =
  "https://www.idsog.org/2024-annual-meeting/2024-program/";

const IDSOG_2023_ABSTRACT_GARDNERELLA_CTA =
  "IDSOG 2023 Abstract: Gardnerella Species Variation Show Pathogenic and Metabolic Differences";
const IDSOG_2023_ABSTRACT_GARDNERELLA_LINK =
  "https://www.ajog.org/article/S0002-9378(23)00656-7/fulltext";

const IDSOG_2023_ABSTRACT_VAGINITIS_CTA =
  "IDSOG 2023 Abstract: Incidence and Symptom Profiling of Vaginitis Containing Aerobic and Anaerobic Pathogens";
const IDSOG_2023_ABSTRACT_VAGINITIS_LINK =
  "https://www.ajog.org/article/S0002-9378(23)00686-5/fulltext";

const ISSWSH_2024_ABSTRACT_PAIN_WITH_SEX_CTA =
  "ISSWSH 2024 Abstract: Women Who Report Pain with Sex Have Varying Microbiome Profiles Based on Age";
const ISSWSH_2024_ABSTRACT_PAIN_WITH_SEX_LINK =
  "https://academic.oup.com/jsm/article/21/Supplement_5/qdae054.123/7694162";

const PSYCHOSOCIAL_RECURRENT_VAGINAL_INFECTIONS_CTA =
  "2023 Review Paper: Psychosocial Impact of Recurrent Vaginal Infections";
const PSYCHOSOCIAL_RECURRENT_VAGINAL_INFECTIONS_LINK =
  "https://journals.sagepub.com/doi/10.1177/17455057231216537";

const TOP_SECTION_TEXT = {
  "United States": [
    "Over 50% of vaginitis cases are misdiagnosed using today's standard of care. Evvy is here to change that.",
    "Evvy’s vaginal microbiome test is the first and only metagenomics-based (mNGS) vaginal health test that can be self-collected at home or in the clinic.",
    "With a single swab, it screens for over 700 bacteria and fungi with industry-leading sensitivity and specificity. This test is also CLIA and CLEP-certified.",
    "Our Expanded PCR Panel add-on adds PCR testing for 4 STIs, 11 common microbes, and 10 antibiotic resistances, all with a 1-3 day turnaround.",
  ],
  Canada: [
    "Over 50% of vaginitis cases are misdiagnosed using today's standard of care. Evvy is here to change that.",
    "Evvy’s vaginal microbiome test is the first and only metagenomics-based (mNGS) vaginal health test that can be self-collected at home or in the clinic.",
    "With a single swab, it screens for over 700 bacteria and fungi with industry-leading sensitivity and specificity. This test is also performed at a CLIA and CLEP-certified lab.",
  ],
};

const CREAM_CARD_TEXT = [
  {
    title: "Comprehensive **testing**",
    text: "Co-infections are common in vaginitis patients. While standard tests can detect 3-10 pathogens, Evvy screens for 700+ microbes relevant to the urogenital tract with a single swab. This enables providers to accurately identify a higher number of co-infection cases compared to traditional diagnostic methods such as wet mount, culture, Amsel's criteria, or relying solely on symptoms.",
  },
  {
    title: "Improved **patient outcomes**",
    text: "In addition to comprehensive results, the Evvy experience for providers includes results interpretation guidance, treatment support, and ongoing education on the latest vaginal health research to improve patient outcomes.",
  },
];

interface MicrobeRelation {
  title?: string;
  description: string | JSX.Element;
  color?: string;
  unitedStatesOnly?: boolean;
  canadaOnly?: boolean;
}

const MICROBE_RELATIONS_LEFT_COLUMN = [
  {
    title: "Aerobic Vaginitis (AV)",
    description: "e.g. E.coli, Group B streptococci",
    color: "orange",
  },
  {
    title: "Bacterial Vaginosis (BV)",
    description:
      "e.g. Gardnerella vaginalis, Atopobium vaginae, Prevotella bivia",
    color: "orange",
  },
  {
    title: "Cytolytic Vaginosis (CV)",
    description: "e.g. Lactobacillus crispatus",
    color: "orange",
  },
  {
    title: "Recurrent UTIs",
    description: "e.g. E. coli, Staphylococcus, Aerococcus",
    color: "orange",
  },
  {
    title: "Yeast Infections",
    description:
      "e.g. Candida albicans, C. glabrata,C. parapsilosis, C. tropicalis, C. krusei",
    color: "orange",
  },
];

const MICROBE_RELATIONS_RIGHT_COLUMN = [
  {
    title: "Good Health",
    description:
      "e.g. Lactobacillus crispatus, Lactobacillus gasseri, Lactobacillus jensenii",
    color: "blue",
  },
  {
    title: "Mycoplasma / Ureaplasma",
    description:
      "e.g. Mycoplasma hominis, Ureaplasma parvum, Ureaplasma urealyticum",
    color: "orange",
  },
  {
    title: "STIs*",
    description: (
      <>
        e.g. Mycoplasma genitalium, Chlamydia, Gonorrhea, Trichomonas vaginalis
        <p className="not-italic mt-4">
          *STIs are only tested for with the Expanded PCR Panel (not in NY yet).
          We don’t test for viruses.
        </p>
      </>
    ),
    color: "orange",
    unitedStatesOnly: true,
  },
];

const PATIENT_EXAMPLE_CARDS = [
  {
    title: "For patients experiencing **symptoms or recurrent infections**",
    image: "/images/graphics/evvy-patient-1.png",
    description:
      "screen for 700+ bacteria & fungi with one swab, including those associated with recurrent BV, yeast infections, UTIs & more. Each test comes with a personalized, evidence-based plan, for improving the microbiome.",
  },
  {
    title: "For patients who want to **test after treatment & beyond**",
    image: "/images/graphics/evvy-patient-2.png",
    description:
      "enable you to monitor the vaginal microbiome remotely to understand how a patient's microbiome responds to treatment, lifestyle, or hormonal changes.",
  },
  {
    title: "For patients interested in **preventative health**",
    image: "/images/graphics/evvy-patient-3.png",
    description:
      "provide insight into a patient's vaginal microbiome and how the microbes found are related to outcomes across fertility, pregnancy, STI acquisition, and more.",
  },
];

const WAYS_TO_ORDER = {
  title: "There are **3 ways** to order the Evvy test for your patients:",
  items: [
    {
      title: "Patient Pays Direct",
      description: {
        "United States":
          "Enter your patient's email and we'll send them a link to purchase an Evvy test ($159 for mNGS Vaginal Health Test, $248 with Expanded PCR Panel add-on)",
        Canada:
          "Enter your patient’s email and we’ll send them a link to purchase an Evvy test ($229 USD for mNGS Vaginal Health Test)",
      },
    },
    {
      title: "Provider Bills Patient",
      description: {
        "United States":
          "Pay for a test for your patient upfront ($129 for mNGS Vaginal Health Test, $218 with Expanded PCR Panel add-on) and bill them separately. We will ship directly to them.",
        Canada:
          "Pay for a test for your patient upfront ($199 USD for mNGS Vaginal Health Test) and bill them separately. We will ship directly to them.",
      },
    },
    {
      title: "Clinic Bulk Order",
      description: {
        "United States":
          "Bulk order tests for your clinic to give out as needed ($129 per mNGS Vaginal Health Test, $218 with Expanded PCR Panel add-on)",
        Canada:
          "Bulk order tests for your clinic to give out as needed (Up to $199 USD per mNGS Vaginal Health Test - volume discounts available)",
      },
    },
  ],
};

const WAYS_TO_ORDER_INFO = {
  "United States":
    "After receiving the Evvy test, the patient takes the sample and ships it to the lab. Evvy processes results from PCR in 1-3 business days, and from mNGS in 7-10 business days. Final results are sent to both patient and provider.",
  Canada:
    "After receiving the Evvy test, the patient takes the sample and ships it to the lab. Evvy processes results from mNGS in 7-10 business days. Final results are sent to both patient and provider.",
};

export const ProviderResources = () => {
  const navigate = useNavigate();
  const [providerProfile] = useProviderProfile();
  const providerCountryIsUS =
    providerProfile?.clinic_country === "United States";
  const topSectionText =
    TOP_SECTION_TEXT[
      providerProfile?.clinic_country as keyof typeof TOP_SECTION_TEXT
    ] || [];

  useEffect(() => {
    localStorage.setItem("hasSeenResources", "true");
    sendProviderViewedResourcesPage();
  }, []);

  const microbeRelationsComponent = (column: MicrobeRelation[]) => {
    return (
      <div
        className={cn("w-1/2 flex flex-col", {
          "gap-5": column === MICROBE_RELATIONS_LEFT_COLUMN,
          "gap-8": column === MICROBE_RELATIONS_RIGHT_COLUMN,
        })}
      >
        {column
          .filter(
            (relation) => !relation.unitedStatesOnly || providerCountryIsUS
          )
          .map((relation) => (
            <div className="flex flex-col gap-2" key={relation?.title}>
              <div className="flex gap-2">
                {relation.color && (
                  <div
                    className={cn("shrink-0  mt-2 h-3 w-3 rounded-full", {
                      "bg-orange-600": relation.color === "orange",
                      "bg-evvy-blue": relation.color === "blue",
                    })}
                  />
                )}
                <div className="flex flex-col">
                  <h4 className="t3 mb-0">{relation.title}</h4>
                  <p className="text-sm italic mb-0 leading-tight">
                    {relation.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-8 max-w-7xl mx-auto p-8">
      <div className="flex justify-between pl-4 items-start">
        <div className="flex flex-col justify-between flex-shrink">
          <h2 className="text-5xl font-semibold">Evvy Vaginal Health Test</h2>
          <div className="w-5/6">
            {topSectionText.map((text) => (
              <p className="text-lg mb-6 last:mb-0" key={text}>
                {text}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center flex-shrink-0">
          <img
            className="object-contain h-[375px]"
            src="/images/graphics/evvy-phone-results.png"
            loading="lazy"
            alt="Evvy Results on Mobile Phone"
          />
          <img
            className="object-contain w-[340px] h-[340px] rounded-xl"
            src="/images/graphics/evvy-kit-blue.png"
            loading="lazy"
            alt="Evvy Kit"
          />
        </div>
      </div>
      <div className="flex gap-16 mt-8">
        <div className="bg-evvy-dark-cream rounded-lg w-1/2 p-8 my-auto flex flex-col justify-between">
          {CREAM_CARD_TEXT.map((item, index) => {
            // Section between ** is highlighted in the title
            const highlightedText = item.title.match(/\*\*(.*?)\*\*/)?.[1];
            const unhighlightedText = item.title.replace(/\*\*(.*?)\*\*/g, "");

            return (
              <div key={item.title}>
                <h3 className="text-2xl font-bold mb-4">
                  {unhighlightedText}
                  {highlightedText && highlightedText.length > 0 && (
                    <span className="bg-highlighter-yellow">
                      {highlightedText}
                    </span>
                  )}
                </h3>
                <p
                  className={cn({
                    "mb-0": index === CREAM_CARD_TEXT.length - 1,
                  })}
                >
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-1/2 flex flex-col">
          <h3 className="text-3xl font-bold mb-4">
            Evvy tests for all microbes that research has shown are related to:
          </h3>
          <div className="flex flex-row">
            {microbeRelationsComponent(MICROBE_RELATIONS_LEFT_COLUMN)}
            {microbeRelationsComponent(MICROBE_RELATIONS_RIGHT_COLUMN)}
          </div>
        </div>
      </div>
      <div className="flex gap-8 mt-8 ">
        {PATIENT_EXAMPLE_CARDS.map((card) => {
          // Section between ** is italic in the title
          const italicText = card.title.match(/\*\*(.*?)\*\*/)?.[1];
          const unitalicText = card.title.replace(/\*\*(.*?)\*\*/g, "");

          return (
            <div
              className="flex flex-col rounded-lg bg-evvy-silverfish p-4 w-1/3"
              key={card.title}
            >
              <div className="flex gap-4">
                <img
                  src={card.image}
                  loading="lazy"
                  alt="Evvy Patient Example"
                  className="rounded-full w-12 h-12"
                />
                <h3 className="text-lg font-bold leading-tight mb-4">
                  {unitalicText}
                  {italicText && italicText.length > 0 && (
                    <span className="italic">{italicText}</span>
                  )}
                </h3>
              </div>
              <hr className="border-gray-300 opacity-50 mb-4" />
              <p className="uppercase text-sm font-semibold mb-1">
                An Evvy test can...
              </p>
              <p className="text-lg leading-tight mb-0">{card.description}</p>
            </div>
          );
        })}
      </div>
      <div className="py-4">
        <h3 className="text-2xl">
          {WAYS_TO_ORDER.title.split("**").map((text, index) => {
            if (index % 2 === 0) {
              return <span key={index}>{text}</span>;
            } else {
              return (
                <span className="bg-highlighter-yellow" key={index}>
                  {text}
                </span>
              );
            }
          })}
        </h3>
        <div className="flex gap-4">
          {WAYS_TO_ORDER.items.map((item, index) => {
            const type =
              index === 0 ? "provider" : index === 1 ? "patient" : "bulk";
            return (
              <div
                className="flex flex-col gap-2 first:pl-0 p-4 w-1/4 last:border-r last:border-black"
                key={item.title}
              >
                <Link
                  className="text-md mb-0 uppercase font-semibold hover:underline"
                  to={`/provider/order?type=${type}`}
                >
                  0{index + 1} — {item.title}
                </Link>
                <p className="text-sm mb-0">
                  {
                    item.description[
                      providerProfile?.clinic_country as keyof typeof item.description
                    ]
                  }
                </p>
                <div className="flex flex-grow" />
                <BlueRectangularButton
                  text="Order Now"
                  fullWidth
                  onClick={() => {
                    sendProviderClickedOrderFromResources({
                      type,
                    });
                    navigate(
                      index === 0
                        ? "/provider/order?type=provider"
                        : index === 1
                        ? "/provider/order?type=patient"
                        : "/provider/order?type=bulk"
                    );
                  }}
                />
              </div>
            );
          })}
          <p className="w-1/4 pl-4 italic">
            {
              WAYS_TO_ORDER_INFO[
                providerProfile?.clinic_country as keyof typeof WAYS_TO_ORDER_INFO
              ]
            }
          </p>
        </div>
      </div>
      <ResourceCard />
    </div>
  );
};

const ResourceCard = () => {
  const [providerProfile] = useProviderProfile();
  const providerCountryIsUS =
    providerProfile?.clinic_country === "United States";

  const RESOURCE_LINKS = [
    {
      title: PROVIDER_INTERPRETATION_CTA,
      link: PROVIDER_INTERPRETATION_LINK,
    },
    {
      title: METHODS_CTA,
      link: providerCountryIsUS
        ? METHODS_LINK_UNITED_STATES
        : METHODS_LINK_CANADA,
    },
    {
      title: PATIENT_TALKING_POINTS_CTA,
      link: providerCountryIsUS
        ? PATIENT_TALKING_POINTS_LINK_UNITED_STATES
        : PATIENT_TALKING_POINTS_LINK_CANADA,
    },
    {
      title: ASK_EVVY_BLOG_CTA,
      link: ASK_EVVY_BLOG_LINK,
    },
    {
      title: EVVY_TEST_VALIDATION_PAPER_CTA,
      link: EVVY_TEST_VALIDATION_PAPER_LINK,
    },
    {
      title: WHITE_PAPER_CTA,
      link: WHITE_PAPER_LINK,
    },
    {
      title: IDSOG_2024_ABSTRACT_CTA,
      link: IDSOG_2024_ABSTRACT_LINK,
    },
    {
      title: IDSOG_2023_ABSTRACT_GARDNERELLA_CTA,
      link: IDSOG_2023_ABSTRACT_GARDNERELLA_LINK,
    },
    {
      title: IDSOG_2023_ABSTRACT_VAGINITIS_CTA,
      link: IDSOG_2023_ABSTRACT_VAGINITIS_LINK,
    },
    {
      title: ISSWSH_2024_ABSTRACT_PAIN_WITH_SEX_CTA,
      link: ISSWSH_2024_ABSTRACT_PAIN_WITH_SEX_LINK,
    },
    {
      title: PSYCHOSOCIAL_RECURRENT_VAGINAL_INFECTIONS_CTA,
      link: PSYCHOSOCIAL_RECURRENT_VAGINAL_INFECTIONS_LINK,
    },
  ];
  return (
    <div className="flex w-full flex-wrap sm:flex-nowrap gap-8">
      <div className="flex flex-col space-y-4 my-auto">
        {RESOURCE_LINKS.map((resource) => (
          <LinkedText
            key={resource.title}
            href={resource.link}
            isLink
            arrow
            arrowRight
            onClick={() => {
              sendProviderClickedResourceLink({
                articleUrl: resource.link,
                ctaText: resource.title,
              });
            }}
          >
            {resource.title}
          </LinkedText>
        ))}
      </div>
    </div>
  );
};
