import { Marked } from "@ts-stack/markdown";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";

/*
Custom markdown component that uses markdown-to-jsx to render markdown with custom styles.
- Overrides the default styles for links to add underline and open links in new tab.

*/
export const EvvyMarkdown = ({
  children,
  newlineOnPipe = false,
  className,
}: {
  children: string;
  newlineOnPipe?: boolean;
  className?: string;
}) => {
  // map "|" character to markdown newline
  const convertPipeToNewline = (text: string) => {
    return text.replace(/\|/g, "\n");
  };

  let childText = children;
  if (newlineOnPipe) {
    childText = convertPipeToNewline(children);
  }

  return (
    <Markdown
      options={{
        overrides: {
          a: {
            props: {
              className: classNames(
                "!underline !underline-offset-2 persistSize",
                className
              ),
              target: "_blank",
            },
          },
        },
      }}
    >
      {childText}
    </Markdown>
  );
};

export const EvvyMarkdownPdf = (text: string): string => {
  const processedText = text
    .replace(/\|/g, "")
    // replace URL in <> with "link" hyperlink
    .replace(/<(https?:\/\/[^>]+)>/g, "[link]($1)");
  return Marked.parse(processedText);
};
