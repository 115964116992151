export const FERTILITY_DISRUPTOR_COPY = {
  header: {
    title: "Fertility Disruptors",
    subtitle:
      "The pathogens that research has associated with fertility outcomes.",
  },
};

export const STI_STATUS_COPY = {
  header: {
    title: "STI Status",
    subtitle:
      "The presence of STIs has been associated with fertility outcomes.",
  },
};

export const DISCLAIMER_COPY =
  "* These insights are based on published research. Fertility is multifaceted, and these insights do not determine your personal fertility outcomes. Your vaginal microbiome results are only one piece of health information to discuss with your provider.";

export const DETECTED_COPY = "Detected";
export const POSITIVE_STATUS = "POSITIVE";
export const NEGATIVE_STATUS = "NEGATIVE";

export const FERTILITY_DISRUPTOR_TOOLTIP_COPY =
  "This bacteria was detected through your Expanded Panel which uses PCR testing. PCR is really good at confirming the presence of a particular microbe, as opposed to the amount.";
 
export const RESEARCH_STATUS_COPY =
  "Scientific research is always evolving. In this context, “Research Status” represents how established the research is on a microbiome marker's relationship to fertility outcomes.";

export const SCORE_VALUE_LOW = "low";
export const SCORE_VALUE_MODERATE = "moderate";
export const SCORE_VALUE_HIGH = "high";
export const SCORE_VALUE_DETECTED = "detected";