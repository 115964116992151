import { AxiosResponse, AxiosError } from "axios";
import { getRequest, postRequest, putRequest } from "../utils/axios";

export const fetchOpenOrders = async () => {
  const response = await getRequest(
    "/api/v1/orders/?open=true",
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const fetchLatestOrder = async () => {
  const response = await getRequest(
    "/api/v1/orders/?limit=1",
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const attachOrderToUser = async (
  checkoutId: string | undefined,
  orderNumber: string | undefined
) => {
  const response = await putRequest(
    `/api/v1/orders/attach`,
    { order_number: orderNumber, checkout_id: checkoutId },
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const processOrder = async (
  checkoutId: string | undefined,
  orderEmail: string | undefined
) => {
  const response = await postRequest(
    `/api/v1/orders/process`,
    { checkout_id: checkoutId, order_email: orderEmail },
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

// Orders that have had transfers initiated but not yet confirmed
export const unverifiedOrderTransfers = async () => {
  const response = await getRequest(
    "/api/v1/order-transfer-verification/",
    (response: AxiosResponse) => {
      return response.data;
    },
    (error: AxiosError) => {
      throw error;
    }
  );
  return response;
};

export const resendOrderTransferEmail = async (
  orderNumbers: string[],
  onSuccess: any,
  onFailure: any
) => {
  const response = await postRequest(
    "/api/v1/order-transfer-verification/",
    { orders: orderNumbers },
    (response: AxiosResponse) => {
      onSuccess();
      return response.data;
    },
    (error: AxiosError) => {
      onFailure();
      throw error;
    }
  );
  return response;
};

// Confirm and attach order to user once the user clicks on the link in the email
export const verifyAccountOrderTransferConfirm = async (
  hash: string | undefined,
  onSuccess: any,
  onFailure: any
) => {
  const response = await postRequest(
    "/api/v1/order-transfer-verification-confirm/",
    { hash: hash },
    (response: AxiosResponse) => {
      onSuccess(response);
      return response.data;
    },
    (error: AxiosError) => {
      onFailure();
      throw error;
    }
  );
  return response;
};
