import { ALL_CANCERS, getGenericQuestionName } from "../utils/healthHistory";
import { Consult, ProductCitation, TreatmentPathway } from "./care";

export type ChecklistStatus = {
  step_number: number;
  step: string;
  action: string;
  cta_link?: string;
  completed_steps: number[];
};

export type AddressInfo = {
  address_first_line?: string;
  address_second_line?: string;
  city?: string;
  state_code?: string;
  zip_code?: string;
  phone_number?: string;
  provider_release?: boolean;
};

export type SectionIntro = {
  text: string;
  header?: string;
  citations?: ProductCitation[];
};

export type HealthContext = {
  is_complete: boolean;
  health_history_submitted_at?: string;
  sample_taken_at?: string;
  is_v2_user_experience?: boolean;
};

export type HealthContextFields = {
  healthcontextextradata_set: any;
  // all current select & multiselect options
  _options: HealthContextOptions;
  _related_diagnosis_options: HealthContextRelatedDiagnosesOptions;

  // for tracking on last screen
  health_history_submitted_at?: Date | null;
  is_complete?: boolean;

  past_health_history?: HealthContextFields;

  // HEALTH CONTEXT - SYMPTOMS
  symptoms_has_current?: string;
  symptoms_best_description?: string;
  symptoms_reason_for_test?: string[];
  symptoms_obvious_trigger?: string;
  symptoms_trigger_other?: string;
  symptoms_additional?: string;
  diagnoses_30?: string;

  diagnoses_30day_bv_method?: string;
  diagnoses_30day_bv_method_other?: string;
  diagnoses_30day_yeast_infection_method?: string;
  diagnoses_30day_yeast_infection_method_other?: string;

  // HEALTH CONTEXT - BASICS
  basics_reason_for_test?: string[];
  basics_reason_for_test_other?: string;
  basics_recent_period?: string;
  basics_last_period_date?: Date | null;
  basics_hoping_to_learn?: string;
  basics_follow_recommendations?: string;
  basics_follow_recommendations_details?: string;
  basics_changes_since_last?: string[];
  basics_changes_since_last_details?: string;
  basics_retest_symptoms_changed?: string;
  basics_main_reason_for_test?: string;
  basics_retest_external_prescriptions?: string[];

  // HEALTH CONTEXT - BASICS - POST CARE
  post_care_symptom_change?: string;
  post_care_improved_symptoms?: string[];
  post_care_improved_symptoms_other?: string;
  post_care_worsened_symptoms?: string[];
  post_care_worsened_symptoms_other?: string;
  post_care_side_effects?: string[];
  post_care_had_side_effects?: string;
  post_care_side_effects_other?: string;
  post_care_followed_protocol?: string | null;
  post_care_treatments_taken?: string[];
  post_care_treatments_full_course_taken?: string;
  post_care_treatments_not_completed?: string[];
  post_care_external_treatments_taken?: string[];

  // HEALTH CONTEXT - DISCHARGE
  vaginal_discharge?: string;
  symptoms_vaginal_smell?: string[];
  symptoms_vaginal_smell_other: string;
  symptoms_vaginal_discharge: string[];
  symptoms_vaginal_discharge_other: string;
  symptoms_vaginal_discharge_color: string;
  symptoms_vaginal_discharge_color_other: string;
  symptoms_vaginal_discharge_consistency: string;
  symptoms_vaginal_discharge_consistency_other: string;

  // HEALTH CONTEXT - SYMPTOMS - VAGINAL SYMPTOMS
  symptoms_additional_vagina_pain: string;
  symptoms_additional_vulva_pain: string;
  symptoms_additional_vulva_redness: string;
  symptoms_additional_vaginal_swelling: string;
  symptoms_additional_external_itchiness: string;
  symptoms_additional_internal_itchiness: string;
  symptoms_additional_dryness: string;
  symptoms_additional_burning_sensation: string;
  symptoms_additional_pain_with_sex: string;
  symptoms_additional_pain_while_peeing: string;
  symptoms_additional_excessive_discharge: string;
  symptoms_additional_odorous_discharge: string;

  // HEALTH CONTEXT - DIAGNOSES
  diagnoses_30day_bv?: string;
  diagnoses_30day_yeast_infection?: string;
  diagnoses_30day_uti?: string;
  diagnoses_30day_av?: string;
  diagnoses_30day_ureaplasma?: string;
  diagnoses_30day_mycoplasma?: string;
  diagnoses_30day_cytolytic_vaginosis?: string;
  diagnoses_30day_vaginal_lactobacillosis?: string;
  diagnoses_30day_chlamydia?: string;
  diagnoses_30day_trichomoniasis?: string;
  diagnoses_30day_gonorrhea?: string;

  // HEALTH CONTEXT - TREATMENTS
  treatments?: string[];
  treatments_other?: string;
  other_vaginal_health_products?: string[];
  other_vaginal_health_products_other?: string;
  treatment_response?: string;
  prescription_treatment?: string;
  prescription_treatment_none?: string;
  prescription_treatment_antibiotics_metro?: string;
  prescription_treatment_antibiotics_clinda?: string;
  prescription_treatment_antibiotics_not_listed?: string;
  prescription_treatment_antifungal?: string;
  prescription_treatment_hormone_therapy?: string;
  prescription_treatment_antibiotics_other?: string;

  treatment_response_antibiotics_metro?: string;
  treatment_response_antibiotics_clinda?: string;
  treatment_response_antibiotics_other?: string;
  treatment_response_antifungal?: string;
  treatment_response_hormone_therapy?: string;
  treatment_response_douching?: string;
  treatment_response_feminine_hygiene?: string;
  treatment_response_probiotics?: string;
  treatment_response_boric_acid?: string;

  // HEALTH CONTEXT - SEXUAL ACTIVITY
  sexual_activity_condoms?: string;
  sexual_activity_timing?: string;
  contraception_types_used?: string;
  contraception_type_other?: string;
  contraception_brand?: string;
  sexual_activity_partners?: string;
  recent_sexual_activity?: string;
  sexual_activity?: string[];

  // HEALTH CONTEXT - ANYTHING ELSE
  health_context_anything_else?: string;

  // SAMPLE TAKING
  sample_ph?: string | null;
  sample_taken_at?: Date | null;

  all_additional_symptoms?: string[];
  is_v2_user_experience?: boolean;
  questionnaire_version?: string;
  // HEALTH CONTEXT - DEMOGRAPHICS
  demographics_height?: string;
  demographics_weight?: string;
  demographics_race?: string[];
  demographics_race_other?: string;
  demographics_hispanic_or_latino?: string;
  demographics_born_with_vagina?: string;
  demographics_gender_affirming_care?: string;
  demographics_born_with_vagina_other?: string;
  demographics_gender_identity?: string;
  demographics_gender_identity_other?: string;

  lifestyle_diet?: string[];
  lifestyle_diet_other?: string;
  lifestyle_smoking?: string;
  lifestyle_exercise?: string;

  // HEALTH CONTEXT DIAGNOSES CHOICES
  diagnoses?: string;
  diagnoses_bv?: string[];
  diagnoses_yeast_infection?: string[];
  diagnoses_uti?: string[];
  diagnoses_av?: string[];
  diagnoses_ureaplasma?: string[];
  diagnoses_mycoplasma?: string[];
  diagnoses_cytolytic_vaginosis?: string[];
  diagnoses_chlamydia?: string[];
  diagnoses_trichomoniasis?: string[];
  diagnoses_gonorrhea?: string[];
  related_diagnoses_immunocompromised?: string;

  // HEALTH CONTEXT RELATED DIAGNOSES
  infertility_treatments?: string;

  // PREGNANCY
  pregnancy_status?: string[] | string;
  pregnancy_breastfeeding?: string;
  pregnancy_postpartum_timing?: string;
  pregnancy_month?: string;
  pregnancy_previously?: string;
  pregnancy_experienced?: string[];
  pregnancy_experienced_other?: string;
  pregnancy_previously_experienced?: string[];
  pregnancy_previously_experienced_other_selected?: string;
  pregnancy_previously_experienced_other?: string;

  pregnancy_had_amh_test?: string;
  pregnancy_trying_to_conceive_planned_treatments?: string[];
  pregnancy_amh_test_date?: string;
  pregnancy_amh_test_result?: string;
  pregnancy_implantation_failure?: string;
  pregnancy_implantation_failure_count?: string;
  pregnancy_implantation_failure_date?: string;
  pregnancy_ivf_cycle_date?: string;
  pregnancy_ivf_rounds?: string;
  pregnancy_previously_experienced_ectopic_pregnancy?: string;
  pregnancy_previously_experienced_ectopic_pregnancy_date?: string;
  pregnancy_previously_experienced_fullterm_birth?: string;
  pregnancy_previously_experienced_fullterm_birth_date?: string;
  pregnancy_previously_experienced_miscarriage?: string;
  pregnancy_previously_experienced_miscarriage_date?: string;
  pregnancy_previously_experienced_preeclampsia?: string;
  pregnancy_previously_experienced_preeclampsia_date?: string;
  pregnancy_previously_experienced_preterm_birth?: string;
  pregnancy_previously_experienced_preterm_birth_date?: string;
  pregnancy_previously_experienced_stillbirth?: string;
  pregnancy_previously_experienced_stillbirth_date?: string;
  pregnancy_trying_to_conceive_time?: string;
  pregnancy_time_to_conceive?: string;

  // HORMONAL
  hormonal_menopause?: string;
  hormonal_menopause_date?: string;
  hormonal_menopause_symptoms?: string[];
  hormonal_menopause_hormones?: string[];
  hormonal_menopause_hormones_other?: string;
  hormonal_menopause_hysterectomy?: string;
  hormonal_menstrual_cycle?: string;
  hormonal_period_counts?: string;
  hormonal_period_products?: string[];
  hormonal_period_products_other?: string;
  hormonal_symptoms?: string[];

  // QUALITY OF LIFE
  related_diagnoses?: string[];
  related_diagnoses_quality_of_life?: number;
  quality_of_life_most_affected?: string[];
  basics_why_evvy?: string[];
  basics_why_evvy_primary?: string;
  basics_why_evvy_other: string;
  healthcontextrelateddiagnoses_set: Array<HealthContextRelatedDiagnosesFields>;
};

export type HealthContextRelatedDiagnosesFields = {
  autoimmune_conditions: string;
  cancer_in_remission: string;
  cancer_last_surgery_date: Date | null;
  cancer_last_treatment_date: Date | null;
  cancer_treatment: string[];
  cancer_treatment_other: string;
  cin_type: string;
  condition: string;
  diabetes_hba1c: string;
  diabetes_last_hba1c_date: Date | null;
  diabetes_type: string;
  diagnosis_year: number | null;
  diagnosis_date: Date | null;
  infertility_treatments: string[];
  fertility_treatment_alternative_therapies: string;
  fertility_treatment_therapy_not_listed: string;
  fertility_treatment_hormone_therapy: string;
  fertility_treatment_in_vitro_fertilization: string;
  fertility_treatment_intrauterine_insemination: string;
  fertility_treatment_other: string;
  fertility_treatment_ovulation_induction: string;
  fertility_treatment_surgical_interventions: string;
  herpes_outbreak_location: string[];
  infertility_cause: string;
  infertility_cause_identified: string;
  last_screening_date: Date | null;
  cancer_last_radiation_treatment_date: Date | null;
  cancer_last_chemotherapy_treatment_date: Date | null;
  endometriosis_diagnosis_method: string;
  endometriosis_diagnosis_method_other: string;
  endometriosis_treatment: string[];
  endometriosis_treatment_other: string;
  endometriosis_lesion_surgery_date: Date | null;
};

export function isHealthContextRelatedDiagnosesField(
  key: string
): key is keyof HealthContextRelatedDiagnosesFields {
  const genericQuestionName = getGenericQuestionName(key);
  const keys: Array<keyof HealthContextRelatedDiagnosesFields> = [
    "autoimmune_conditions",
    "cancer_in_remission",
    "cancer_last_surgery_date",
    "cancer_last_treatment_date",
    "cancer_treatment",
    "cancer_treatment_other",
    "cin_type",
    "condition",
    "diabetes_hba1c",
    "diabetes_last_hba1c_date",
    "diabetes_type",
    "diagnosis_year",
    "diagnosis_date",
    "infertility_treatments",
    "fertility_treatment_alternative_therapies",
    "fertility_treatment_therapy_not_listed",
    "fertility_treatment_hormone_therapy",
    "fertility_treatment_in_vitro_fertilization",
    "fertility_treatment_intrauterine_insemination",
    "fertility_treatment_other",
    "fertility_treatment_ovulation_induction",
    "fertility_treatment_surgical_interventions",
    "herpes_outbreak_location",
    "infertility_cause",
    "infertility_cause_identified",
    "last_screening_date",
  ];
  return (
    keys.includes(key as keyof HealthContextRelatedDiagnosesFields) ||
    keys.includes(
      genericQuestionName as keyof HealthContextRelatedDiagnosesFields
    )
  );
}

export type HealthContextRelatedDiagnosesOptions = {
  cancer_in_remission: TupleArray<string>;
  cancer_treatment: TupleArray<string>;
  cin_type: TupleArray<string>;
  related_diagnoses: TupleArray<string>;
  diabetes_type: TupleArray<string>;
  fertility_treatment_alternative_therapies: TupleArray<string>;
  fertility_treatment_therapy_not_listed: TupleArray<string>;
  fertility_treatment_hormone_therapy: TupleArray<string>;
  fertility_treatment_in_vitro_fertilization: TupleArray<string>;
  fertility_treatment_intrauterine_insemination: TupleArray<string>;
  fertility_treatment_ovulation_induction: TupleArray<string>;
  fertility_treatment_surgical_interventions: TupleArray<string>;
  herpes_outbreak_location: TupleArray<string>;
  infertility_cause_identified: TupleArray<string>;
  infertility_treatments: TupleArray<string>;
};

export type TupleArray<T> = [T, T][];

export type HealthContextOptions = {
  symptoms_has_current?: TupleArray<string>;
  symptoms_best_description?: TupleArray<string>;
  symptoms_reason_for_test?: TupleArray<string>;
  basics_why_evvy?: TupleArray<string>;
  basics_reason_for_test?: TupleArray<string>;
  basics_recent_period?: TupleArray<string>;
  basics_follow_recommendations?: TupleArray<string>;
  basics_changes_since_last?: TupleArray<string>;
  post_care_symptom_change?: TupleArray<string>;
  post_care_improved_symptoms?: TupleArray<string>;
  post_care_worsened_symptoms?: TupleArray<string>;
  post_care_side_effects?: TupleArray<string>;
  post_care_treatments_taken?: TupleArray<string>;
  symptoms_vaginal_smell?: TupleArray<string>;
  symptoms_vaginal_discharge?: TupleArray<string>;
  symptoms_vaginal_discharge_color?: TupleArray<string>;
  symptoms_vaginal_discharge_consistency?: TupleArray<string>;
  symptoms_additional?: TupleArray<string>;
  diagnoses?: TupleArray<string>;
  diagnoses_30?: TupleArray<string>;
  treatments?: TupleArray<string>;
  prescription_treatment?: TupleArray<string>;
  other_vaginal_health_products?: TupleArray<string>;
  demographics_race?: TupleArray<string>;
  demographics_born_with_vagina?: TupleArray<string>;
  demographics_gender_affirming_care?: TupleArray<string>;
  demographics_gender_identity?: TupleArray<string>;
  quality_of_life_most_affected?: TupleArray<string>;
  related_diagnoses?: TupleArray<string>;
  related_diagnoses_trigger?: TupleArray<string>;
  related_diagnoses_immunocompromised?: TupleArray<string>;
  hormonal_menstrual_cycle?: TupleArray<string>;
  hormonal_period_days?: TupleArray<string>;
  hormonal_period_counts?: TupleArray<string>;
  hormonal_period_products?: TupleArray<string>;
  hormonal_symptoms?: TupleArray<string>;
  contraception_types_used?: TupleArray<string>;
  contraception_type?: TupleArray<string>;
  contraception_changed?: TupleArray<string>;
  sexual_activity_timing?: TupleArray<string>;
  sexual_activity?: TupleArray<string>;
  sexual_activity_condoms?: TupleArray<string>;
  pregnancy_status?: TupleArray<string>;
  pregnancy_breastfeeding?: TupleArray<string>;
  pregnancy_postpartum_timing?: TupleArray<string>;
  pregnancy_month?: TupleArray<string>;
  pregnancy_previously?: TupleArray<string>;
  pregnancy_experienced?: TupleArray<string>;
  pregnancy_experienced_other?: TupleArray<string>;
  treatment_response?: TupleArray<string>;
  pregnancy_previously_experienced?: TupleArray<string>;
};

export type Test = {
  hash: string;
  pretty_hash: string;
  status: string;
  get_status_display: string;
  create_date: string;
  modify_date: string;
  activated_at?: string;
  results_ready_at?: string;
  eligible_for_care: boolean;
  care_eligibility_expires_at: string;
  has_active_infection?: boolean;
  eligible_for_a_la_care: boolean;
  recommended_program_slug: string;
  previous_test_care: {
    previous_test_completed_care: boolean;
    previous_test_had_care: boolean;
    previous_treatment_pathway?: TreatmentPathway;
  };
  is_ldt: boolean;
  lab: "mdx" | "diversigen";
  results_viewed_at?: string;
  healthcontext?: HealthContext;
  test_order: number;
  is_provider_ordered: boolean;
  valencia_type: string;
  test_type: string;
  has_vpcr_test: boolean;
  has_other_active_test: boolean;
  expan_upsell_enabled: boolean;
  estimated_results_ready_dates: {
    vpcr_results?: string;
    vngs_results?: string;
  };
  provider: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    clinic_state: string;
    provider_type: string;
    specialty: string;
    affiliated_practice: string;
  };
  latest_vaginitis_consult?: Consult;
  latest_sti_consult?: Consult;
  can_open_sti_treatment_consult: boolean;
  had_coaching_call?: boolean;
  is_recommended_antibiotic_or_antifungal: boolean;
  recommended_program_bundle_price: number;
  is_trying_to_conceive?: boolean;
  is_trying_to_conceive_soon?: boolean;
};

type MicrobeScore = {
  value: number;
};

export type TestScores = {
  protective: MicrobeScore;
  disruptive: MicrobeScore;
  variable: MicrobeScore;
  unknown: MicrobeScore;
};

export type CareEligibility = {
  v0_bundle: boolean;
  a_la_care: boolean;
  can_open_sti_treatment_consult: boolean;
};

export type MicrobiomeState =
  | "disrupted"
  | "healthy"
  | "suboptimal"
  | "sti-positive";

export type InfectionStateCitation = {
  title: string;
  url: string;
};

export type InfectionState = {
  id: number;
};

export type ProblemsSummary = {
  microbiome_state: MicrobiomeState;
  conditions: string[];
  stis: string[];
  symptoms: string[];
  context: string[];
  uses_clinical_intake: boolean;
  more_info: string[];
  citations: InfectionStateCitation[];
  has_symptoms: boolean;
  infection_state: InfectionState;
  provider_info: string[];
  overall_cst_type: number;
  ngs_microbiome_state: MicrobiomeState;
};

export type HealthContextTrendSummary = {
  sample_taken_at: string;
  symptoms_additional_vagina_pain: string;
  symptoms_additional_vulva_pain: string;
  symptoms_additional_vulva_redness: string;
  symptoms_additional_vaginal_swelling: string;
  symptoms_additional_external_itchiness: string;
  symptoms_additional_internal_itchiness: string;
  symptoms_additional_dryness: string;
  symptoms_additional_burning_sensation: string;
  symptoms_additional_pain_with_sex: string;
  symptoms_additional_pain_while_peeing: string;
  symptoms_additional_excessive_discharge: string;
  symptoms_additional_odorous_discharge: string;
  symptoms_additional_vaginal_burning_internal: string;
  symptoms_additional_vulvar_burning: string;
  symptoms_additional_frequent_urination: string;
};

export type TestTrendSummary = {
  hash: string;
  pretty_hash: string;
  status: string;
  get_status_display: string;
  create_date: string;
  modify_date: string;
  test_order: number;
  scores: TestScores;
  problems_summary: ProblemsSummary;
  healthcontext: HealthContextTrendSummary;
  care_eligibility: CareEligibility;
  latest_sti_consult?: Consult;
};

export type HdyhauSurvey = {
  uid: string;
  hdyhau: string;
  hdyhau_other: string;
  when_heard_about_evvy: string;
  which_advertisement: string;
  which_advertisement_other: string;
  which_social_media: string;
  which_social_media_other: string;
  which_community: string;
  which_influencer: string;
  which_news: string;
  _options: HdyhauOptions;
};

export type HdyhauOptions = {
  hdyhau?: TupleArray<string>;
  when_heard_about_evvy?: TupleArray<string>;
  which_advertisement?: TupleArray<string>;
  which_social_media?: TupleArray<string>;
  which_community?: TupleArray<string>;
  which_influencer?: TupleArray<string>;
  which_news?: TupleArray<string>;
};

export const DatabaseVersionSageV1 = "SA";
export const DatabaseVersionSageV2 = "S2";
export const DatabaseVersionSageV2_2 = "S2.2";
