import { FertilityModule, ScoreDetails } from "../types";
import { NEGATIVE_STATUS, POSITIVE_STATUS } from "./constants";

export const formatScore = (number: number, scoreType: string): string => {
  if (scoreType === "microbe") {
    return `${number.toFixed(1)}%`;
  } else if (scoreType === "protective") {
    return `${number.toFixed(0)}%`;
  } else if (scoreType === "number") {
    return `${number.toFixed(1)}`;
  } 
  return "N/A";
};

export const getSTIStatus = (
  stiScores: FertilityModule["scores"]["sti_scores"]
): string => {
  return stiScores.is_sti_positive ? POSITIVE_STATUS : NEGATIVE_STATUS;
};

export const getScoreType = (score: ScoreDetails) => {
  if (score.score_definition.slug === "protective") {
    return "protective";
  } else if (score.score_definition.score_type === "microbe") {
    return "microbe";
  } else if (score.score_definition.slug === "shannon-diversity") {
    return "number";
  } else if (score.score_definition.slug === "sti") {
    return "status";
  }
  return "";
};

export const transformModuleData = (module: FertilityModule) => {
  const { scores, fertility_summary } = module;

  const fertilityDisruptorMicrobes = Object.fromEntries(
    Object.entries(scores.microbe_scores).filter(([key]) => key !== "summary")
  ) as Record<string, ScoreDetails>;

  return {
    header: {
      title: "Fertility Insights ",
      title_suffix: "from your microbiome",
      subtitle: fertility_summary,
    },
    scores: {
      protective: scores.numeric_scores["protective"],
      ["shannon-diversity"]: scores.numeric_scores["shannon-diversity"],
      fertilityDisruptors: {
        scores: fertilityDisruptorMicrobes,
        summary: scores.microbe_scores.summary,
      },
      sti: scores.sti_scores,
    },
  };
};
