import React from "react";
import { ResultsBaseAnalyticsArgs, ScoreDetails } from "../types";
import classNames from "classnames";
import {
  DETECTED_COPY,
  RESEARCH_STATUS_COPY,
} from "../fertilityModule/constants";
import LinkedText from "../../../../components/common/linkedText";
import { ScoreInsightRow } from "./ScoreInsightRow";
import { getIsMobile } from "../../../../utils/general";
import Tippy from "@tippyjs/react";
import { ReactComponent as InfoIcon } from "../../../../components/results_v2/icons/info.svg";
import { secondaryColors } from "../../../../utils/viz";
import { ResearchRating } from "../../../../components/common/ResearchRating";
import { MicrobeLearnMoreExpanded } from "./MicrobeLearnMoreExpanded";

interface ScoreCardRowProps {
  score: ScoreDetails;
  isExpanded?: boolean;
  setSelectedMicrobe?: (microbe: ScoreDetails | null) => void;
  microbeLearnMoreExpanded?: boolean;
  setModalData?: (data: { type: string; data: any }) => void;
  analyticsArgs: ResultsBaseAnalyticsArgs;
  isLastChild?: boolean;
}

export const ScoreCardRow: React.FC<ScoreCardRowProps> = ({
  score,
  isExpanded,
  setSelectedMicrobe,
  microbeLearnMoreExpanded,
  setModalData,
  analyticsArgs,
  isLastChild = false,
}) => {
  const isMobile = getIsMobile();
  return (
    <div
      className={classNames({
        "mb-6": isExpanded && !isLastChild,
        "mb-2": isLastChild,
      })}
      key={score.score_definition.display_name}
    >
      <div className="flex justify-between items-center">
        <p
          className={classNames("medium mb-0 text-[16px]", {
            "semibold b2":
              isMobile &&
              score.score_definition.score_type === "sti" &&
              isExpanded,
            "medium b2": !isExpanded && isMobile,
          })}
        >
          {score.score_definition.display_name}
        </p>
        <p
          className={classNames("t4 tracking-wider whitespace-nowrap mb-0", {
            "text-teal-900":
              !score.pcr_detected_or_sti_positive && !score.above_threshold,
            "text-evvy-red":
              score.pcr_detected_or_sti_positive || score.above_threshold,
          })}
        >
          {score.score_definition.score_type === "sti"
            ? score.qualitative_value
            : DETECTED_COPY}
        </p>
      </div>
      {isExpanded && (
        <ResearchRating
          researchRating={score.research_rating}
          tooltipCopy={RESEARCH_STATUS_COPY}
        />
      )}
      <div>
        {isExpanded && isMobile && (
          <MicrobeLearnMoreExpanded
            microbeLearnMoreExpanded={microbeLearnMoreExpanded}
            setSelectedMicrobe={setSelectedMicrobe}
            score={score}
            analyticsArgs={analyticsArgs}
            setModalData={setModalData}
          />
        )}
      </div>
    </div>
  );
};
 