import React, { useState, forwardRef } from "react";
import { ResultsBaseAnalyticsArgs, ScoreDetails, STIScores } from "../types";
import classNames from "classnames";
import { DottedDivider } from "../components/Icons";
import { ScoreCardRow } from "../components/ScoreCardRow";
import { ScoreCardWrapper } from "../components/ScoreCardWrapper";
import { getSTIStatus } from "./utils";
import { getIsMobile } from "../../../../utils/general";
import { ScoreCardHeader } from "../components/ScoreCardHeader";

interface STICardProps {
  stiScores: STIScores;
  onSelect?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  isExpanded: boolean;
  setModalData: (data: { type: string; data: any }) => void;
  analyticsArgs: ResultsBaseAnalyticsArgs;
}

export const STICard = forwardRef<HTMLDivElement, STICardProps>(
  (
    { stiScores, className, isExpanded, onSelect, setModalData, analyticsArgs },
    ref
  ) => {
    const isMobile = getIsMobile();
    const { scores, is_sti_positive, sti_positive_microbes } = stiScores;
    const [selectedMicrobe, setSelectedMicrobe] = useState<ScoreDetails | null>(
      Object.keys(stiScores.scores).length > 0
        ? stiScores.scores[Object.keys(stiScores.scores)[0]]
        : null
    );

    return (
      <ScoreCardWrapper
        ref={ref}
        onSelect={isMobile ? undefined : onSelect}
        className={className}
        isExpanded={isExpanded}
        isSTIScore={true}
      >
        <ScoreCardHeader
          scoreDisplayName="STI status"
          scoreSubtitle="The STI's that research has associated with fertility outcomes."
          isMobile={isMobile}
          isExpanded={isExpanded}
          status={getSTIStatus(stiScores)}
          onSelect={onSelect}
        />
        <div
          className={classNames({
            "bg-white p-4 rounded-xl": isMobile && isExpanded,
            "bg-transparent p-0": !isMobile,
          })}
          data-score-type="sti"
        >
          {isMobile && isExpanded && <p className="t4 mb-2">YOUR RESULTS:</p>}
          {isMobile && (
            <div className="flex text-center mx-auto items-center w-full bg-evvy-cream rounded-full p-2">
              <p
                className={classNames(
                  "w-full mb-0 t4 pb-0 font-semibold text-sm leading-5",
                  {
                    "text-evvy-red": is_sti_positive,
                    "text-teal-900": !is_sti_positive,
                  }
                )}
              >
                {getSTIStatus(stiScores)}
              </p>
            </div>
          )}
        </div>
        {!isExpanded && (
          <div>
            {/* dotted line */}
            {sti_positive_microbes.map(
              (microbe: ScoreDetails, index: number) => (
                <div key={microbe.score_definition.display_name}>
                  <div className="mb-4 mt-4 w-full border-b border-dashed border-black border-opacity-50 min-h-px max-md:max-w-full" />
                  <ScoreCardRow score={microbe} analyticsArgs={analyticsArgs} />
                </div>
              )
            )}
          </div>
        )}
        {/* Expanded STI scores */}
        {isExpanded && (
          <div>
            {Object.keys(scores).map((key, index) => (
              <div
                className={classNames({
                  "mb-0 pb-0": index === Object.keys(scores).length - 1,
                  "mb-6": index !== Object.keys(scores).length - 1,
                })}
                key={key}
              >
                <DottedDivider />
                <ScoreCardRow
                  score={scores[key]}
                  isExpanded={true}
                  setSelectedMicrobe={setSelectedMicrobe}
                  microbeLearnMoreExpanded={
                    selectedMicrobe?.score_definition.display_name ===
                    scores[key]?.score_definition.display_name
                  }
                  setModalData={setModalData}
                  analyticsArgs={analyticsArgs}
                  isLastChild={index === Object.keys(scores).length - 1}
                />
              </div>
            ))}
          </div>
        )}
      </ScoreCardWrapper>
    );
  }
);
