import React from "react";
import { sendExpandedMicrobeLearnMore } from "../../../../utils/analytics/customEventTracking";
import LinkedText from "../../../../components/common/linkedText";
import { ResultsBaseAnalyticsArgs, ScoreDetails } from "../types";
import { ScoreInsightRow } from "./ScoreInsightRow";

interface MicrobeLearnMoreExpandedProps {
  microbeLearnMoreExpanded?: boolean;
  setSelectedMicrobe?: (microbe: ScoreDetails | null) => void;
  score: ScoreDetails;
  analyticsArgs: ResultsBaseAnalyticsArgs;
  setModalData?: (data: { type: string; data: any }) => void;
}

export const MicrobeLearnMoreExpanded: React.FC<
  MicrobeLearnMoreExpandedProps
> = ({
  microbeLearnMoreExpanded,
  setSelectedMicrobe,
  score,
  analyticsArgs,
  setModalData,
}) => {
  return (
    <div>
      <LinkedText
        noIcon
        href="#"
        borderColorClass="border-none"
        onClick={() => {
          sendExpandedMicrobeLearnMore({
            testOrder: analyticsArgs.testOrder,
            testHash: analyticsArgs.testHash,
            cst: analyticsArgs.cst,
            moduleName: "fertility",
            scoreSlug: score.score_definition.slug,
            scoreQualitativeValue: score.qualitative_value ?? "none",
          });
          microbeLearnMoreExpanded
            ? setSelectedMicrobe?.(null)
            : setSelectedMicrobe?.(score);
        }}
      >
        <div className="flex gap-2 items-center text-neutral-500">
          <p className="text-[12px] mb-0 font-semibold uppercase tracking-[0.05em]">
            Learn More {microbeLearnMoreExpanded ? "-" : "+"}
          </p>
        </div>
      </LinkedText>

      {microbeLearnMoreExpanded && (
        <div className="flex flex-col gap-2 mt-2">
          <ScoreInsightRow
            score={score}
            setModalData={setModalData}
            analyticsArgs={analyticsArgs}
          />
        </div>
      )}
    </div>
  );
};
