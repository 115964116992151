import { useState, useEffect } from "react";
import DetectSwipe from "../../common/detectSwipe";
import LoadingSpinner from "../../common/loadingSpinner";
import Modal from "../../common/modal";
import ErrorBoundary from "../../common/errorBoundary";
import TreatmentCalendarWeek from "../TreatmentCalendarWeek";
import { careService } from "../../../services/care";
import {
  eventNames,
  sendOpenPeriodModal,
  sendTreatmentChangedWeek,
} from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import EditCalendarStartDateTemplate from "../modalTemplates/editCalendarStartDate";
import { EditPeriodModal } from "../modalTemplates/editPeriodModal";
import { ConfirmCalendarResetModal } from "../modalTemplates/confirmCalendarResetModal";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import { TreatmentCalendarExport } from "../treatmentCalendar/treatmentCalendarExport";
import { Banner } from "../../common/banner";
import { BaseAnalyticsArgsContext } from "../../../contexts/baseAnalyticsArgsContext";
import useTreatmentPlan from "../../../hooks/care/useTreatmentPlan";
import TreatmentCalendarSidebar from "../treatmentCalendar/TreatmentCalendarSidebar";

const CALENDAR_ACTION_MODAL = "calendarAction";
const PERIOD_ACTION_MODAL = "periodAction";
const CONFIRM_RESET_CALENDAR_MODAL = "resetCalendarAction";
const renderModal = ({ type, data }, closeModal) => {
  switch (type) {
    case CALENDAR_ACTION_MODAL: {
      return (
        <EditCalendarStartDateTemplate handleClose={closeModal} data={data} />
      );
    }
    case PERIOD_ACTION_MODAL: {
      return <EditPeriodModal handleClose={closeModal} data={data} />;
    }
    case CONFIRM_RESET_CALENDAR_MODAL: {
      return <ConfirmCalendarResetModal handleClose={closeModal} data={data} />;
    }
    default:
      return null;
  }
};

// Calendar for treatment
const TreatmentCalendar = ({
  consult,
  isMenopausal,
  baseUrl,
  openPrescriptionModal,
  retestLink,
  tryingToConceiveTitle,
  treatmentOrderMattersBullets,
}) => {
  const { treatmentPlan, refetchTreatmentPlan } = useTreatmentPlan(consult.uid);
  const weeks = treatmentPlan
    ? Array.from(
        { length: treatmentPlan?.calendar?.last_treatment_week },
        (_, i) => i + 1
      )
    : Array.from({ length: 9 }, (_, i) => i + 1); // returns [1,2,3,4,5,6,7,8,9]. If we change number, change in treatmentCalendarPdfExport as well
  const defaultStartWeek =
    treatmentPlan && treatmentPlan?.calendar?.current_treatment_day
      ? Math.max(1, treatmentPlan.calendar.current_treatment_day.week)
      : 1; // start calendar on current treatment week (>0)
  // TODO: consider setting/getting current week in the query params
  const [currentWeek, setCurrentWeek] = useState(defaultStartWeek);
  const [modalData, setModalData] = useState(null);
  const startDate = treatmentPlan?.calendar?.start_date;
  const [startDateUpdated, setStartDateUpdated] = useState(startDate); // state of the form field
  const [editingStartDate, setEditingStartDate] = useState(false);
  const [savingStartDate, setSavingStartDate] = useState(false);
  const [editingTreatment, setEditingTreatment] = useState(null);
  const [savedTreatmentMessage, setSavedTreatmentMessage] = useState("");
  const isMobile = window && window.innerWidth < 600 ? true : false; // mobile detects swipe actions on the calendar
  // retest link url
  const calendarModified = treatmentPlan?.calendar?.calendar_treatments.some(
    (treatment) => treatment.is_modified
  );

  const currentUser = useLoggedInUser();
  const periods = treatmentPlan?.calendar?.periods || [];
  const planType = consult?.plan_type;
  const isTreatmentPlan = planType === "treatment";

  // analytics
  const baseAnalyticsArgs = {
    consultId: consult?.uid,
    testHash: treatmentPlan?.test?.hash,
  };

  /*
   * Effects
   */

  useEffect(() => {
    if (treatmentPlan) {
      // if we just saved a treatment edit, then try to keep the current week unchanged
      if (savedTreatmentMessage) {
        // but set the current week to last week if the treatment is shorter now
        if (currentWeek > treatmentPlan.calendar.last_treatment_week) {
          setCurrentWeek(treatmentPlan.calendar.last_treatment_week);
        }
      } else {
        // if we didn't just save a treatment edit, then set the current week to the default start week
        setCurrentWeek(defaultStartWeek);
      }
      setStartDateUpdated(startDate);
    }
  }, [treatmentPlan]); // set state when we get the treatment plan loaded

  /*
   * Actions
   */
  // get subscription membership portal link

  // submit form to set the start date
  const setStartDate = async (e) => {
    e.preventDefault(); // to not post to the current url
    const newStartDate = e.target.elements["startdate"].value;
    if (!newStartDate) {
      // other validation? can't be too far in past/future?
      alert("Must select start date");
      return;
    }

    setSavingStartDate(true);
    const data = {
      treatment_start_date: newStartDate,
    };

    try {
      const response = await careService.setTreatmentPlanStartDate(
        consult.uid,
        data
      );

      if (response.status === 200) {
        refetchTreatmentPlan();
        setCurrentWeek(defaultStartWeek);
      } else {
        alert("Error setting start date");
      }
    } catch (error) {
      alert("Error setting start date");
    } finally {
      setSavingStartDate(false);
      setEditingStartDate(false);
    }
  };

  const openEditModal = (consult, refetchTreatmentPlan) => {
    setModalData({
      type: CALENDAR_ACTION_MODAL,
      data: { consult, refetch: refetchTreatmentPlan },
    });
  };

  const openAddNewPeriodModal = () => {
    setModalData({
      type: PERIOD_ACTION_MODAL,
      data: {
        consultUid: consult.uid,
        refetch: refetchTreatmentPlan,
        periods,
        // periodId, // no periodId means we are adding a new period
      },
    });
    // analytics
    sendOpenPeriodModal({
      ...baseAnalyticsArgs,
      location: "button",
    });
  };

  const openEditPeriodModal = (periodId) => {
    setModalData({
      type: PERIOD_ACTION_MODAL,
      data: {
        consultUid: consult.uid,
        refetch: refetchTreatmentPlan,
        periods,
        periodId,
      },
    });
    // analytics
    sendOpenPeriodModal({
      ...baseAnalyticsArgs,
      location: "calendar",
      periodId,
    });
  };

  const openResetCalendarModal = () => {
    setModalData({
      type: CONFIRM_RESET_CALENDAR_MODAL,
      data: { treatmentPlan, refetch: refetchTreatmentPlan },
    });
  };

  // handle swipe left and right calendar actions on mobile
  const handleCalendarSwipeLeft = (currWeek) => {
    if (currWeek < weeks.length) {
      sendTreatmentChangedWeek({
        ...baseAnalyticsArgs,
        currentWeek: defaultStartWeek,
        viewedWeek: currWeek + 1,
      });
      setCurrentWeek(currWeek + 1);
    }
  };
  const handleCalendarSwipeRight = (currWeek) => {
    if (currWeek !== 1) {
      sendTreatmentChangedWeek({
        ...baseAnalyticsArgs,
        currentWeek: defaultStartWeek,
        viewedWeek: currWeek - 1,
      });
      setCurrentWeek(currWeek - 1);
    }
  };
  const handleCloseModal = () => {
    setModalData(null);
  };

  // used in multiple places
  const calendarHelpText = startDate
    ? "Edit treatment start date."
    : "After you’ve received all of your medication, select a date to start your treatment.";

  return (
    <BaseAnalyticsArgsContext.Provider
      value={{
        baseAnalyticsArgs,
      }}
    >
      <div className="max-w-6xl w-full mx-auto px-4">
        <div className="block py-12 sm:py-20">
          <h2 className="text-center capitalize">{planType} Calendar</h2>
          {modalData !== null && (
            <ErrorBoundary>
              <Modal
                closeModal={() => handleCloseModal()}
                widthClass="w-plan-column-sm"
                hasFocusItem
              >
                {/* specific template for modal */}
                {renderModal(modalData, () => handleCloseModal())}
              </Modal>
            </ErrorBoundary>
          )}
          {!treatmentPlan && (
            <div className="py-20">
              <LoadingSpinner />
            </div>
          )}

          {treatmentPlan && (
            <div className="mt-12 block bg-white rounded-lg sm:flex">
              <TreatmentCalendarSidebar
                editingTreatment={editingTreatment}
                setEditingTreatment={setEditingTreatment}
                setSavedTreatmentMessage={setSavedTreatmentMessage}
                refetchTreatmentPlan={refetchTreatmentPlan}
                editingStartDate={editingStartDate}
                setEditingStartDate={setEditingStartDate}
                startDate={startDate}
                setStartDate={setStartDate}
                startDateUpdated={startDateUpdated}
                setStartDateUpdated={setStartDateUpdated}
                savingStartDate={savingStartDate}
                calendarHelpText={calendarHelpText}
                treatmentPlan={treatmentPlan}
                isMenopausal={isMenopausal}
                openPrescriptionModal={openPrescriptionModal}
                openEditModal={openEditModal}
                openEditPeriodModal={openEditPeriodModal}
                openAddNewPeriodModal={openAddNewPeriodModal}
                openResetCalendarModal={openResetCalendarModal}
                calendarModified={calendarModified}
                isTreatmentPlan={isTreatmentPlan}
                currentWeek={currentWeek}
                weeks={weeks}
                currentUser={currentUser}
                retestLink={retestLink}
                consult={consult}
                baseAnalyticsArg={baseAnalyticsArgs}
              />

              <hr className="block sm:hidden" />

              {/* this component listens to swipes on mobile */}
              <DetectSwipe
                className="flex-grow"
                onSwipeLeft={() =>
                  isMobile && handleCalendarSwipeLeft(currentWeek)
                }
                onSwipeRight={() =>
                  isMobile && handleCalendarSwipeRight(currentWeek)
                }
              >
                {/* current week toggle */}
                <div className="p-8 text-2xl medium flex items-center justify-center sm:justify-start">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      disabled={currentWeek === 1}
                      className={`h-8 w-8 p-1 rounded-full bg-white hover:bg-concrete cursor-pointer ${
                        currentWeek > 1 ? "text-evvy-blue" : "opacity-50"
                      }`}
                      onClick={analyticsClickHandler({
                        eventName: eventNames.TREATMENT_CHANGE_WEEK,
                        eventArgs: {
                          ...baseAnalyticsArgs,
                          currentWeek: defaultStartWeek,
                          viewedWeek: currentWeek - 1,
                        },
                        clickHandler: () => setCurrentWeek(currentWeek - 1),
                      })}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </div>
                  <div className="mx-2 w-44 text-center items-center">
                    <div className="flex mx-auto justify-center">
                      <div className="mr-2">Week {currentWeek}</div>
                      <div className="opacity-40">of {weeks.length}</div>
                    </div>
                  </div>
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      disabled={currentWeek >= weeks.length}
                      className={`h-8 w-8 p-1 rounded-full bg-white hover:bg-concrete cursor-pointer ${
                        currentWeek < weeks.length
                          ? "text-evvy-blue"
                          : "opacity-50"
                      }`}
                      onClick={analyticsClickHandler({
                        eventName: eventNames.TREATMENT_CHANGE_WEEK,
                        eventArgs: {
                          ...baseAnalyticsArgs,
                          currentWeek: defaultStartWeek,
                          viewedWeek: currentWeek + 1,
                        },
                        clickHandler: () => setCurrentWeek(currentWeek + 1),
                      })}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>

                {/* in-depth calendar */}
                <TreatmentCalendarWeek
                  treatmentPlan={treatmentPlan}
                  currentWeek={currentWeek}
                  editingStartDate={editingStartDate}
                  retestLink={retestLink}
                  openPeriodModal={openEditPeriodModal}
                  openPrescriptionModal={openPrescriptionModal}
                />

                <hr className="block sm:hidden" />
              </DetectSwipe>
            </div>
          )}
          {treatmentPlan && !editingStartDate && (
            <TreatmentCalendarExport
              onClickPopup={
                !startDate
                  ? () => openEditModal(consult, refetchTreatmentPlan)
                  : null
              }
              treatmentPlan={treatmentPlan}
              consult={consult}
              baseUrl={baseUrl}
              retestLink={retestLink}
              isMenopausal={isMenopausal}
              tryingToConceiveTitle={tryingToConceiveTitle}
              treatmentOrderMattersBullets={treatmentOrderMattersBullets}
            />
          )}
          {savedTreatmentMessage && <Banner text={savedTreatmentMessage} />}
        </div>
      </div>
    </BaseAnalyticsArgsContext.Provider>
  );
};

export default TreatmentCalendar;
