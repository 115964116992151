import React, { useCallback, useState, useEffect, useRef } from "react";

import { Module } from "../types";
import { ReactComponent as EyeOpenIcon } from "../../../../components/common/icons/eye-open.svg";
import { ReactComponent as EyeClosedIcon } from "../../../../components/common/icons/eye-closed.svg";
import { ReactComponent as LockIcon } from "../../../../components/common/icons/lock.svg";
import { MODULE_TITLE_DICTIONARY } from "../../constants";
import LinkedText from "../../../../components/common/linkedText";
import classNames from "classnames";
import { PDP_URL } from "../../../constants";

interface ModuleVisibilityProps {
  modules: Module[];
  onChange?: (moduleOption: string, isVisible: boolean) => void;
}

export const ModuleVisibility = ({
  modules,
  onChange,
}: ModuleVisibilityProps) => {
  const [visibilityState, setVisibilityState] = useState<
    Record<string, boolean>
  >({});
  const isInitialized = useRef(false);

  // Initialize visibility state from localStorage and module defaults
  useEffect(() => {
    if (!isInitialized.current) {
      const initialState = modules.reduce((acc, module) => {
        const storageKey = `module-visibility-${module.module_option}`;
        const storedValue = localStorage.getItem(storageKey);
        const isVisible =
          storedValue !== null ? JSON.parse(storedValue) : module.enabled;
        acc[module.module_option] = isVisible;

        // Notify parent of initial state
        onChange?.(module.module_option, isVisible);

        return acc;
      }, {} as Record<string, boolean>);
      setVisibilityState(initialState);
      isInitialized.current = true;
    }
  }, [modules, onChange]);

  const getModuleVisibility = useCallback(
    (module: Module) => {
      return visibilityState[module.module_option] ?? module.enabled;
    },
    [visibilityState]
  );

  const toggleModuleVisibility = useCallback(
    (module: Module) => {
      if (!module.locked) {
        // TODO: Handle actually updating this on the backend for the module enabled/disabled
        const storageKey = `module-visibility-${module.module_option}`;
        const newValue = !getModuleVisibility(module);

        // Update state immediately for UI
        setVisibilityState((prev) => ({
          ...prev,
          [module.module_option]: newValue,
        }));

        // Update localStorage
        localStorage.setItem(storageKey, JSON.stringify(newValue));

        // Notify parent of change
        onChange?.(module.module_option, newValue);
      }
    },
    [getModuleVisibility, onChange]
  );

  return (
    <div className="sm:mb-36 mb-12 w-full xl:w-[1260px] min-h-[240px] border border-black border-opacity-40 rounded-lg border-dashed flex sm:flex-row flex-col gap-4 sm:p-12 p-8 justify-between">
      <div className="flex-1">
        <h3 className="text-neutral-900 max-w-[300px]">
          Customize Your View to Access More Insights
        </h3>
        <p className="text-neutral-900 md:max-w-[350px]">
          Based on your health questionnaire, we've customized your results
          view. Show/hide insights as they meet your health goals.
        </p>
      </div>
      <div className="flex-1 sm:max-w-[570px] cursor-pointer">
        {modules.map((module) => {
          const isVisible = getModuleVisibility(module);
          return (
            <div
              className={classNames(
                "flex items-center mb-2 justify-between sm:p-6 p-4 rounded-lg transition-colors duration-200",
                {
                  "border border-black border-opacity-20": module.locked,
                  "bg-white hover:bg-stone-100 cursor-pointer":
                    isVisible && !module.locked,
                  "bg-stone-200 hover:bg-stone-300 cursor-pointer":
                    !isVisible && !module.locked,
                  "cursor-not-allowed": module.locked,
                }
              )}
              key={module.module_option}
              onClick={() => toggleModuleVisibility(module)}
            >
              <div className="flex items-center gap-2">
                <span className="text-neutral-900">
                  {MODULE_TITLE_DICTIONARY[module.module_option]}
                </span>
                {module.module_option === "sti" && module.locked && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(PDP_URL, "_blank");
                    }}
                    className="cursor-pointer"
                  >
                    <div className="mb-0 ml-2 pb-0 text-xs text-neutral-900 underline italic">
                      Requires Expanded PCR Panel add-on
                    </div>
                  </span>
                )}
                {module.module_option === "menopause" && (
                  <span className="text-neutral-900">
                    <div className="mb-0 ml-2 pb-0 text-xs text-neutral-900 italic">
                      Coming soon
                    </div>
                  </span>
                )}
              </div>
              <div className="transition-transform duration-200">
                {module.locked && <LockIcon fill="black" />}
                {isVisible && !module.locked && <EyeOpenIcon />}
                {!isVisible && !module.locked && <EyeClosedIcon />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
