import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import LayoutAuth from "../../components/auth/layout";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";
import { getIsMobile } from "../../utils/general";
import { MAX_PASSWORD_LENGTH } from "../../components/account/constants";
import { sendAccountCreationViewedPage } from "../../utils/analytics/customEventTracking";

const Register = ({ registrationRoute = "" }) => {
  const isTreatmentRegistrationRoute = registrationRoute === "treatment";
  const [userParams, setUserParams] = useState({
    orderNumber: "",
    checkoutID: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    // Trigger analytics
    sendAccountCreationViewedPage({
      version: isTreatmentRegistrationRoute ? "ungated-rx" : "standard",
    });

    if (isTreatmentRegistrationRoute) {
      // Remove "+" from the query string
      const userParams = new URLSearchParams(
        window.location.search.replace(/\+/g, "%2B")
      );
      setUserParams({
        orderNumber: decodeURIComponent(userParams.get("orderNumber") || ""),
        checkoutID: decodeURIComponent(userParams.get("checkoutID") || ""),
        firstName: decodeURIComponent(userParams.get("firstName") || ""),
        lastName: decodeURIComponent(userParams.get("lastName") || ""),
        email: decodeURIComponent(userParams.get("email") || ""),
      });
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [birthDate, setBirthDate] = useState();
  const isMobile = getIsMobile();

  const submitForm = (e) => {
    e.preventDefault(); // to not post to the current url
    setLoading(true);
    setError(null);

    const password = e.target.elements["password"].value;

    if (password.length > MAX_PASSWORD_LENGTH) {
      setError("Password is too long");
      setLoading(false);
      return;
    }

    const registrationPayload = {
      email: e.target.elements["email"].value,
      first_name: e.target.elements["first_name"].value,
      last_name: e.target.elements["last_name"].value,
      password: password,
      birthday: e.target.elements["birthday"].value,
    };

    if (isTreatmentRegistrationRoute) {
      if (userParams.checkoutID) {
        registrationPayload.checkout_id = userParams.checkoutID;
      } else if (userParams.orderNumber) {
        registrationPayload.order_number = userParams.orderNumber;
      }
      registrationPayload.registration_route = registrationRoute;
    } else {
      registrationPayload.registration_route = "default";
    }

    authService.register(
      registrationPayload,
      (response) => {
        setError(null);

        authService.fetchUser(
          () => {
            const next = getParameterByName("next", window.location);
            if (next) {
              window.location = next;
            } else {
              window.location = "/research-consent/"; // go ask for research consent
              // window.location = '/tests/'; // take user to their account page
            }
          },
          () => {
            console.error("Failed to fetch user info");
          }
        );
        //setLoading(false);  // no need, wait for redirect
      },
      (error, response) => {
        console.error(error);
        console.error(response);
        setError(response ? response : "could not sign up");
        setLoading(false);
      }
    );
  };

  const getLoginLink = () => {
    if (isTreatmentRegistrationRoute) {
      // pass params through to the login route for prefilling
      if (userParams.checkoutID) {
        let baseLoginUrl = "/treatments/login";
        if (userParams.orderNumber) {
          baseLoginUrl = baseLoginUrl.concat(
            `?orderNumber=${userParams.orderNumber}`
          );
        } else if (userParams.checkoutID) {
          baseLoginUrl = baseLoginUrl.concat(
            `?checkoutID=${userParams.checkoutID}`
          );
        }
        if (userParams.email) {
          baseLoginUrl = baseLoginUrl.concat(`&email=${userParams.email}`);
        }
        return encodeURI(baseLoginUrl);
      } else {
        return "/treatments/login/";
      }
    } else {
      return "/login/";
    }
  };

  const withinAgeRange = (date) => {
    if (date) {
      const age = new Date().getFullYear() - date.getFullYear();
      return age >= 18 && age <= 100;
    }
    return false;
  };

  return (
    <LayoutAuth
      title={
        isTreatmentRegistrationRoute
          ? "Create an account to complete your order"
          : "Welcome!"
      }
      metaTitle="Evvy — Register Your Account"
      subtitle={isTreatmentRegistrationRoute ? null : "Create your account."}
    >
      <form className="space-y-6" action="#" onSubmit={submitForm}>
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first_name" className="uppercase font-medium">
              First Name
            </label>
            <div className="mt-1">
              <input
                id="first_name"
                name="first_name"
                value={userParams.firstName}
                onChange={(e) =>
                  setUserParams({ ...userParams, firstName: e.target.value })
                }
                type="text"
                autoFocus
                autoComplete="given-name"
                required
                style={{ fontSize: "16px" }}
                className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last_name" className="uppercase font-medium">
              Last Name
            </label>
            <div className="mt-1">
              <input
                id="last_name"
                name="last_name"
                value={userParams.lastName}
                onChange={(e) =>
                  setUserParams({ ...userParams, lastName: e.target.value })
                }
                type="text"
                autoComplete="family-name"
                required
                style={{ fontSize: "16px" }}
                className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="email" className="uppercase font-medium">
            Email Address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              value={userParams.email}
              onChange={(e) =>
                setUserParams({ ...userParams, email: e.target.value })
              }
              type="email"
              autoComplete="email"
              required
              style={{ fontSize: "16px" }}
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
            />
          </div>
        </div>

        <div>
          <label htmlFor="birthday" className="uppercase font-medium">
            Birthday (mm/dd/yyyy)
          </label>
          <div className="mt-1">
            {isMobile ? (
              <DatePicker
                id="birthday"
                className="block w-full p-3 text-lg border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black focus:outline-none focus:ring-transparent focus:border-crazy-purple"
                selected={birthDate}
                placeholderText="mm/dd/yyyy"
                onChange={(date) => setBirthDate(date)}
                showYearDropdown
                yearDropdownItemNumber={80}
                scrollableYearDropdown
                maxDate={new Date()} // can't select today or future dates
                // showMonthDropdown  // removed this since it's ugly
              />
            ) : (
              <input
                id="birthday"
                name="birthday"
                type="date"
                autoComplete="bday"
                required
                style={{ fontSize: "16px" }}
                className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              />
            )}
          </div>
        </div>

        <div>
          <label htmlFor="password" className="uppercase font-medium">
            Password
          </label>
          <div className="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              minLength="8"
              style={{ fontSize: "16px" }}
              className="appearance-none block w-full p-3 border border-evvy-blue rounded-xs shadow-sm placeholder-evvy-black sm:placeholder-transparent focus:outline-none focus:ring-transparent focus:border-crazy-purple"
              data-html={true}
              data-tip="<div>
								14 or more characters<br/>
								Alphanumeric characters<br/>
								No common passwords<br/>
								Password can't be similar to email
							</div>"
            />
          </div>
          <ReactTooltip
            place="right"
            backgroundColor="#F3F1EE"
            textColor="#000000"
            effect="solid"
          />
        </div>

        <div className="flex items-start">
          <input
            id="terms"
            name="terms"
            type="checkbox"
            required
            className="mt-0.5 bg-evvy-cream rounded-sm border-evvy-blue cursor-pointer focus:outline-none focus:ring-evvy-blue active:ring-evvy-blue checked:bg-evvy-blue"
          />
          <label htmlFor="terms" className="block font-medium ml-2">
            I have read and agree to the{" "}
            <a
              href="https://www.evvy.com/terms-of-service"
              className="underline uppercase text-sm font-semibold tracking-wider"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            and acknowledge the{" "}
            <a
              href="https://www.evvy.com/privacy-policy"
              className="underline uppercase text-sm font-semibold tracking-wider"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </label>
        </div>

        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-xs">
            {error}
          </div>
        ) : (
          ""
        )}

        <div className="sm:flex items-center justify-between sm:flex-row-reverse">
          <button
            type="submit"
            className="cursor-pointer mt-6 sm:mt-0 justify-center w-full sm:w-auto py-6 px-20 tracking-wider font-semibold rounded-xs shadow-sm bg-evvy-blue focus:outline-none"
            disabled={loading}
            onClick={analyticsClickHandler({
              eventName: eventNames.ACCOUNT_CREATION_CLICKED_CTA,
              eventArgs: {
                version: isTreatmentRegistrationRoute
                  ? "ungated-rx"
                  : "standard",
                ctaText: "Next",
                ageError: withinAgeRange(birthDate) ? false : true,
              },
            })}
          >
            {loading ? "Next →" : "Next →"}
          </button>

          <div className="mr-auto mt-6 sm:mt-0">
            Already have an account?{" "}
            <Link
              to={getLoginLink()}
              className="underline uppercase text-sm font-semibold tracking-wider"
              onClick={analyticsClickHandler({
                eventName: eventNames.ACCOUNT_CREATION_CLICKED_CTA,
                eventArgs: {
                  version: isTreatmentRegistrationRoute
                    ? "ungated-rx"
                    : "standard",
                  ctaText: "Sign In",
                  ageError: withinAgeRange(birthDate) ? false : true,
                },
              })}
            >
              Sign In
            </Link>
          </div>
        </div>
      </form>
    </LayoutAuth>
  );
};

export default Register;
