import Tippy from "@tippyjs/react";
import React from "react";
import { ReactComponent as InfoIcon } from "../../components/results_v2/icons/info.svg";
import { secondaryColors } from "../../utils/viz";

interface ResearchRatingProps {
  researchRating: string;
  tooltipCopy?: string;
}
export const ResearchRating: React.FC<ResearchRatingProps> = ({
  researchRating,
  tooltipCopy,
}) => {
  return (
    <div className="text-sm flex gap-2 items-center mt-2 text-neutral-500">
      <Tippy
        arrow
        render={() => (
          <div
            id="tooltip"
            role="tooltip"
            className="leading-snug bg-evvy-pine p-3 rounded-md max-w-sm text-evvy-white"
            tabIndex={-1}
          >
            {tooltipCopy}
          </div>
        )}
        placement="bottom"
        animation={false}
      >
        <InfoIcon
          stroke={secondaryColors["evvy-black-dull"]}
          fill={secondaryColors["evvy-black-dull"]}
          className="mb-0 w-3 h-3 inline"
        />
      </Tippy>
      <span className="self-stretch my-auto font-semibold">
        Research Status:
      </span>
      <span className="self-stretch my-auto">{researchRating}</span>
    </div>
  );
};
