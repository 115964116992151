import React from "react";
import { ScoreCardWrapper } from "../components/ScoreCardWrapper";
import { ReactComponent as LockIcon } from "../../../../components/common/icons/lock.svg";

interface LockedCardProps {
  lockedCardCopy: string;
  lockedCardTitle: string;
}

export const LockedCard: React.FC<LockedCardProps> = ({
  lockedCardCopy,
  lockedCardTitle,
}) => {
  return (
    <ScoreCardWrapper isLocked>
      <div className="flex justify-between items-center">
        <div className="sm:max-w-[60%] max-w-[80%]">
          <p className="text-xl semibold text-opacity-50 text-neutral-900 mb-2">
            {lockedCardTitle}
          </p>
          <p className="text-base medium text-opacity-50 text-neutral-900 mb-0 pb-2">
            {lockedCardCopy}
          </p>
        </div>
        <LockIcon />
      </div>
    </ScoreCardWrapper>
  );
};
