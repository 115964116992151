import { TransparentBundle } from "../../../types/care";
import { AddCartItemPayload } from "../../../types/cart";
import { cn } from "../../../utils/cn";
import { transparentCarePhaseColorMap } from "../../../utils/colors";
import CarePackagingImg from "../../care/images/care-packaging-no-bg.png";
import BundleEcommFeatures from "./BundleEcommFeatures";
import BundleFreebies from "./BundleFreebies";
import EvvyStudyStats from "./EvvyStudyStats";
import TransparentPhaseHeader from "./TransparentPhaseHeader";
import TreatmentBundleCard from "./TreatmentBundleCard";
import { usePanelCart } from "../../../hooks/cart/usePanelCart";
import { getBundleTreatmentsFromPayload } from "../../ecomm/cart";
import { useEffect, useState } from "react";
import RectangularButton from "../../common/rectangularButton";
import { useTransparentCareTreatments } from "../../../hooks/care/transparentCare/useTransparentCareTreatments";
import { Test } from "../../../types/test";
import WarningIcon from "../icons/warning";
import ActiveInfectionNoTreatmentsWarningModal from "../ActiveInfectionNoTreatmentsWarningModal";
import { secondaryColors } from "../../../utils/viz";
import { ReactComponent as InfoIcon } from "../../../components/results_v2/icons/info.svg";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import classNames from "classnames";

interface TreatmentBundleProps {
  transparentBundle?: TransparentBundle;
  test: Test;
  asymptomatic?: boolean;
}

const TreatmentBundle: React.FC<TreatmentBundleProps> = ({
  transparentBundle,
  test,
  asymptomatic,
}) => {
  const { transparentCareTreatments } = useTransparentCareTreatments(test.hash);

  const { cart, addToCart, getIfBundleInCart, showCart, setShowCart } =
    usePanelCart();

  const [showStickyAddToCartButton, setShowStickyAddToCartButton] =
    useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const bundleInCart = getIfBundleInCart() || false;
  const bundlePrice = transparentBundle?.pricing?.bundled_price;
  const hasActiveInfection = test?.has_active_infection;
  const isRecommendedAntibioticOrAntifungal =
    test?.is_recommended_antibiotic_or_antifungal;

  // Only show the sticky footer when we've scrolled past half the window's height
  useEffect(() => {
    const checkScroll = () => {
      if (window.scrollY > window.innerHeight / 2) {
        setShowStickyAddToCartButton(!bundleInCart && !showCart);
      } else {
        setShowStickyAddToCartButton(false);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [bundleInCart, cart, showCart]);

  const formattedBundleTreatments: AddCartItemPayload[] =
    getBundleTreatmentsFromPayload(transparentCareTreatments);

  const addTreatmentBundleToCart = () => {
    const bundleInCart = getIfBundleInCart();
    if (bundleInCart) {
      setShowCart(true);
    } else {
      addToCart(formattedBundleTreatments);
    }
  };

  const currentUser = useLoggedInUser();
  const fertilityV0Enabled = currentUser?.features.fertilityV0Enabled;

  // Show TTC warning for asymptomatic TTC users who have antibiotics in their bundle
  const showTryingToConceiveWarning =
    fertilityV0Enabled &&
    test?.is_trying_to_conceive &&
    asymptomatic &&
    transparentBundle?.treatments.treat.treatments.some(
      (treatment) => treatment.type === "antibiotic"
    );

  const smallCapsBold = "uppercase text-sm font-semibold"; // this is the actual typography class in figma

  if (!transparentBundle || Object.keys(transparentBundle || {}).length === 0)
    return null;

  return (
    <>
      <div className="bg-white rounded-[20px] max-w-[1045px] mx-auto">
        {/* Top Section Gradient Component */}
        <div className="px-3 sm:px-8 md:px-28 flex flex-col lg:flex-row items-center justify-between rounded-2xl bg-gradient-to-b from-[#D8CFCA] to-white via-[#D8CFCA] via-80% lg:pt-16 lg:pb-12 pt-8 pb-6">
          <img
            className="block lg:hidden mb-6 max-w-[250px] sm:max-w-[350px]"
            src={CarePackagingImg}
            alt="Evvy Complete Treatment Program"
          />
          <div className="w-full lg:w-[390px]">
            <div className={cn(smallCapsBold, "mb-1")}>Designed for you</div>
            <h2 className="text-[40px]">
              Evvy Complete <br className="hidden md:block" /> Treatment Program
            </h2>
            <div>
              A prescription treatment program personalized to your unique
              microbiome and symptoms.
            </div>
            {/* Pricing Row: bundle price, full price, discount banner */}
            <div className="text-lg mt-4 mb-6 items-center flex">
              ${bundlePrice}
              <span className="ml-2 line-through text-black text-opacity-50 text-lg">
                ${transparentBundle?.pricing?.full_price.toString() ?? ""}
              </span>
              <span className="uppercase text-center bg-dv-pond text-white rounded-full font-semibold text-[12px] px-2.5 py-0.5 ml-4">
                Save 10% w/ program
              </span>
            </div>
            <RectangularButton
              text={bundleInCart ? "Added" : "Add to cart"}
              textColorClass="text-evvy-black"
              verticalPadding="py-5"
              fullWidth
              onClick={addTreatmentBundleToCart}
              icon={bundleInCart ? "checkmark" : ""}
            />
            {/* HSA/FSA Eligible, Free Shipping, OB/GYN Approved */}
            <BundleEcommFeatures />
            {hasActiveInfection && !isRecommendedAntibioticOrAntifungal && (
              <span
                className="flex flex-row items-center mt-6 hover:cursor-pointer w-fit"
                onClick={() => setWarningModalOpen(true)}
              >
                <WarningIcon
                  fill={secondaryColors["evvy-starfish"]}
                  outlineStroke="none"
                  className="w-7 h-7"
                />
                <span className="uppercase underline font-medium text-sm ml-2.5">
                  Important Note
                </span>
              </span>
            )}
          </div>
          <img
            className="max-w-[390px] hidden lg:block"
            src={CarePackagingImg}
            alt="Evvy Complete Treatment Program"
          />
        </div>
        {/* Treatment Phases */}
        <div className="px-3 sm:px-8 md:px-16 lg:px-24 mt-4 relative">
          {/* vertical line connecting phase circles */}
          <div className="absolute left-[22px] sm:left-16 md:left-24 lg:left-32 top-12 bottom-36 w-[1px] bg-gradient-to-b from-[#D8CFCA] to-white via-[#D8CFCA] via-60% bg-opacity-10" />

          <div className={cn(smallCapsBold, "mb-5")}>
            Your complete treatment program may include<sup>*</sup>:
          </div>
          {Object.entries(transparentBundle.treatments)
            .filter(([_, details]) => details.treatments.length > 0) // filter phases with treatments
            .map(([phase, details], index) => (
              <div
                className={classNames("mb-10", {
                  "mb-0": showTryingToConceiveWarning && phase === "treat",
                })}
                key={phase}
              >
                <div className="flex flex-row mb-8 last:mb-0" key={phase}>
                  {/* Phase Number */}
                  <div
                    className={`bg-white border-black border-opacity-10 border-2
                  rounded-full sm:w-10 sm:h-10 w-7 h-7 flex flex-shrink-0 items-center
                  justify-center text-[12px] sm:text-sm mr-3.5 sm:ml-3 sm:mr-6 md:mr-12 font-semibold
                  relative z-10`}
                  >
                    {index + 1}
                  </div>
                  <div className="flex flex-col">
                    <TransparentPhaseHeader
                      headerText={details.header_text}
                      phaseColor={
                        transparentCarePhaseColorMap[
                          phase as keyof typeof transparentCarePhaseColorMap // hacky type assertion to appease TS
                        ].opacity70
                      }
                      headerClassName="text-xl sm:text-2xl"
                    />
                    <div className="flex flex-wrap gap-2 md:gap-6 lg:gap-10">
                      {details.treatments.map((treatment) => (
                        <TreatmentBundleCard
                          key={treatment.slug}
                          treatment={treatment}
                          phase={phase}
                        />
                      ))}
                    </div>
                    {showTryingToConceiveWarning && phase === "treat" && (
                      <div className="mt-6">
                        <span className="flex flex-row gap-2 items-center semibold">
                          <InfoIcon
                            stroke={secondaryColors["evvy-black-dull"]}
                            fill={secondaryColors["evvy-black-dull"]}
                            className="mb-0 w-3 h-3 inline"
                          />
                          <span className="self-stretch my-auto text-neutral-500 text-sm">
                            From our experts
                          </span>
                        </span>
                        <p className="b3 text-black text-opacity-50">
                          {`An antibiotic is not typically prescribed to people
                          without symptoms. That said, research shows that lower
                          amounts of disruptive microbes in the vaginal
                          microbiome may be associated with improved fertility
                          outcomes. Since you’re trying to conceive${
                            test?.is_trying_to_conceive_soon ? " soon" : ""
                          }, a local
                          antibiotic may be prescribed to help target some of
                          the disruptive microbes found in your results.`}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {/* Footer */}
          <div className="pl-11 sm:pl-20 md:pl-[88px] pr-5 sm:pr-10 text-sm mt-10 pb-12">
            <div className="flex flex-row items-center mb-6">
              <div className="text-[12px] text-black text-opacity-50 xs:whitespace-nowrap mr-4">
                Included with the Evvy Complete Treatment Program
              </div>
              <div className="hidden xs:flex flex-grow h-[1px] border-t border-evvy-black border-opacity-20 border-dashed" />
            </div>

            <BundleFreebies />

            <div className="text-[12px] text-black text-opacity-50 italic mt-6">
              *A provider will review your results, preferences, and intake form
              to design your specialized treatment program. Any prescriptions
              will be determined at the sole discretion of an Evvy-affiliated
              provider. Individual results for any given product may vary.
            </div>
          </div>
        </div>
      </div>
      <EvvyStudyStats />
      {/* Sticky add to cart button */}
      {showStickyAddToCartButton && (
        <div
          className="z-50 ml-4 xs:w-[400px] fixed bottom-2 right-4 sm:bottom-0 sm:right-0 sm:m-12 flex p-[20px] bg-evvy-blue justify-between items-center cursor-pointer shadow-xl"
          onClick={addTreatmentBundleToCart}
        >
          <div>
            <p className="mb-0 medium xs:w-[150px] mr-4 sm:mr-0">
              Complete Treatment Program{" "}
              <span className="opacity-50">— ${bundlePrice}</span>
            </p>
          </div>
          <p className="t4 mb-0 text-right whitespace-nowrap">ADD TO CART</p>
        </div>
      )}
      {warningModalOpen && test.results_ready_at && (
        <ActiveInfectionNoTreatmentsWarningModal
          resultsReadyAtDate={test.results_ready_at}
          setWarningModalOpen={setWarningModalOpen}
        />
      )}
    </>
  );
};

export default TreatmentBundle;
