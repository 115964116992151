interface WarningIconProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  stroke?: string;
  outlineStroke?: string;
}

const WarningIcon: React.FC<WarningIconProps> = ({
  fill = "black",
  stroke = "black",
  outlineStroke = "#000000",
  ...props
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4656 4.62751L2.86549 17.5156C2.7088 17.7913 2.62588 18.1038 2.62501 18.4221C2.62413 18.7404 2.70532 19.0534 2.86049 19.3299C3.01567 19.6064 3.23942 19.8369 3.50949 19.9983C3.77956 20.1597 4.08654 20.2465 4.39987 20.25H19.6001C19.9135 20.2465 20.2204 20.1597 20.4905 19.9983C20.7606 19.8369 20.9843 19.6064 21.1395 19.3299C21.2947 19.0534 21.3759 18.7404 21.375 18.4221C21.3741 18.1038 21.2912 17.7913 21.1345 17.5156L13.5344 4.62751C13.3744 4.35963 13.1492 4.13816 12.8804 3.98445C12.6117 3.83075 12.3084 3.75 12 3.75C11.6916 3.75 11.3883 3.83075 11.1196 3.98445C10.8508 4.13816 10.6256 4.35963 10.4656 4.62751Z"
      fill={fill}
      stroke={outlineStroke}
      strokeWidth="2"
    />
    <path
      d="M12.002 8.42139V13.0267"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.002 17.0994H12.0104"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WarningIcon;
