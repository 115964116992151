import { useState, useEffect, useCallback } from "react";
import { processOrder } from "../services/order";
import { Order } from "../types/orders";

type ProcessOrderResponse = {
  consult_uid?: string;
  checkout_source?: string;
  order?: Order;
};

// Process the order synchronously if it is not yet processed
export const useProcessOrder = (
  checkoutId?: string | null,
  orderEmail?: string | null
) => {
  const [data, setData] = useState<ProcessOrderResponse | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const processCurrentOrder = useCallback(async () => {
    setIsLoading(true);
    if (checkoutId && orderEmail) {
      try {
        const response = await processOrder(checkoutId, orderEmail);
        setData(response);
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [checkoutId, orderEmail]);

  const refetchProcessOrder = () => {
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    processCurrentOrder();
  }, [processCurrentOrder, refetch]);

  return { data, error, isLoading, refetchProcessOrder };
};
