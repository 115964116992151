/**
 * A styled with a gradient slider
 * @param {number} score a number indicating the score value
 * @param {string} scoreType "disruptive" or "protective" - indicates gradient type
 * @returns jsx of a card
 */

import React from "react";

const colorClasses = {
  protective: {
    from: "from-GO-fill/20",
    to: "to-GO-fill",
    krystal: "bg-GO-fill",
  },
  disruptive: {
    from: "from-BA-fill",
    to: "to-BA-fill/20",
    krystal: "bg-BA-fill",
  },
  shannon_diversity: {
    from: "from-NE-fill",
    to: "to-NE-fill/20",
    krystal: "bg-NE-fill",
  },
};

const gradientAxisLabels = {
  protective: {
    from: "Very low",
    to: "Good",
  },
  disruptive: {
    from: "Good",
    to: "Very high",
  },
  shannon_diversity: {
    from: "Low",
    to: "High",
  },
};

const scoreGradient = ({ scoreType, score }) => {
  return (
    <div className="">
      <div
        className={`h-[20px] w-full rounded-[3px] bg-gradient-to-r ${colorClasses[scoreType].from} ${colorClasses[scoreType].to}`}
      >
        <div className="w-[95%] relative mx-auto">
          <div
            className={`flex items-center transition-all duration-1000 absolute top-0`}
            style={{
              left: `${
                scoreType === "shannon_diversity" ? (score / 4) * 100 : score
              }%`,
            }}
          >
            <div className="w-[2px] h-[20px] bg-white" />
            <div
              className={`w-[12px] h-[12px] rounded-full ${colorClasses[scoreType].krystal} border-solid border-2 border-white -ml-[7px] drop-shadow-2xl`}
              style={{ filter: "drop-shadow(0 0 .6rem grey)" }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between b3 semibold mt-3">
        <div>{gradientAxisLabels[scoreType].from}</div>
        <div>{gradientAxisLabels[scoreType].to}</div>
      </div>
    </div>
  );
};

export default scoreGradient;
