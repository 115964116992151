export const HorizontalDivider = () => (
  <svg
    width="1260"
    height="2"
    viewBox="0 0 1260 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-px bg-[rgba(17,22,26,0.1)]"
  >
    <path d="M0 1.07422H1260" stroke="#11161A" strokeOpacity="0.1" />
  </svg>
);

export const DottedDivider = () => (
  <div className="my-6 w-full border-b border-dashed border-black border-opacity-20 min-h-px max-md:max-w-full" />
);

export const VerticalDivider = () => (
  <div className="w-px bg-[rgba(17,22,26,0.1)] self-stretch" />
);

export const BulletPoint = () => (
  <svg
    width="10"
    height="24"
    viewBox="0 0 10 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-5 h-5 mt-2"
  >
    <circle cx="6" cy="13.0742" r="6" fill="#C9C2BD" />
  </svg>
);
